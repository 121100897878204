type TMinMaxValidation = {
  min?: number;
  max?: number;
};
export const validateNumber = (number?: number, { min, max }: TMinMaxValidation = {}) => {
  if (Number.isNaN(Number(number))) return false;
  if (min === undefined && max === undefined) return true;
  if (min === undefined) return Number(number) < Number(max);
  if (max === undefined) return Number(number) > Number(min);
  return true;
};
