import { combineReducers } from '@reduxjs/toolkit';

// SLICES
import uiReducer from './ui.slice';
import authReducer from './auth.slice';
import languagesReducer from './language.slice';
import objectRelationshipReducer from './objectRelationships.slice';
import organizationReducer from './organization.slice';

import pathwayReducer from '../study-combinations/slices/pathway.slice';
import integrationReducer, { IntegrationState } from './integration.slice';
import combinationReducer from '../study-combinations/slices/combinationStudents.slice';
import activityReducer, { ActivityState } from '../activities/pages/slices/activity.slice';
import { IAuthState, IObjectRelationshipState, IUIState } from 'types/state.interface';

export interface ApplicationState {
  ui: IUIState;
  auth: IAuthState;
  languages: any;
  pathway: any;
  integration: IntegrationState;
  combinations: any;
  objectRelationships: IObjectRelationshipState;
  organizations: any;
  activity: ActivityState;
}
const rootReducer = combineReducers<ApplicationState>({
  ui: uiReducer,
  auth: authReducer,
  languages: languagesReducer,
  pathway: pathwayReducer,
  integration: integrationReducer,
  combinations: combinationReducer,
  objectRelationships: objectRelationshipReducer,
  organizations: organizationReducer,
  activity: activityReducer,
});

export default rootReducer;
