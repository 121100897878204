/* eslint-disable no-param-reassign */
import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { sanitizeRecursive } from '@timeedit/preferences-and-dm-commons/lib/src/utils';
import { isLoadingRequest, finishedLoadingSuccess, finishedLoadingFailure } from '../../utils/sliceHelpers';
import api from '../../services/api.service';
import {
  changeGenerateStudentSetsStatusSuccess as changeGenerateStatusInPathway,
  triggerToReloadStudenSetGeneration,
} from './pathway.slice';
import { configService } from '../../services/config.service';
import { StudentSetGenerationStatusString } from '@timeedit/types/lib/constants';
import intl from '../../i18n/intl';
import { Typography } from 'antd';
import { HookAPI } from 'antd/es/modal/useModal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { GenerateStudentSetsSortableField, MongoSortOrder } from '@timeedit/types/lib/constants/dataManagerConstants';
import React from 'react';
import { TPathway, TPathwayConfig } from '../pages/PathwayService.type';
import { GENERATING } from '../pages/PathwayServiceConstants';
import { generateGetCombinationsQuery } from 'study-combinations/pages/useCombinationsWatchers';
import { paginationDefaults } from 'constants/table.constants';

type TStudentSetGenerationStatusStringCounts = Record<StudentSetGenerationStatusString, number>;

const initialState = {
  forms: [],
  filters: {
    filteredCourses: undefined,
  } as {
    filteredCourses: string[] | undefined;
  },
  isGeneratingStudentSets: false,
  isPreparingGeneratingStudentSets: false,
  combinations: [],
  loadings: {
    combinations: false,
    forms: false,
    allocateStudentSet: false,
    deallocateStudentSet: false,
    deleteStudentSets: false,
  },
  combinationsCount: 0,
  countPerAllPages: undefined as unknown as number,
  allIds: [],
  /*
combinationsCount = Count for all combinations withOUT filter
countPerAllPages = Count for all combinations on all pages WITH current filter
*/

  studentSetGenerationStatusStringCounts: {} as TStudentSetGenerationStatusStringCounts,

  generateStudentSetsPage: paginationDefaults.generateStudentSetsPage,
  generateStudentSetsPerPage: paginationDefaults.generateStudentSetsPerPage,
  generateStudentSetsSortBy: undefined as unknown as GenerateStudentSetsSortableField,
  generateStudentSetsSortOrder: undefined as unknown as MongoSortOrder,
};

const language = intl.messages;

// Reducers
const slice = createSlice({
  name: 'combinations',
  initialState,
  reducers: {
    fetchCombinationsFormsRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchCombinationsFormsSuccess: (state: any, { payload }) => {
      state.forms = payload || [];
      finishedLoadingSuccess(state);
    },
    fetchCombinationsFormsFailure: (state: any) => {
      finishedLoadingFailure(state);
    },

    fetchCombinationsRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchCombinationsSuccess: (state: any, { payload }) => {
      const { dataManagerObjects, countPerAllPages, studentSetGenerationStatusStringCounts, onlyCounts, allIds } =
        payload;

      if (!onlyCounts) {
        const pathways: TPathway[] = dataManagerObjects
          .filter((item: any) => item.inputObject.inputType === 'pathway')
          .map((item: any) => {
            const inputContent = item.inputObject?.inputContent || {};
            return {
              ...inputContent,
              _id: item._id,
              reviewStatus: item.reviewStatus,
              formInstanceStatus: inputContent.status,
              studentSetGenerationStatus: item?.studentSetGenerationStatus,
              studentSetGenerationStatusString: item?.studentSetGenerationStatusString,
              studentSetsError: item?.studentSetsError,
              allocationError: item?.allocationError,
              deallocationError: item?.deallocationError,
              generatedStudentSets: (item?.studentSets as Record<string, unknown>[])?.filter(
                (i) => i.size && i.studyCombination,
              ).length,
              mandatoryCourseError: item.mandatoryCourseError,
              electiveCourseError: item.electiveCourseError,
            };
          });
        state.combinations = pathways;
      }
      state.allIds = allIds;
      state.countPerAllPages = countPerAllPages;
      state.studentSetGenerationStatusStringCounts = studentSetGenerationStatusStringCounts;
      finishedLoadingSuccess(state);
    },
    fetchCombinationsFailure: (state: any) => {
      finishedLoadingFailure(state);
    },

    fetchCombinationsCountRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchCombinationsCountSuccess: (state: any, { payload }) => {
      const { dataManagerObjectsCount } = payload;
      state.combinationsCount = dataManagerObjectsCount;
      finishedLoadingSuccess(state);
    },
    fetchCombinationsCountFailure: (state: any) => {
      finishedLoadingFailure(state);
    },

    changeCombinationStatusRequest: (state: any) => {
      isLoadingRequest(state);
    },
    changeCombinationStatusSuccess: (state: any, { payload }) => {
      const { pathwayIds, status } = payload;
      state.combinations = state.combinations.map((pathway: TPathway) => {
        if (!pathwayIds.includes(pathway._id)) return pathway;
        return {
          ...pathway,
          reviewStatus: status,
        };
      });
    },
    changeCombinationStatusFailure: (state: any) => {
      finishedLoadingFailure(state);
    },

    changeGenerateStudentSetsStatusSuccess: (
      state: any,
      {
        payload,
      }: {
        payload: {
          dataManagerObjectIds: string[];
          newStatus?: StudentSetGenerationStatusString;
          studentSetsError?: TPathway['studentSetsError'];
          allocationError?: TPathway['allocationError'];
          deallocationError?: TPathway['deallocationError'];
          generatedStudentSets?: number | undefined;
        };
      },
    ) => {
      const {
        dataManagerObjectIds,
        newStatus,
        studentSetsError,
        allocationError,
        deallocationError,
        generatedStudentSets,
      } = payload;
      state.combinations = state.combinations.map((pathway: TPathway) => {
        if (!dataManagerObjectIds.includes(pathway._id)) return pathway;
        return {
          ...pathway,
          studentSetGenerationStatusString: newStatus || GENERATING,
          studentSetsError: studentSetsError || pathway.studentSetsError,
          allocationError: allocationError || pathway.allocationError,
          deallocationError: deallocationError || pathway.deallocationError,
          generatedStudentSets: generatedStudentSets || pathway.generatedStudentSets,
          // TODO: Also adapt studentSetGenerationStatus!
        };
      });
    },

    updateCombinationFilters: (state, { payload }) => {
      state.filters = payload;
    },

    updateCombinationFilteredCourses: (state, { payload }: { payload: string[] }) => {
      state.filters.filteredCourses = payload;
    },

    generateStudentSetsChangePagination: (
      state,
      {
        payload,
      }: {
        payload: {
          generateStudentSetsPage: number;
          generateStudentSetsPerPage: number;
        };
      },
    ) => {
      state.generateStudentSetsPage = payload.generateStudentSetsPage;
      state.generateStudentSetsPerPage = payload.generateStudentSetsPerPage;
    },

    updateStudentSetGenerationSorting: (
      state,
      {
        payload,
      }: {
        payload: {
          generateStudentSetsSortBy: GenerateStudentSetsSortableField;
          generateStudentSetsSortOrder: MongoSortOrder;
        };
      },
    ) => {
      state.generateStudentSetsSortBy = payload.generateStudentSetsSortBy;
      state.generateStudentSetsSortOrder = payload.generateStudentSetsSortOrder;
    },

    updateGeneratingStudentSetsStatus: (state, { payload }: { payload: boolean }) => {
      state.isGeneratingStudentSets = payload;
    },

    updatePreparingGeneratingStudentSetsStatus: (state, { payload }: { payload: boolean }) => {
      state.isPreparingGeneratingStudentSets = payload;
    },

    updateAllocateStudentSetLoading: (state, { payload }: { payload: boolean }) => {
      state.loadings.allocateStudentSet = payload;
    },

    updateDeallocateStudentSetLoading: (state, { payload }: { payload: boolean }) => {
      state.loadings.deallocateStudentSet = payload;
    },

    updateDeleteStudentSetsLoading: (state, { payload }: { payload: boolean }) => {
      state.loadings.deleteStudentSets = payload;
    },
  },
});

const {
  fetchCombinationsFormsRequest,
  fetchCombinationsFormsSuccess,
  fetchCombinationsFormsFailure,

  fetchCombinationsRequest,
  fetchCombinationsSuccess,
  fetchCombinationsFailure,

  fetchCombinationsCountRequest,
  fetchCombinationsCountSuccess,
  fetchCombinationsCountFailure,

  changeGenerateStudentSetsStatusSuccess,

  updateCombinationFilters,
  updateCombinationFilteredCourses,
  generateStudentSetsChangePagination,
  updateStudentSetGenerationSorting,
  updateGeneratingStudentSetsStatus,
  updatePreparingGeneratingStudentSetsStatus,
  updateAllocateStudentSetLoading,
  updateDeallocateStudentSetLoading,
  updateDeleteStudentSetsLoading,
} = slice.actions;

export {
  updateGeneratingStudentSetsStatus,
  updateCombinationFilters,
  updateCombinationFilteredCourses,
  generateStudentSetsChangePagination,
  updateStudentSetGenerationSorting,
  changeGenerateStudentSetsStatusSuccess,
};

// Actions
export const fetchCombinationsForms = () => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchCombinationsFormsRequest());
    const response = await api.get({
      endpoint: `${configService().REACT_APP_PATHWAY_SERVICE_URL}v1/pathway-config-from-user`,
    });
    dispatch(fetchCombinationsFormsSuccess(response?.data?.pathwayConfigFromUser));
  } catch (e) {
    dispatch(fetchCombinationsFormsFailure());
    console.error(e);
  }
};

export const fetchFormCombinations =
  (
    formId: string,
    query: Record<string, unknown>,
    page: number,
    perPage: number,
    sortBy?: GenerateStudentSetsSortableField,
    sortDirection?: MongoSortOrder,
    onlyCounts?: boolean,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(fetchCombinationsRequest());
      const res = await api.post({
        endpoint: `${configService().REACT_APP_PATHWAY_SERVICE_URL}v1/data-manager-objects`,
        data: sanitizeRecursive({
          pathwayConfigFromUserId: formId,
          reviewStatus: ['KEEP'],
          page,
          perPage,
          sortBy,
          sortDirection,
          countByStatus: 'studentSetGenerationStatusString',
          onlyCounts,
          ...query,
        }),
        successMessage: false,
      });
      dispatch(fetchCombinationsSuccess({ ...res.data, formId, onlyCounts }));
    } catch (e) {
      dispatch(fetchCombinationsFailure());
      console.error(e);
    }
  };

export const fetchCombinationsCount = (formId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchCombinationsCountRequest());
    const res = await api.get({
      endpoint: `${configService().REACT_APP_PATHWAY_SERVICE_URL}v1/data-manager-objects/count`,
      data: {
        pathwayConfigFromUserId: formId,
        reviewStatus: 'KEEP',
      },
    });
    dispatch(fetchCombinationsCountSuccess({ ...res.data, formId }));
  } catch (e) {
    dispatch(fetchCombinationsCountFailure());
    console.error(e);
  }
};

export const generateStudentSetsPaginationSelector = (state: any) =>
  ({
    generateStudentSetsPage: state.combinations.generateStudentSetsPage,
    generateStudentSetsPerPage: state.combinations.generateStudentSetsPerPage,
  }) as {
    generateStudentSetsPage: number;
    generateStudentSetsPerPage: number;
  };

export const generateStudentSetsSortingSelector = (state: any) =>
  ({
    generateStudentSetsSortBy: state.combinations.generateStudentSetsSortBy,
    generateStudentSetsSortOrder: state.combinations.generateStudentSetsSortOrder,
  }) as {
    generateStudentSetsSortBy: GenerateStudentSetsSortableField;
    generateStudentSetsSortOrder: MongoSortOrder;
  };
export default slice.reducer;

// Selectors
export const combinationFormsSelector = (state: any) => state.combinations.forms as any[];
export const combinationFiltersSelector = (state: any) => state.combinations.filters as any;

export const combinationSelector = () => (state: any) => {
  return state.combinations.combinations;
};

/*
combinationsCount = Count for all combinations withOUT filter
countPerAllPages = Count for all combinations on all pages WITH filter
*/
export const combinationsCountSelector = (state: any): undefined | number => {
  return state.combinations.combinationsCount;
};
export const generateStudentSetsCombinationsCountPerAllPagesSelector = (state: any): undefined | number => {
  return state.combinations.countPerAllPages;
};
export const generateStudentSetsCombinationsAllIdsSelector = (state: any): string[] => {
  return state.combinations.allIds;
};

export const studentSetGenerationStatusStringCountsSelector = (
  state: any,
): undefined | TStudentSetGenerationStatusStringCounts => {
  return state.combinations.studentSetGenerationStatusStringCounts;
};

export const combinationSelectedFormSelector = (state: any): undefined | TPathwayConfig => {
  const form = state.combinations.forms.find((form: any) => form._id === state.combinations.filters.formId);
  if (!form) return undefined;
  let programObject;
  if (!state.integration && !state.integration.mapping) return undefined;
  for (const key in state.integration.mapping?.objectTypes) {
    if (state.integration.mapping?.objectTypes[key]?.applicationObjectTypeGroup === 'PROGRAM') {
      programObject = state.integration.mapping.objectTypes[key];
      break;
    }
  }
  return {
    ...form,
    objectScope: programObject?.objectTypeExtId || 'program',
  };
};

export const selectedFormByFormIdSelector =
  (formId: string): any =>
  (state: any) => {
    const form = state.combinations.forms.find((form: any) => form._id === formId);
    if (!form) return undefined;
    let programObject;
    if (!state.integration && !state.integration.mapping) return undefined;
    for (const key in state.integration.mapping?.objectTypes) {
      if (state.integration.mapping?.objectTypes[key]?.applicationObjectTypeGroup === 'PROGRAM') {
        programObject = state.integration.mapping.objectTypes[key];
        break;
      }
    }
    return {
      ...form,
      objectScope: programObject?.objectTypeExtId || 'program',
    };
  };

export const generatingStudentCombinationSetsStatusSelector = () => (state: any) => {
  return state.combinations.isGeneratingStudentSets;
};

export const preparingGeneratingStudentCombinationSetsStatusSelector =
  () =>
  (state: any): boolean => {
    return state.combinations.isPreparingGeneratingStudentSets;
  };

export const generateStudentCombinationSets = (dataManagerObjectIds: string[]) => async (dispatch: Dispatch) => {
  try {
    dispatch(updatePreparingGeneratingStudentSetsStatus(true));
    await api.post({
      endpoint: `${
        configService().REACT_APP_PATHWAY_SERVICE_URL
      }v1/data-manager-objects/student-set-generation/start-multiple`,
      data: {
        dataManagerObjectIds,
      },
      successMessage: language['generate_student_sets.started_message'] as string,
      successToastTitle: language['generate_student_sets.started_title'] as string,
    });
    dispatch(updatePreparingGeneratingStudentSetsStatus(false));
    dispatch(changeGenerateStudentSetsStatusSuccess({ dataManagerObjectIds }));
    dispatch(changeGenerateStatusInPathway({ dataManagerObjectIds }));
    dispatch(updateGeneratingStudentSetsStatus(true));
  } catch (e) {
    dispatch(updatePreparingGeneratingStudentSetsStatus(false));
    console.error(e);
  }
};

export const cancelStudentCombinationSets = (dataManagerObjectIds: string[]) => async () => {
  try {
    await api.post({
      endpoint: `${
        configService().REACT_APP_PATHWAY_SERVICE_URL
      }v1/data-manager-objects/student-set-generation/abort-multiple`,
      data: {
        dataManagerObjectIds,
      },
    });
  } catch (e) {
    console.error(e);
  }
};

export const deleteStudentCombinationSets = (dataManagerObjectIds: string[]) => async (dispatch: Dispatch) => {
  try {
    dispatch(updateDeleteStudentSetsLoading(true));
    const response = await api.post({
      endpoint: `${
        configService().REACT_APP_PATHWAY_SERVICE_URL
      }v1/data-manager-objects/student-set-generation/delete-multiple`,
      data: {
        dataManagerObjectIds,
      },
    });

    const { dataManagerObjectIds: modifiedIds } = response.data;

    dispatch(
      changeGenerateStudentSetsStatusSuccess({
        dataManagerObjectIds: modifiedIds,
        newStatus: 'COMPLETE',
      }),
    );
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(updateDeleteStudentSetsLoading(false));
  }
};

export const allocateStudentCombinationSets =
  (dataManagerObjectIds: string[], modal: HookAPI) => async (dispatch: Dispatch) => {
    try {
      dispatch(updateAllocateStudentSetLoading(true));
      const responseData = await api.post({
        endpoint: `${
          configService().REACT_APP_PATHWAY_SERVICE_URL
        }v1/data-manager-objects/student-set-generation/allocate-multiple`,
        data: {
          dataManagerObjectIds,
        },
        timeout: 300000, // 5 mins timeout
      });

      if (responseData.data.idsFailed.length > 0) {
        modal.error({
          icon: <ExclamationCircleOutlined color="error" type="error" />,
          title: language.student_set_allocation_failed_title as string,
          content: (
            <div>
              {language.student_set_allocation_failed_description_multiple as string}
              :
              <br />
              {responseData.data.idsFailed.map((i: { id: string; errorMessage: string }) => (
                <div key={i.id}>
                  {i.id}: {i.errorMessage}
                </div>
              ))}
            </div>
          ),
          onCancel: () => {},
        });
      }

      dispatch(
        changeGenerateStudentSetsStatusSuccess({
          dataManagerObjectIds: responseData?.data?.idsSucceeded,
          newStatus: 'STUDENT_SETS_ALLOCATED',
        }),
      );

      for (const f of responseData.data.idsFailed) {
        dispatch(
          changeGenerateStudentSetsStatusSuccess({
            dataManagerObjectIds: [f.id],
            newStatus: 'ALLOCATION_ERROR',
            allocationError: { error: true, message: f.errorMessage },
          }),
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(updateAllocateStudentSetLoading(false));
    }
  };

export const deallocateStudentCombinationSets =
  (dataManagerObjectIds: string[], modal: HookAPI) => async (dispatch: Dispatch) => {
    try {
      dispatch(updateDeallocateStudentSetLoading(true));
      const responseData = await api.post({
        endpoint: `${
          configService().REACT_APP_PATHWAY_SERVICE_URL
        }v1/data-manager-objects/student-set-generation/deallocate-multiple`,
        data: {
          dataManagerObjectIds,
        },
        timeout: 300000, // 5 mins timeout
      });

      if (responseData.data.idsFailed.length > 0) {
        modal.error({
          icon: <ExclamationCircleOutlined color="error" type="error" />,
          title: language.student_set_deallocation_failed_title as string,
          content: (
            <div>
              {language.student_set_deallocation_failed_description_multiple as string}
              :
              <br />
              {responseData.data.idsFailed.map((data: { id: string; errorMessage: string }) => (
                <Typography.Paragraph key={data.id}>
                  <Typography.Text strong>{data.id}</Typography.Text>: {data.errorMessage}
                </Typography.Paragraph>
              ))}
            </div>
          ),
          onCancel: () => {},
        });
      }

      dispatch(
        changeGenerateStudentSetsStatusSuccess({
          dataManagerObjectIds: responseData?.data?.idsSucceeded,
          newStatus: 'STUDENT_SETS_GENERATED',
        }),
      );

      for (const failedData of responseData.data.idsFailed) {
        dispatch(
          changeGenerateStudentSetsStatusSuccess({
            dataManagerObjectIds: [failedData.id],
            newStatus: 'DEALLOCATION_ERROR',
            allocationError: { error: true, message: failedData.errorMessage },
          }),
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(updateDeallocateStudentSetLoading(false));
    }
  };

export const allocateStudentSetLoadingSelector = (state: any) =>
  state.combinations.loadings?.allocateStudentSet as boolean;

export const deallocateStudentSetLoadingSelector = (state: any) =>
  state.combinations.loadings?.deallocateStudentSet as boolean;

export const studentSetsLoadingSelector = (state: any) => state.combinations.loading as boolean;

export const deleteStudentSetsLoadingSelector = (state: any) =>
  state.combinations.loadings.deleteStudentSets as boolean;
