/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, InputNumber, Modal, ModalProps, Spin, Table, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import intl from 'i18n/intl';
import {
  fetchCoursesWithoutUpdatingState,
  fetchFormPathwaysWithoutUpdatingState,
  updateMultiplePathways,
} from 'study-combinations/slices/pathway.slice';
import { TPathway } from '../../PathwayService.type';
import { getChangedPahtwaysAfterEdit, toTPathway } from '../../PathwayService.utils';
import { compact, groupBy, keyBy } from 'lodash';
import { TCourseRowData, TEditingExpectedEnrollment } from '../../Views/Tabs/ReviewByCourse';

type TCourseTableData = TCourseRowData & {
  scExpectedEnrollment: number;
  diff: number;
};

const language = intl.messages;

const CoursesTable = ({ data }: { data: TCourseTableData[] }) => {
  const columns = useMemo(
    () => [
      {
        title: language.course as string,
        dataIndex: 'name',
      },
      {
        title: language.expected_enrollment_course_shorten as string,
        dataIndex: 'expectedEnrollmentOfCourse',
      },
      {
        title: language.expected_enrollment_study_combination_shorten as string,
        dataIndex: 'scExpectedEnrollment',
      },
      {
        title: language.enrolment_diff as string,
        dataIndex: 'diff',
        render: (expectedEnrollmentDiff: number) => {
          return (
            <div className={expectedEnrollmentDiff < 0 ? 'text--danger' : 'text--success'}>
              <b>{expectedEnrollmentDiff}</b>
            </div>
          );
        },
      },
    ],
    [],
  );

  return <Table pagination={false} dataSource={data} columns={columns} />;
};

interface Props extends Omit<ModalProps, 'onOk' | 'visible'> {
  pathwayIds?: string[];
  errorMessage?: string;
  onOk: (changed?: boolean) => void;
}

const ModalFixingExpectedEnrollment = (props: Props) => {
  const dispatch = useDispatch();
  const { pathwayIds, onOk, ...rest } = props;
  const [pathways, setPathways] = useState<TPathway[]>([]);
  const [coursesForReviewByCourse, setCoursesForReviewByCourse] = useState<
    Record<string, Record<string, TCourseRowData>>
  >({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editingExpectedEnrollment, setEditingExpectedEnrollment] = useState<TEditingExpectedEnrollment>({});

  const [pathwaysToShow, setPathwaysToShow] = useState<TPathway[]>([]);

  useEffect(() => {
    if (!props.open) {
      setPathways([]);
    }
  }, [props.open]);

  useEffect(() => {
    if (!props.open) return;
    const doFetchingData = async () => {
      setLoading(true);
      const pathwaysRes = await fetchFormPathwaysWithoutUpdatingState({ id: pathwayIds });
      const pathways: TPathway[] = (pathwaysRes.data?.dataManagerObjects || []).map((pathway: Partial<TPathway>) => {
        const formattedPathway = toTPathway(pathway);
        return {
          ...formattedPathway,
          allCourseIds: [...(formattedPathway.electiveCourseIds || []), ...(formattedPathway.mandatoryCourseIds || [])],
        };
      });
      setPathways(pathways);

      const groupedByFormId = groupBy(pathways, 'pathwayConfigFromUserId');
      const courseResults: typeof coursesForReviewByCourse = {};
      await Promise.all(
        Object.keys(groupedByFormId).map(async (formId) => {
          const pathwaysInForm = groupedByFormId[formId];
          const allCourseIds = compact(pathwaysInForm.flatMap((pathway) => pathway.allCourseIds));
          const coursesRes = await fetchCoursesWithoutUpdatingState(allCourseIds, pathways[0]?.pathwayConfigFromUserId);
          courseResults[formId] = keyBy(coursesRes.data?.coursesForReviewByCourse || [], 'id');
          return coursesRes;
        }),
      );
      setLoading(false);
      setCoursesForReviewByCourse(courseResults);
    };
    doFetchingData();
  }, [props.open, pathwayIds]);

  const onSubmitChange = async () => {
    const changedPathways = getChangedPahtwaysAfterEdit(pathways, editingExpectedEnrollment, {});
    if (changedPathways) {
      setSaving(true);
      await dispatch(updateMultiplePathways(changedPathways));
      setSaving(false);
      onOk(true);
    } else {
      onOk(false);
    }
  };

  const getCourseTableData = useCallback(
    (pathway: TPathway): TCourseTableData[] => {
      const courses: TCourseRowData[] = pathway.allCourseIds
        .map((courseId: string) => coursesForReviewByCourse[pathway.pathwayConfigFromUserId]?.[courseId])
        .filter((course: TCourseRowData) => course);

      return courses.map((course) => {
        let diff = 0;
        course.pathways.forEach((pathway) => {
          if (editingExpectedEnrollment?.[pathway._id] !== undefined) {
            diff += (editingExpectedEnrollment?.[pathway._id] ?? 0) - (pathway?.expectedEnrollment ?? 0);
          }
        });

        return {
          ...course,
          scExpectedEnrollment: (course.expectedEnrollmentOfStudyCombinations ?? 0) + diff,
          diff: (course.expectedEnrollmentDiff ?? 0) - diff,
        };
      });
    },
    [coursesForReviewByCourse, editingExpectedEnrollment],
  );

  useEffect(() => {
    if (pathwaysToShow.length === 0) {
      const pathwaysToView = pathways.filter((pathway) => {
        const courseTableData = getCourseTableData(pathway);

        // If all courses have enough expected enrollment, don't show this pathway
        return !courseTableData.every((entry) => entry.diff >= 0);
      });

      setPathwaysToShow(pathwaysToView);
    }
  }, [pathways, getCourseTableData, pathwaysToShow]);

  return (
    <Modal
      {...rest}
      onOk={() => onSubmitChange()}
      okButtonProps={{
        loading: saving,
      }}
    >
      <Spin spinning={loading}>
        <div className="te-flex te-flex-col te-gap-y-2" style={{ minHeight: 150 }}>
          {pathwaysToShow.length === 0 && (
            <Typography.Paragraph>{`${language.all_study_combinations_have_been_fixed}`}</Typography.Paragraph>
          )}
          {pathwaysToShow.map((pathway) => {
            const courseTableData = getCourseTableData(pathway);

            return (
              <div className="te-flex te-flex-col te-p-4 te-bg-grey" key={pathway._id}>
                <b>{pathway.name}</b>
                <div className="te-text-xs te-mb-1">
                  {`${language.expected_enrollment}: `}
                  <InputNumber
                    min={1}
                    required
                    value={editingExpectedEnrollment[pathway._id] || pathway.expectedEnrollment}
                    onChange={(val: number | null) => {
                      setEditingExpectedEnrollment({
                        ...editingExpectedEnrollment,
                        [pathway._id]: val || 1,
                      });
                    }}
                    size="small"
                  />
                </div>
                <div>
                  <CoursesTable data={courseTableData} />
                </div>
              </div>
            );
          })}
        </div>
      </Spin>
    </Modal>
  );
};

export const ModalAllocationError = (props: Props) => {
  const { errorMessage, pathwayIds, onOk } = props;
  const language = intl.messages;
  const [modalVisible, setModalVisible] = useState(false);

  // @TODO: Is there any better way?
  const isFixable = useMemo(() => errorMessage?.includes('do not have enough'), [errorMessage]);

  if (!pathwayIds) return null;

  return (
    <div>
      {errorMessage}
      {isFixable && (
        <>
          <ModalFixingExpectedEnrollment
            width={700}
            pathwayIds={pathwayIds}
            open={modalVisible}
            title={language.edit_expected_enrolment_in_study_combinations as string}
            onCancel={() => setModalVisible(false)}
            onOk={(changed) => {
              setModalVisible(false);
              onOk(changed);
            }}
          />
          <Button type="link" onClick={() => setModalVisible(true)}>
            {language.fix_it_now as string}
          </Button>
        </>
      )}
    </div>
  );
};
