/* eslint-disable no-redeclare */
import { useEffect, useState } from 'react';
import { isDefined, TField, TType } from '@timeedit/registration-shared';
import api from '../services/api.service';
import TEObjectManager from 'activities/services/TEObjects.service';

type RequiredData = {
  types: TType[];
  fields: TField[];
};

type FetchRequiredDataResult =
  | { status: 'loading' }
  | { status: 'success'; data: RequiredData }
  | { status: 'error'; error: unknown };

export function useFetchRequiredData(): FetchRequiredDataResult {
  const [data, setData] = useState<RequiredData>();
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    let abort = false;

    async function fetch() {
      const [typesResponse, fieldsResponse] = await Promise.all([api.findTypes({}), api.findFields({})]);
      if (!abort) {
        setData({
          types: typesResponse.results,
          fields: fieldsResponse.results,
        });

        TEObjectManager.init({ fields: fieldsResponse.results, objectTypes: typesResponse.results });
      }
    }

    fetch().catch((e) => {
      setError(e);
    });

    return () => {
      abort = true;
    };
  }, []);

  if (isDefined(error)) {
    return { status: 'error', error };
  }

  if (isDefined(data)) {
    return { status: 'success', data };
  }

  return { status: 'loading' };
}
