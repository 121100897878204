import intl from 'i18n/intl';
import { TType } from '@timeedit/registration-shared';

const language = intl.messages;

export const getObjectTypeLabel = (
  object: TType,
  { primaryObjectType, activityObjectType }: { primaryObjectType: string; activityObjectType: string },
) => {
  let suffix = '';
  if (object.extId.toString() === primaryObjectType) {
    suffix += ` (${language.primary_object})`;
  } else if (object.extId.toString() === activityObjectType) {
    suffix = ` (${language.activity_type})`;
  }

  return suffix ? `${object.name}${suffix}` : object.name;
};
