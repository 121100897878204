import React, { useEffect, useMemo, useState } from 'react';
import ActivityDrawerWrapper from './ActivityDrawerWrapper';
import { ActivityInfo, ActivityNumberOfTracks } from './ActivityDrawerComponents';
import ActivityValues, { ActivityTracks } from './ActivityValues';
import { useActivityGetter } from './ActivityDrawer.hook';
import { Button, Form, Modal } from 'antd';
import intl from 'i18n/intl';
import { deleteActivitiesById, updateActivitiesValues } from 'activities/services/activities.service';
import { ActivityEvents } from 'activities/pages/types/activity.type';
import ActivityTags from './ActivityTags';
import { DeleteOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { organizationIdSelector } from 'slices/auth.slice';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';
import { toActivityWeekRange } from 'activities/utils';
import ActivityWeeks from './ActivityWeeks';

const language = intl.messages as Record<string, string>;

function ActivityDrawer() {
  const [saving, setSaving] = useState(false);
  const [open, setOpen] = useState(false);
  const [activityId, setActivityId] = useState<undefined | string>(undefined);

  const { loading, activity, initialValues, activitiesInSameTracks } = useActivityGetter({ activityId: activityId! });
  const [modal, modalContextHolder] = Modal.useModal();

  const organizationId = useSelector(organizationIdSelector);
  const editable = useMemo(() => {
    if (!activity) return false;
    return ![EActivityStatus.IN_REVIEW].includes(activity?.activityStatus);
  }, [activity]);

  const onSave = async (updatedValues: Record<string, string>) => {
    if (!activity || !activity.formId) return;
    setSaving(true);
    await updateActivitiesValues(activity.formId.toString(), activity._id.toString(), 'FLAT', updatedValues);
    setSaving(false);
    document.dispatchEvent(new CustomEvent(ActivityEvents.REFETCH_ACTIVITIES_TABLE));
    onClose();
  };

  const onOpen = (e: Event) => {
    setOpen(true);
    setActivityId((e as CustomEvent<{ activityId: string }>).detail?.activityId);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener(ActivityEvents.OPEN_ACTIVITY_DRAWER, onOpen);
    document.addEventListener(ActivityEvents.CLOSE_ACTIVITY_DRAWER, onClose);
    return () => {
      document.removeEventListener(ActivityEvents.OPEN_ACTIVITY_DRAWER, onOpen);
      document.removeEventListener(ActivityEvents.CLOSE_ACTIVITY_DRAWER, onClose);
    };
  }, []);

  useEffect(() => {
    if (!open) setActivityId(undefined);
  }, [open]);

  const onDeleteConfirm = () => {
    modal.confirm({
      title: language['activities.confirm_to_delete_activity'],
      onOk: async () => {
        if (organizationId && activity?._id) {
          await deleteActivitiesById(organizationId, [activity?._id.toString()]);
          if (typeof onClose === 'function') {
            document.dispatchEvent(new CustomEvent(ActivityEvents.REFETCH_ACTIVITIES_TABLE));
            onClose();
          }
        }
      },
    });
  };

  const disabledWeeks = useMemo(() => {
    if (!activitiesInSameTracks) return [];
    return activitiesInSameTracks.flatMap((activity) => {
      return toActivityWeekRange(activity.metadata.startDate, activity.metadata.endDate);
    });
  }, [activitiesInSameTracks]);

  return (
    <ActivityDrawerWrapper
      onClose={onClose}
      open={open}
      title="Activity"
      initialValues={initialValues}
      primaryObject={activity?.metadata?.primaryObject}
      editable={editable}
      loading={loading}
      saving={saving}
      onSave={onSave}
    >
      <>
        <ActivityInfo editable={false} loading={loading} activityType={activity?.metadata.activityType} />
        <ActivityNumberOfTracks totalTracks={activity?.metadata?.totalTracks} />
        <ActivityValues
          editable={editable}
          loading={loading}
          values={activity?.values}
          track={activity?.metadata?.track}
          title={language.track_info}
        >
          <ActivityTracks track={activity?.metadata.track} />
          <ActivityWeeks editable required allowClear={false} disabledWeeks={disabledWeeks} />
        </ActivityValues>
        <ActivityTags formId={activity?.formId.toString()} editable={editable} />
        <Form.Item label=" " colon={false}>
          <Button size="small" danger onClick={onDeleteConfirm}>
            <DeleteOutlined /> {language.delete_activity}
          </Button>
        </Form.Item>
        {modalContextHolder}
      </>
    </ActivityDrawerWrapper>
  );
}

export default ActivityDrawer;
