import intl from 'i18n/intl';

const language = intl.messages;

export const TABS = [
  {
    key: 'generalSettings',
    title: language.general_settings as string,
  },
  {
    key: 'objects',
    title: language.objects as string,
  },
  {
    key: 'fields',
    title: language.fields as string,
  },
  {
    key: 'preview',
    title: language.preview as string,
  },
];
