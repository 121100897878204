/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// ACTIONS
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from '../utils/sliceHelpers';

// TYPES
import { TOrganizationPublic } from '@timeedit/types/lib/types';
import { createOrganization } from '@timeedit/types/lib/utils';
import { IDefaultAPIState, IState } from '../types/state.interface';
import { TAppDispatch } from '..';

const initialState: IDefaultAPIState = {
  loading: false,
  hasErrors: false,
  results: [],
  map: {},
  page: 0,
  limit: 10000,
  totalPages: 0,
};

// Slice
const slice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    fetchOrganizationRequest: (state) => {
      isLoadingRequest(state);
    },
    fetchOrganizationSuccess: (state, { payload }) => {
      const organization = createOrganization(payload);
      state.results = [organization];
      state.map[organization.id] = organization;
      finishedLoadingSuccess(state);
    },
    fetchOrganizationFailure: (state) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

export const organizationSelector = (state: IState): TOrganizationPublic => {
  return state.organizations.map[state.auth.user?.organizationId ?? ''];
};

const { fetchOrganizationRequest, fetchOrganizationSuccess, fetchOrganizationFailure } = slice.actions;

export const fetchOrganization = () => async (dispatch: TAppDispatch, getState: any) => {
  try {
    const storeState = getState();
    const organizationId = storeState.auth?.user?.organizationId;
    if (organizationId) {
      dispatch(fetchOrganizationRequest());
      const result = await api.get({
        endpoint: `/organizations/public?id=${organizationId}`,
      });
      dispatch(fetchOrganizationSuccess(result.results[0]));
    } else {
      dispatch(fetchOrganizationFailure());
    }
  } catch (e) {
    dispatch(fetchOrganizationFailure());
    console.error(e);
  }
};
