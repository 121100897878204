import intl from 'i18n/intl';
import { DataManagerObjectReviewStatus } from '@timeedit/types/lib/constants';

const language = intl.messages;

export const PATHWAY_STATUSES_MAPPING: Record<
  string,
  { key: DataManagerObjectReviewStatus; label: string; type: string }
> = {
  REJECTED: {
    key: 'REJECTED',
    label: language.rejected as string,
    type: 'error',
  },
  KEEP: {
    key: 'KEEP',
    label: language.approved as string,
    type: 'success',
  },
  NOT_REVIEWED: {
    key: 'NOT_REVIEWED',
    label: language.not_reviewed as string,
    type: 'default',
  },
};
