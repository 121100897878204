import React, { useMemo, useState } from 'react';
import { Typography, Empty, Dropdown, Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import intl, { getInlineString } from '../../../../i18n/intl';

import {
  convertToPathwayRowData,
  filterByNumber,
  getCustomColumnLabelsBasedOnMappingAndForm,
  pathwayIsAcceptedOrRejected,
} from '../../PathwayService.utils';
import { TCreatePathwayPayload, TPathway } from '../../PathwayService.type';
import {
  TEInactiveObjectIdsSelector,
  TEObjectLabeledFieldSelector,
  TEObjectMappingSelector,
  TEObjectSelector,
} from '../../../../slices/integration.slice';
import { capitalize, compact, isEmpty } from 'lodash';
import {
  pathwaySelectedFormSelector,
  pathwaySelector,
  pathwayCountPerAllPagesSelector,
  dataManagerObjectCountSelector,
  changePathwayStatus,
  deletePathways,
  createNewPathway,
  editExistingPathway,
  reviewStudyCombinationsChangePagination,
  updateReviewByStudyCombinationsSorting,
  paginationCurrentPageSelector,
  loadingSelector,
  allPathwayIdsSelector,
} from '../../../slices/pathway.slice';
import { useTEObjectWatcher } from '../../usePathwayWatchers';
import { DownOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { EditableNumberInput, TEBaseTable } from '@timeedit/ui-components';
import PathwayFilters from '../../components/PathwayFilters';
import PathwaysSummary from '../../components/PathwaysSummary';
import PathwaysFooterActions from '../../components/PathwaysFooterActions';
import PathwayCoursesModal from '../../components/PathwayCoursesModal';
import ModalCreatingOrEditingPathway from '../../components/ModalCreatingOrEditingPathway';
import { ReviewByStudyCombinationSortableField, mongoReactSortOrderMapping } from '@timeedit/types/lib/constants';
import { renderNumber } from '../../../utils/stringUtils';
import TEObjectLabel from '../../components/TEObjectLabel';
import { useTableSelection } from 'hooks/useTableSelection';
import { paginationDefaults } from 'constants/table.constants';
import CoursesLabel from 'study-combinations/pages/components/Table/CoursesLabel';

const language = intl.messages;

function ReviewByStudyConbination() {
  const dispatch = useDispatch();
  const currentPage = useSelector(paginationCurrentPageSelector);
  const form = useSelector(pathwaySelectedFormSelector);
  const loading = useSelector(loadingSelector);
  const pathwayObjects = useSelector(TEObjectSelector(form?.pathwaysSettings.pathways.datasource));
  const pathways = useSelector(pathwaySelector());
  const allPathwayIds = useSelector(allPathwayIdsSelector());
  const countPerAllPages = useSelector(pathwayCountPerAllPagesSelector());
  const labeledFields = useSelector(TEObjectLabeledFieldSelector());
  const mapping = useSelector(TEObjectMappingSelector());
  const inactiveCourses = useSelector(TEInactiveObjectIdsSelector(form?.pathwaysSettings.course.datasource));

  const dataManagerObjectCount = useSelector(dataManagerObjectCountSelector);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPathwayToEdit, setSelectedPathwayToEdit] = useState<undefined | TPathway>(undefined);

  const [coursesModalVisible, setCoursesModalVisible] = useState<TPathway>();

  const { indexedPathways, courses, filters } = useTEObjectWatcher();

  const rowData = useMemo(() => {
    return convertToPathwayRowData(
      pathways as TPathway[],
      { courses, pathwayObjects },
      {
        labeledCourseField: labeledFields?.[form?.pathwaysSettings?.course?.datasource || ''],
        labeledPathwayField: labeledFields?.[form?.pathwaysSettings?.pathways?.datasource || ''],
        creditField: form?.pathwaysSettings?.course?.fields?.credits,
        historicalField: form?.pathwaysSettings?.pathways.enrollment.historicalEnrollment,
        pathwayDatasource: form?.pathwaysSettings?.pathways.datasource,
        courseDatasource: form?.pathwaysSettings?.course?.datasource,
      },
    ).filter((item) => {
      // This is temporary out of use because the "filter by credits" option is not visible to the user. TODO: Find better solution!
      return [filterByNumber(filters?.credits, item.credits)].every((result) => result);
    });
  }, [pathways, form, labeledFields, filters, courses?.length, pathwayObjects?.length]);

  const customColumnLabelsBasedOnMappingAndForm = useMemo(
    () => getCustomColumnLabelsBasedOnMappingAndForm(mapping, form),
    [mapping, form],
  );

  const {
    Utils: { selectedRowKeys, onSelectedRowKeyChange, isSelectingAllInAllPage },
    Component: TableAllItemsSelection,
  } = useTableSelection({
    tableData: rowData,
    countPerAllPages,
    labels: {
      allInPageAreSelected: 'all_study_combinations_in_page_are_selected',
      selectAll: 'select_all_number_of_study_combinations',
      allInAllPageAreSelected: 'all_study_combinations_in_all_page_are_selected',
    },
    allKeys: allPathwayIds,
  });

  const allocatedPathways = useMemo(() => rowData.filter(({ disabled }) => disabled).map(({ _id }) => _id), [rowData]);

  const onStatusChange =
    (item: TPathway) =>
    ({ key }: { key: string }) => {
      switch (key) {
        case 'approve':
          dispatch(changePathwayStatus([item._id], 'KEEP'));
          break;
        case 'reject':
          dispatch(changePathwayStatus([item._id], 'REJECTED'));
          break;

        case 'not_review':
          dispatch(changePathwayStatus([item._id], 'NOT_REVIEWED'));
          break;

        case 'delete':
          dispatch(deletePathways([item._id]));
          break;

        default:
          break;
      }
    };

  const onOpenCourseModal = (pathway: TPathway) => {
    setCoursesModalVisible(pathway);
  };

  const disableUpdateStatusWhenGeneratingStudentSet = (pathway: TPathway) => {
    if (pathway.studentSetGenerationStatusString === 'GENERATING') {
      return true;
    }
    return !!(
      pathway.studentSetGenerationStatus?.started &&
      !pathway.studentSetGenerationStatus?.errored &&
      !pathway.studentSetGenerationStatus?.aborted &&
      !pathway.studentSetGenerationStatus?.succeeded
    );
  };

  const onUpdateStudyCombinationInTable = (rowData: TPathway, dataToUpdate: Partial<TCreatePathwayPayload>) => {
    const updatedData = {
      mandatoryCourseIds: rowData.mandatoryCourseIds || [],
      electiveCourseIds: rowData.electiveCourseIds || [],
      name: rowData.name,
      formId: rowData.pathwayConfigFromUserId,
      scopedObject: rowData.programExtId || '',
      expectedEnrollment: rowData.expectedEnrollment || 0,
      ...dataToUpdate,
    };

    dispatch(editExistingPathway(updatedData, rowData._id));
  };

  const onCreateOrEditStudyCombination = (data: TCreatePathwayPayload) => {
    if (!selectedPathwayToEdit) {
      dispatch(createNewPathway(data));
    } else {
      dispatch(editExistingPathway(data, selectedPathwayToEdit._id));
    }
    setSelectedPathwayToEdit(undefined);
    setModalVisible(false);
  };

  const columns = [
    {
      title: customColumnLabelsBasedOnMappingAndForm.pathwaysLabel ?? language.study_combination,
      key: 'pathway',
      width: 300,
      resizable: true,
      cellRenderer: (cell: any) => {
        return (
          <Typography.Text ellipsis>
            <span className="">
              {cell.rowData.name}
              &nbsp;&nbsp;
              <InfoCircleOutlined
                style={{ verticalAlign: 'text-bottom' }}
                onClick={() => onOpenCourseModal(cell.rowData as TPathway)}
              />
            </span>
          </Typography.Text>
        );
      },
    },
    {
      title: customColumnLabelsBasedOnMappingAndForm.courseLabel ?? language.courses,
      key: 'coursesLabel',
      width: 300,
      resizable: true,
      cellRenderer: (cell: any) => {
        return (
          <TEObjectLabel datasource={cell.rowData.fieldSettings.courseDatasource}>
            <CoursesLabel courses={cell.rowData.courses} inactiveCourses={inactiveCourses} />
          </TEObjectLabel>
        );
      },
      sortable: true,
    },
    {
      title: language.type,
      key: 'status',
      width: 100,
      cellRenderer: (cell: any) => {
        return capitalize(cell.rowData.status);
      },
      sortable: true,
    },
    {
      title: customColumnLabelsBasedOnMappingAndForm.creditsLabel ?? language.credits,
      key: 'credits',
      width: 100,
      cellRenderer: (cell: any) => {
        return capitalize(cell.rowData.credits);
      },
      sortable: true,
    },
    {
      title: customColumnLabelsBasedOnMappingAndForm.historicalEnrollmentLabel ?? language.history_enrollment_shorten,
      key: 'historicalEnrollment',
      width: 150,
      cellRenderer: (cell: any) => {
        return cell.rowData.historicalEnrollment;
      },
      sortable: true,
    },
    {
      title: customColumnLabelsBasedOnMappingAndForm.expectedEnrollmentLabel ?? language.expected_enrollment_shorten,
      key: 'expectedEnrollment',
      width: 150,
      cellRenderer: (cell: any) => {
        if (pathwayIsAcceptedOrRejected(cell.rowData.reviewStatus)) {
          return cell.rowData.expectedEnrollment;
        }
        return (
          <EditableNumberInput
            required
            min={1}
            value={cell.rowData.expectedEnrollment}
            onSubmitChange={(val?: number) => {
              onUpdateStudyCombinationInTable(cell.rowData, {
                expectedEnrollment: val,
              });
            }}
          />
        );
      },
      sortable: true,
    },
    {
      title: language.status,
      key: 'reviewStatus',
      width: 150,
      cellRenderer: (cell: any) => {
        return (
          <div>
            {cell.rowData.reviewStatusLabel}
            {!cell.rowData.disabled && (
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu
                    disabled={disableUpdateStatusWhenGeneratingStudentSet(cell.rowData as TPathway)}
                    onClick={onStatusChange(cell.rowData)}
                  >
                    {['KEEP', 'REJECTED'].includes(cell.rowData.reviewStatus) ? (
                      <Menu.Item key="not_review">{language.not_review as string}</Menu.Item>
                    ) : (
                      <>
                        <Menu.Item key="approve">{language.approve as string}</Menu.Item>
                        <Menu.Item key="reject">{language.reject as string}</Menu.Item>
                      </>
                    )}
                  </Menu>
                }
              >
                <DownOutlined />
              </Dropdown>
            )}
          </div>
        );
      },
      sortable: true,
    },
  ];

  const headerSummary = useMemo(() => {
    if (!form) return [];
    return (
      <>
        <span>
          {getInlineString(
            'showing_number_of_study_combinations',
            renderNumber(countPerAllPages, '-'),
            renderNumber(dataManagerObjectCount, '-'),
          )}
        </span>
        <PathwaysSummary />
      </>
    );
  }, [form, countPerAllPages, dataManagerObjectCount]);

  return (
    <div className="tabpane__wrapper">
      <PathwayFilters />
      <div className="te-flex te-flex-col te-flex-1 te-relative" style={{ paddingTop: 30 }}>
        <div style={{ position: 'absolute', top: 0 }}>{TableAllItemsSelection}</div>

        <TEBaseTable.Container>
          <TEBaseTable.Component
            currentPage={currentPage}
            tableId="TABLE_REVIEW_BY_STUDY_COMBINATION"
            columns={columns}
            data={rowData}
            columnsEditable
            selectable
            rowKey="id"
            height={rowData.length ? undefined : 250}
            draggable={false}
            footerLeftActions={
              <PathwaysFooterActions
                indexedRowData={indexedPathways}
                selectedRowKeys={selectedRowKeys || []}
                selectedForm={form}
                onOpenCreatePathwayModal={() => setModalVisible(true)}
                isSelectingAllInAllPage={isSelectingAllInAllPage}
                filters={filters}
              />
            }
            contextMenuItems={(rowId: string) => {
              const item = indexedPathways[rowId];
              if (!item) return [];
              if (item.disabled) return [];
              const isAcceptedOrRejected = pathwayIsAcceptedOrRejected(item.reviewStatus);
              const isDisabledByGenerating = disableUpdateStatusWhenGeneratingStudentSet(item as TPathway);
              return compact([
                {
                  label: language.not_review,
                  key: 'not_review',
                  disabled: !isAcceptedOrRejected || isDisabledByGenerating,
                  onSelect: (items: string[]) => {
                    dispatch(changePathwayStatus(items, 'NOT_REVIEWED'));
                  },
                },
                {
                  label: language.approve,
                  key: 'approve',
                  disabled: isAcceptedOrRejected || isDisabledByGenerating,
                  onSelect: (items: string[]) => {
                    dispatch(changePathwayStatus(items, 'KEEP'));
                  },
                },
                {
                  label: language.reject,
                  key: 'reject',
                  disabled: isAcceptedOrRejected || isDisabledByGenerating,
                  onSelect: (items: string[]) => {
                    dispatch(changePathwayStatus(items, 'REJECTED'));
                  },
                },
                {
                  label: language.edit,
                  key: 'edit',
                  disabled: isAcceptedOrRejected || isDisabledByGenerating,
                  onSelect: (items: string[]) => {
                    if (!isEmpty(items)) {
                      setModalVisible(true);
                      setSelectedPathwayToEdit(indexedPathways[items[0]]);
                    }
                  },
                },
                {
                  label: language.delete,
                  key: 'delete',
                  danger: true,
                  disabled: item.status !== 'NEW' || isDisabledByGenerating,
                  onSelect: (items: string[]) => {
                    dispatch(deletePathways(items));
                  },
                },
              ]);
            }}
            emptyRenderer={
              <div className="prefs-p-4 text--disabled">
                <Empty description={language.no_study_combinations_warning as string} />
              </div>
            }
            loading={loading}
            disabledRowKeys={allocatedPathways}
            selectedRowKeys={selectedRowKeys}
            onSelectedRowKeysChange={onSelectedRowKeyChange}
            headerSummary={headerSummary}
            onColumnSortChange={(order, key) => {
              const reviewByStudyCombinationSortMapping: Record<string, ReviewByStudyCombinationSortableField> = {
                expectedEnrollment: 'inputObject.inputContent.expectedEnrollment',
                status: 'inputObject.inputContent.status',
                reviewStatus: 'reviewStatus',
                coursesLabel: 'metadata.courses',
                credits: 'metadata.totalCredits',
                historicalEnrollment: 'inputObject.inputContent.historicalEnrollmentCopyForSortingAndFiltering',
              };
              dispatch(
                updateReviewByStudyCombinationsSorting({
                  reviewStudyCombinationsSortBy: reviewByStudyCombinationSortMapping[key.toString()],
                  reviewStudyCombinationsSortOrder: mongoReactSortOrderMapping[order],
                }),
              );
            }}
            deactivateComponentSortingForKeys={[
              'expectedEnrollment',
              'status',
              'reviewStatus',
              'coursesLabel',
              'credits',
              'historicalEnrollment',
            ]}
            enablePagination={(dataManagerObjectCount || 0) > rowData.length}
            onPageOrPageSizeChange={(current: number, pageSize: number) => {
              dispatch(
                reviewStudyCombinationsChangePagination({
                  reviewStudyCombinationsPage: current || 1, // Sometimes values come in as "0". But we cannot have that so, pass 1 instead
                  reviewStudyCombinationsPerPage: pageSize,
                }),
              );
            }}
            defaultPageSize={paginationDefaults.reviewStudyCombinationsPerPage}
            countPerAllPages={countPerAllPages}
            noOfSelectedRows={isSelectingAllInAllPage ? countPerAllPages : selectedRowKeys?.length}
          />
        </TEBaseTable.Container>
      </div>
      <PathwayCoursesModal
        open={!!coursesModalVisible}
        onClose={() => setCoursesModalVisible(undefined)}
        pathway={coursesModalVisible}
        form={form}
        courses={courses}
      />
      <ModalCreatingOrEditingPathway
        createOrEdit={selectedPathwayToEdit ? 'edit' : 'create'}
        open={modalVisible}
        pathwayToEdit={selectedPathwayToEdit}
        onVisibleChange={(visible) => {
          setModalVisible(visible);
          if (!visible) {
            setSelectedPathwayToEdit(undefined);
          }
        }}
        onSubmit={onCreateOrEditStudyCombination}
      />
    </div>
  );
}

export default ReviewByStudyConbination;
