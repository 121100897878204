import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input } from 'antd';
import React, { useMemo } from 'react';
import { useCreateNewImportTemplateModal } from './CreateNewImportTemplateModal.context';
import { useSelector } from 'react-redux';
import { reservationModesSelector } from 'slices/integration.slice';
import { keyBy } from 'lodash';
import { reservationFieldsForTemplateSelector } from 'activities/pages/slices/activity.slice';
import { TTemplateInformationField, TTemplateReservationField } from 'activities/pages/types/importTemplate.type';
import intl from 'i18n/intl';

const language = intl.messages;

export default function CreateNewImportTemplateModalFields() {
  const { settings, fields, setFields, informationFields, setInformationFields } = useCreateNewImportTemplateModal();
  const reservationModes = useSelector(reservationModesSelector());
  const reservationFields = useSelector(reservationFieldsForTemplateSelector(settings.reservationMode));

  const indexedReservationFields: Record<string, TTemplateReservationField> = useMemo(() => {
    return keyBy(reservationFields, 'extId');
  }, [reservationFields]);

  const reservationModeDisplay = useMemo(() => {
    return reservationModes.find((mode) => mode.extId === settings.reservationMode)?.name;
  }, [reservationModes, settings]);

  const onSelectField = (fields: string[]) => {
    setFields(fields.map((field: string) => indexedReservationFields[field]));
  };

  const onAddNewField = () => {
    setInformationFields([
      ...informationFields,
      {
        valueType: 'STRING',
        label: '',
        excludeInScheduling: true,
      },
    ]);
  };

  const onInformationFieldChange = (fieldIdx: number, dataToUpdate: Partial<TTemplateInformationField>) => {
    setInformationFields([
      ...informationFields.slice(0, fieldIdx),
      {
        ...informationFields[fieldIdx],
        ...dataToUpdate,
      },
      ...informationFields.slice(fieldIdx + 1),
    ]);
  };

  const onDeleteInformationField = (fieldIdx: number) => {
    setInformationFields([...informationFields.slice(0, fieldIdx), ...informationFields.slice(fieldIdx + 1)]);
  };

  return (
    <div>
      <div>
        <b>{language.reservation_mode as string}:&nbsp;</b>
        <span>{reservationModeDisplay}</span>
      </div>
      <div className="te-mt-2">
        <b>{language.select_reservation_fields as string}</b>
        <Checkbox.Group
          style={{ width: '100%' }}
          value={fields.map(({ extId }) => extId)}
          onChange={(fields) => onSelectField(fields as string[])}
        >
          <div
            data-testid="CREATE_NEW_IMPORT_TEMPLATE_MODAL_RESERVATION_FIELDS_SELECTION"
            className="create-new-import-template-modal__grid create-new-import-template-modal__grid--3"
          >
            {Object.values(indexedReservationFields)?.map((field: TTemplateReservationField) => (
              <div key={field.extId}>
                <Checkbox value={field.extId}>{field.label}</Checkbox>
              </div>
            ))}
          </div>
        </Checkbox.Group>
      </div>

      <div className="te-mt-2" data-testid="CREATE_NEW_IMPORT_TEMPLATE_MODAL_ADDITIONAL_FIELDS">
        <div>
          <b>{language.add_information_field as string}</b>
          {!!informationFields.length && (
            <div className="create-new-import-template-modal__information-fields">
              <b>{language.label as string}</b>
              <span />
            </div>
          )}
          <div className="create-new-import-template-modal__information-fields fields">
            {informationFields.map((field, fieldIdx) => (
              // eslint-disable-next-line react/no-array-index-key -- There is no id for those fields, use index as key
              <React.Fragment key={fieldIdx}>
                <Input
                  disabled={field.fixed}
                  value={field.label}
                  onChange={(e) => onInformationFieldChange(fieldIdx, { label: e.target.value?.trim() })}
                />
                {field.fixed ? (
                  <span />
                ) : (
                  <Button
                    onClick={() => onDeleteInformationField(fieldIdx)}
                    icon={<DeleteOutlined />}
                    size="small"
                    type="link"
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <Button className="te-mt-2" type="link" onClick={onAddNewField}>
          <PlusOutlined />
          {language.add_information_field as string}
        </Button>
      </div>
    </div>
  );
}
