import { ITEToken } from '@timeedit/types/lib/types/token.type';
import { TTEAuthToken } from '@timeedit/ui-components/lib/src/components/AuthWrapper/types';
import jwtDecode from 'jwt-decode';

export function getAccessTokenRaw() {
  const objectToken = getAccessTokenFromStorage();
  if (!objectToken) {
    return null;
  }
  return objectToken.accessToken;
}

export function getAccessTokenDecoded(): ITEToken | null {
  const authToken = getAccessTokenRaw();
  if (authToken) {
    return jwtDecode(authToken);
  }
  return null;
}

function getAccessTokenFromStorage(): TTEAuthToken | null {
  const token = localStorage.getItem('te_auth_token');
  if (!token) {
    return null;
  }
  try {
    return JSON.parse(token);
  } catch (e) {
    return null;
  }
}
