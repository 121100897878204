import React, { useMemo } from 'react';
import { Tag } from 'antd';
import { useSelector } from 'react-redux';
import Timer from './Timer';
import { MAPPING_COMBINATION_STUDY_STATUS_TO_TAG_TYPE } from '../PathwayServiceConstants';
import {
  StudentSetGenerationStatusString,
  studentSetGenerationStatusStringValues,
} from '@timeedit/types/lib/constants';
import {
  studentSetGenerationStatusStringCountsSelector,
  generatingStudentCombinationSetsStatusSelector,
  generateStudentSetsCombinationsCountPerAllPagesSelector,
  preparingGeneratingStudentCombinationSetsStatusSelector,
} from '../../slices/combinationStudents.slice';
import intl from '../../../i18n/intl';

interface IProps {
  selectedRowKeys?: string[];
}

export function StudentSetGenerationSummary({ selectedRowKeys }: IProps) {
  const studentSetGenerationStatusStringCounts = useSelector(studentSetGenerationStatusStringCountsSelector);
  const countPerAllPages = useSelector(generateStudentSetsCombinationsCountPerAllPagesSelector);

  const isGeneratingStudentSets = useSelector(generatingStudentCombinationSetsStatusSelector());
  const isPreparingGeneration = useSelector(preparingGeneratingStudentCombinationSetsStatusSelector());

  const language = intl.messages;

  const summary = useMemo(() => {
    if (!studentSetGenerationStatusStringCounts) {
      return null;
    }

    type TSummaryOutput = Record<
      StudentSetGenerationStatusString,
      { color: string; label: string; percentage: number; total: number }
    >;
    const summaryOutput: TSummaryOutput = studentSetGenerationStatusStringValues.reduce(
      (wholeObjectSoFar, currentKey) => ({
        ...wholeObjectSoFar,
        [currentKey]: {
          label: MAPPING_COMBINATION_STUDY_STATUS_TO_TAG_TYPE[currentKey].status,
          color: MAPPING_COMBINATION_STUDY_STATUS_TO_TAG_TYPE[currentKey].color,
          total: studentSetGenerationStatusStringCounts[currentKey] ?? 0,
          percentage: countPerAllPages
            ? ((studentSetGenerationStatusStringCounts[currentKey] ?? 0) / countPerAllPages) * 100
            : 0,
        },
      }),
      {} as TSummaryOutput,
    );
    return summaryOutput;
  }, [studentSetGenerationStatusStringCounts, countPerAllPages]);

  return (
    summary && (
      <div className="te-flex te-gap-x-6">
        <span>
          <Tag color={summary.COMPLETE.color}>{summary.COMPLETE.label}</Tag>
          {summary.COMPLETE.total} ({summary.COMPLETE.percentage.toFixed(0)}%)
        </span>
        <span>
          <Tag color={summary.ACTIVITY_DATA_ERROR.color}>{summary.ACTIVITY_DATA_ERROR.label}</Tag>
          {summary.ACTIVITY_DATA_ERROR.total} ({summary.ACTIVITY_DATA_ERROR.percentage.toFixed(0)}%)
        </span>
        <span>
          <Tag color={summary.STUDENT_SETS_GENERATED.color}>{summary.STUDENT_SETS_GENERATED.label}</Tag>
          {summary.STUDENT_SETS_GENERATED.total} ({summary.STUDENT_SETS_GENERATED.percentage.toFixed(0)}%)
        </span>
        <span>
          <Tag color={summary.STUDENT_SETS_ALLOCATED.color}>{summary.STUDENT_SETS_ALLOCATED.label}</Tag>
          {summary.STUDENT_SETS_ALLOCATED.total} ({summary.STUDENT_SETS_ALLOCATED.percentage.toFixed(0)}%)
        </span>
        {isGeneratingStudentSets && !isPreparingGeneration ? <Timer selectedRowKeys={selectedRowKeys} /> : null}
        {!isGeneratingStudentSets && isPreparingGeneration ? (
          <b>{language['generate_student_sets.preparing'] as string}</b>
        ) : null}
      </div>
    )
  );
}
