import { WarningOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { TEInactiveObjectIdsSelector } from '../../../slices/integration.slice';
import { pathwaySelectedFormSelector } from '../../slices/pathway.slice';
import intl from '../../../i18n/intl';

const language = intl.messages;

function InactiveCourseWarningIcon({ courseId }: { courseId: string }) {
  const form = useSelector(pathwaySelectedFormSelector);
  const inactiveCourseIds = useSelector(TEInactiveObjectIdsSelector(form?.pathwaysSettings.course.datasource));
  if (!courseId || !inactiveCourseIds.includes(courseId)) return null;
  return (
    <Tooltip title={language.inactive_course as string}>
      <WarningOutlined className="text--danger" />
    </Tooltip>
  );
}

export default InactiveCourseWarningIcon;
