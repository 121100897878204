import React from 'react';
import { Button, Modal, Space, Spin } from 'antd';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { StudentSetGenerationStatusString } from '@timeedit/types/lib/constants';
import {
  deleteStudentCombinationSets,
  generateStudentCombinationSets,
  updateGeneratingStudentSetsStatus,
  allocateStudentCombinationSets,
  allocateStudentSetLoadingSelector,
  deallocateStudentCombinationSets,
  deallocateStudentSetLoadingSelector,
  preparingGeneratingStudentCombinationSetsStatusSelector,
  combinationFiltersSelector,
  deleteStudentSetsLoadingSelector,
} from '../../slices/combinationStudents.slice';
import intl, { getInlineString } from '../../../i18n/intl';
import api from '../../../services/api.service';
import { configService } from 'services/config.service';
import { generateGetCombinationsQuery } from 'study-combinations/pages/useCombinationsWatchers';

interface IProps {
  disabled: boolean;
  selectedRowKeys: string[];
  onClearSelection: () => void;
  onShowAlertModal: any;
  missingDataRowIds?: number[];
  atLeastOneWithCertStatSel: Record<StudentSetGenerationStatusString, boolean>;
  formId?: string;
  isSelectingAllInAllPage: boolean;
}
function StudyCombinationFooterAction({
  disabled,
  selectedRowKeys,
  onClearSelection,
  onShowAlertModal,
  missingDataRowIds,
  atLeastOneWithCertStatSel,
  isSelectingAllInAllPage,
  formId,
}: IProps) {
  const dispatch = useDispatch();
  const [modal, modalContextHolder] = Modal.useModal();
  const language = intl.messages;
  const allocateStudentSetLoading = useSelector(allocateStudentSetLoadingSelector);
  const deallocateStudentSetLoading = useSelector(deallocateStudentSetLoadingSelector);
  const deleteStudentSetLoading = useSelector(deleteStudentSetsLoadingSelector);
  const isPreparingGeneration = useSelector(preparingGeneratingStudentCombinationSetsStatusSelector());
  const filters = useSelector(combinationFiltersSelector);

  const countSCsByStatus = async (action: 'generate' | 'delete' | 'allocate' | 'deallocate') => {
    const res = await api.post({
      endpoint: `${
        configService().REACT_APP_PATHWAY_SERVICE_URL
      }v1/data-manager-objects/student-set-generation/count/${action}`,
      successMessage: false,
      data: {
        ...generateGetCombinationsQuery(filters),
        pathwayConfigFromUserId: formId,
        id: selectedRowKeys,
      },
    });
    return res.data?.studentSetsTotal;
  };

  const onRequestToGenerateStudentSets = async () => {
    const count = await countSCsByStatus('generate');

    if (!count) {
      modal.error({
        title: language.no_student_sets_can_be_generated as string,
      });
      return;
    }

    modal.confirm({
      title: language.confirm_to_generate_student_sets_title as string,
      content: getInlineString('confirm_to_generate_student_sets_message', count.toString()),
      onOk: () => {
        if (!isEmpty(selectedRowKeys)) {
          if (count <= 0 && !isEmpty(missingDataRowIds)) {
            onShowAlertModal(missingDataRowIds);
          } else {
            dispatch(generateStudentCombinationSets(selectedRowKeys));
          }
        }
      },
    });
  };

  const onRequestToDeleteStudentSets = async () => {
    const count = await countSCsByStatus('delete');

    if (!count) {
      modal.error({
        title: language.no_student_sets_can_be_deleted as string,
      });
      return;
    }

    modal.confirm({
      title: language.confirm_to_delete_student_sets_title as string,
      content: getInlineString('confirm_to_delete_student_sets_message', count.toString()),
      onOk: () => {
        if (!isEmpty(selectedRowKeys)) {
          dispatch(deleteStudentCombinationSets(selectedRowKeys));
          dispatch(updateGeneratingStudentSetsStatus(false));
        }
      },
    });
  };

  const onRequestToAllocateStudentSets = async () => {
    const count = await countSCsByStatus('allocate');

    if (!count) {
      modal.error({
        title: language.no_student_sets_can_be_allocated as string,
      });
      return;
    }

    modal.confirm({
      title: language.confirm_to_allocate_student_sets_title as string,
      content: (
        <Space direction="vertical">
          {getInlineString('confirm_to_allocate_student_sets_message', count.toString())}
          {getInlineString('confirm_to_allocate_student_sets_note', count.toString())}
        </Space>
      ),
      onOk: () => {
        if (!isEmpty(selectedRowKeys)) {
          dispatch(allocateStudentCombinationSets(selectedRowKeys, modal));
        }
      },
    });
  };

  const onRequestToDeallocateStudentSets = async () => {
    const count = await countSCsByStatus('deallocate');

    if (!count) {
      modal.error({
        title: language.no_student_sets_can_be_deallocated as string,
      });
      return;
    }

    modal.confirm({
      title: language.confirm_to_deallocate_student_sets_title as string,
      content: getInlineString('confirm_to_deallocate_student_sets_message', count.toString()),
      okText: language.deallocate_student_sets as string,
      onOk: () => {
        if (!isEmpty(selectedRowKeys)) {
          dispatch(deallocateStudentCombinationSets(selectedRowKeys, modal));
        }
      },
    });
  };

  return (
    <>
      <Button
        size="small"
        disabled={
          disabled ||
          (!isSelectingAllInAllPage &&
            !(atLeastOneWithCertStatSel.COMPLETE || atLeastOneWithCertStatSel.GENERATION_ERROR))
        }
        onClick={onRequestToGenerateStudentSets}
        type="primary"
      >
        {isPreparingGeneration && <Spin />}
        {language['generate_student_sets.title'] as string}
      </Button>
      <Button
        size="small"
        loading={deleteStudentSetLoading}
        disabled={
          !isSelectingAllInAllPage &&
          !(
            atLeastOneWithCertStatSel.STUDENT_SETS_GENERATED ||
            atLeastOneWithCertStatSel.GENERATION_ERROR ||
            atLeastOneWithCertStatSel.ALLOCATION_ERROR
          )
        }
        onClick={onRequestToDeleteStudentSets}
      >
        {language.delete_all_student_sets as string}
      </Button>
      <Button
        size="small"
        loading={allocateStudentSetLoading}
        disabled={
          !isSelectingAllInAllPage &&
          !(atLeastOneWithCertStatSel.STUDENT_SETS_GENERATED || atLeastOneWithCertStatSel.ALLOCATION_ERROR)
        }
        onClick={onRequestToAllocateStudentSets}
      >
        {language.allocate_student_sets as string}
      </Button>
      <Button
        size="small"
        loading={deallocateStudentSetLoading}
        disabled={!atLeastOneWithCertStatSel.STUDENT_SETS_ALLOCATED && !atLeastOneWithCertStatSel.DEALLOCATION_ERROR}
        onClick={onRequestToDeallocateStudentSets}
      >
        {language.deallocate_student_sets as string}
      </Button>
      {modalContextHolder}
    </>
  );
}

export default StudyCombinationFooterAction;
