/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// ACTIONS
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from '../utils/sliceHelpers';

// TYPES
import { IObjectRelationshipState } from '../types/state.interface';
import { TAppDispatch } from '..';

import { groupBy } from 'lodash';
import { EAppId } from '@timeedit/types/lib/enums';

const initialState: IObjectRelationshipState = {
  loading: false,
  hasErrors: false,
  appId: EAppId.TE_EXAM,
  relationships: [],
  mappedRelationship: {},
  objectMappings: {
    appId: EAppId.TE_EXAM,
    objectTypes: [],
  },
};

// Slice
const slice = createSlice({
  name: 'objectRelationships',
  initialState,
  reducers: {
    fetchObjectRelationshipsRequest: (state) => {
      isLoadingRequest(state);
    },
    fetchObjectRelationshipsSuccess: (state, { payload }) => {
      state.relationships = payload.relationships;
      const relationsMapping: Record<string, string[]> = {};
      payload.relationships.forEach(({ from, to }: any) => {
        if (!relationsMapping[from]) {
          relationsMapping[from] = [to];
        } else {
          relationsMapping[from].push(to);
        }
        if (!relationsMapping[to]) {
          relationsMapping[to] = [from];
        } else {
          relationsMapping[to].push(from);
        }
      });
      state.mappedRelationship = relationsMapping;
      finishedLoadingSuccess(state);
    },
    fetchObjectRelationshipsFailure: (state) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Actions
const { fetchObjectRelationshipsRequest, fetchObjectRelationshipsSuccess, fetchObjectRelationshipsFailure } =
  slice.actions;

export const fetchObjectRelationships = (appId: string) => async (dispatch: TAppDispatch) => {
  try {
    dispatch(fetchObjectRelationshipsRequest());
    const result = await api.get({
      endpoint: `/apps/${appId}/object-relationships`,
    });
    dispatch(fetchObjectRelationshipsSuccess(result));
  } catch (e) {
    dispatch(fetchObjectRelationshipsFailure());
    console.error(e);
  }
};
