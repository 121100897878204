import React, { useMemo } from 'react';
import { Tag } from 'antd';
import { useSelector } from 'react-redux';
import { pathwayCountPerAllPagesSelector, pathwayReviewStatusCountSelector } from '../../slices/pathway.slice';
import { PATHWAY_STATUSES_MAPPING } from '../PathwayService.constants';

import {
  DataManagerObjectReviewStatus,
  dataManagerObjectReviewStatusses as dataManagerObjectReviewStatuses,
} from '@timeedit/types/lib/constants';

function PathwaysSummary() {
  const pathwayReviewStatusCounts = useSelector(pathwayReviewStatusCountSelector());

  const pathwayCountPerAllPages = useSelector(pathwayCountPerAllPagesSelector());

  const summary = useMemo(() => {
    if (!pathwayReviewStatusCounts) {
      return null;
    }

    type TSummaryOutput = Record<
      DataManagerObjectReviewStatus,
      { color: string; label: string; percentage: number; total: number }
    >;

    return dataManagerObjectReviewStatuses.reduce((results, stt) => {
      return {
        ...results,
        [stt]: {
          label: PATHWAY_STATUSES_MAPPING[stt]?.label,
          total: pathwayReviewStatusCounts[stt] ?? 0,
          percentage: pathwayCountPerAllPages
            ? Number(((pathwayReviewStatusCounts[stt] ?? 0) / pathwayCountPerAllPages) * 100)
            : 0,
        },
      };
    }, {} as TSummaryOutput);
  }, [pathwayReviewStatusCounts, pathwayCountPerAllPages]);

  return (
    summary && (
      <div className="te-flex te-gap-x-6">
        <span>
          <Tag color="success">{summary.KEEP.label}</Tag>
          {summary.KEEP.total} ({summary.KEEP.percentage.toFixed(0)}%)
        </span>
        <span>
          <Tag color="error">{summary.REJECTED.label}</Tag>
          {summary.REJECTED.total} ({summary.REJECTED.percentage.toFixed(0)}%)
        </span>
        <span>
          <Tag color="default">{summary.NOT_REVIEWED.label}</Tag>
          {summary.NOT_REVIEWED.total} ({summary.NOT_REVIEWED.percentage.toFixed(0)}%)
        </span>
      </div>
    )
  );
}

export default PathwaysSummary;
