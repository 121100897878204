import React, { createContext } from 'react';

export type TTestIds = {
  GenerateStudentSet?: {
    studyCombinationRowCells: {
      generationStatus: string;
    };
  };
};

export const TestIdContext = createContext<TTestIds>({});

export function TestIdProvider({ children, testIds }: { children: React.ReactNode; testIds: TTestIds }) {
  return <TestIdContext.Provider value={testIds}>{children}</TestIdContext.Provider>;
}
