import React, { useMemo } from 'react';
import { Steps } from 'antd';
import { TABS } from './ImportActivitiesModal.constants';
import { useImportActivitiesModal } from './ImportActivitiesModal.context';

export default function ImportActivitiesModalNav() {
  const { tab } = useImportActivitiesModal();
  const activeStep = useMemo(() => {
    return TABS.findIndex(({ key }) => key === tab);
  }, [tab]);
  return (
    <Steps
      data-testid="IMPORT_ACTIVITIES_MODAL_NAV"
      style={{ margin: '20px 0' }}
      size="small"
      current={activeStep}
      items={TABS}
    />
  );
}
