import { createContext, useContext } from 'react';
import { TABS } from './CreateNewImportTemplateModal.constants';
import { capitalize } from 'lodash';
import {
  TTemplateInformationField,
  TTemplateReservationField,
  TTemplateValueType,
} from 'activities/pages/types/importTemplate.type';
import { TType } from '@timeedit/registration-shared';

const ValueTypes: TTemplateValueType[] = ['STRING', 'NUMBER', 'BOOLEAN'];
const ValueTypeOptions = ValueTypes.map((type) => ({
  label: capitalize(type),
  value: type,
}));

export type TTemplateSettings = {
  reservationMode: string;
  primaryObjectType: string;
  activityObjectType: string;
  name: string;
  allowAdditionalObjectsOfPrimaryObjectType: boolean;
  allowAdditionalObjectsOfActivityTypeType: boolean;
  enableTracks: boolean;
};

export type TSelectedStaticObjects = {
  primaryObject?: TSelectedObjectValue;
  activityType?: TSelectedObjectValue;
  additionalObjectOfPrimaryObjectType?: TSelectedObjectValue;
  additionalObjectOfActivityTypeType?: TSelectedObjectValue;
};

export type TSelectedObjects = Record<string, TSelectedObjectValue>;

export type TSelectedObjectValue = {
  selected: boolean;
  active: boolean;
  fields: string[];
  label: string;
  extId: string;
};
export interface ICreateNewImportTemplateModalContext {
  allObjectTypes: Record<string, TType>;
  creating: boolean;
  settings: TTemplateSettings;
  setSettings: (settings: TTemplateSettings) => void;

  staticObjects: TSelectedStaticObjects;
  setStaticObjects: (objects: TSelectedStaticObjects) => void;

  objects: TSelectedObjects;
  setObjects: (objects: TSelectedObjects) => void;

  fields: TTemplateReservationField[];
  setFields: (fields: TTemplateReservationField[]) => void;

  informationFields: TTemplateInformationField[];
  setInformationFields: (fields: TTemplateInformationField[]) => void;

  tab: (typeof TABS)[number]['key'];
  setTab: (tab: (typeof TABS)[number]['key']) => void;
  onClose: () => void;
  activeTabIndex: number;
  onNext: () => void;
  onPrev: () => void;
  onFinish: () => void;
  getSummary: () => {
    reservationMode?: string;
    primaryObject?: string;
    activityType?: string;
  };
}

export const initialValue: ICreateNewImportTemplateModalContext = {
  allObjectTypes: {},
  creating: false,
  settings: {
    reservationMode: '',
    primaryObjectType: '',
    activityObjectType: '',
    name: '',
    enableTracks: false,
    allowAdditionalObjectsOfPrimaryObjectType: false,
    allowAdditionalObjectsOfActivityTypeType: false,
  },
  informationFields: [],
  setInformationFields: () => {},

  setSettings: () => {},

  staticObjects: {},
  setStaticObjects: () => {},

  objects: {},
  setObjects: () => {},

  fields: [],
  setFields: () => {},

  tab: TABS[0].key,
  setTab: () => {},
  onClose: () => {},
  activeTabIndex: 0,
  onNext: () => {},
  onPrev: () => {},
  onFinish: () => {},
  getSummary: () => ({
    reservationMode: '',
    primaryObject: '',
    activityType: '',
  }),
};

const CreateNewImportTemplateModalContext = createContext<ICreateNewImportTemplateModalContext>(initialValue);
export const useCreateNewImportTemplateModal = () => useContext(CreateNewImportTemplateModalContext);
export default CreateNewImportTemplateModalContext;
