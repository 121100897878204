import React, { ReactNode } from 'react';
import { Skeleton, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { TEObjectLoadingSelector } from '../../../slices/integration.slice';

interface Props {
  children?: ReactNode | string;
  datasource?: string;
}
function TEObjectLabel({ children, datasource }: Props) {
  const loading = useSelector(TEObjectLoadingSelector());

  return (
    <Skeleton paragraph={false} title active loading={loading[datasource || '']}>
      {typeof children === 'string' ? <Typography.Text ellipsis>{children || ''}</Typography.Text> : children}
    </Skeleton>
  );
}

export default TEObjectLabel;
