/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react';
import { Modal, ModalProps, InputNumber, Tooltip } from 'antd';
import { CardPathwayDetails } from '@timeedit/ui-components';
import { pathwayIsFinished } from 'study-combinations/pages/PathwayService.utils';
import { TPathway } from '../../PathwayService.type';
import intl from 'i18n/intl';
import { validateNumber } from 'study-combinations/utils/validations';
import { capitalize, isEmpty } from 'lodash';
import { TEditingExpectedEnrollment } from 'study-combinations/pages/Views/Tabs/ReviewByCourse';
import { WarningOutlined } from '@ant-design/icons';

interface Props extends Omit<ModalProps, 'onOk' | 'visible'> {
  pathways?: TPathway[];
  fieldSettings: any;
  onSelectPathway: (pathway: Partial<TPathway>) => void;
  onOk: (editingExpectedEnrollment: TEditingExpectedEnrollment) => void;
}

const language = intl.messages;

function ModalEditExpectedEnrollmentOfCourse(props: Props) {
  const { open, pathways, fieldSettings, onCancel, onOk, onSelectPathway, ...rest } = props;

  const [editingExpectedEnrollment, setEditingExpectedEnrollment] = useState<TEditingExpectedEnrollment>({});

  const editingValuesAreValid = useMemo(() => {
    if (!pathways) return true;
    if (isEmpty(editingExpectedEnrollment)) return true;
    return Object.values(editingExpectedEnrollment).every((val) => validateNumber(val, { min: 0 }));
  }, [editingExpectedEnrollment, pathways]);

  useEffect(() => {
    if (!pathways) {
      setEditingExpectedEnrollment({});
    } else {
      const editingEnrollmentValue = pathways
        .filter((pathway) => !pathwayIsFinished(pathway.studentSetGenerationStatusString))
        .reduce((results: typeof editingExpectedEnrollment, pathway) => {
          return {
            ...results,
            [pathway._id]: pathway.expectedEnrollment,
          };
        }, {});
      setEditingExpectedEnrollment(editingEnrollmentValue);
    }
  }, [pathways]);

  return (
    <Modal
      {...rest}
      open={open}
      onCancel={onCancel}
      onOk={() => onOk(editingExpectedEnrollment)}
      title={language.edit_expected_enrolment_in_study_combinations as string}
      okButtonProps={{
        ...(rest.okButtonProps || {}),
        disabled: !editingValuesAreValid,
      }}
      okText={capitalize(language.save as string)}
    >
      <div className="te-flex te-flex-col te-gap-y-3">
        {pathways?.map((pathway: any) => {
          return (
            <CardPathwayDetails
              pathway={pathway}
              key={pathway._id}
              fieldSettings={fieldSettings}
              onSelect={(pathway) => {
                onSelectPathway(pathway);
              }}
              additionalPathwayFieldsRenderer={(pathway: Partial<TPathway>) => {
                const isDisabled = pathwayIsFinished(pathway.studentSetGenerationStatusString);
                return (
                  <div className="te-relative">
                    <InputNumber
                      disabled={isDisabled}
                      status={
                        validateNumber(editingExpectedEnrollment[pathway._id as string], {
                          min: 0,
                        })
                          ? ''
                          : 'error'
                      }
                      min={1}
                      required
                      size="small"
                      value={editingExpectedEnrollment[pathway._id as string] || pathway.expectedEnrollment}
                      defaultValue={pathway.expectedEnrollment}
                      onChange={(val) => {
                        setEditingExpectedEnrollment({
                          ...editingExpectedEnrollment,
                          [pathway._id as string]: Number(val || 0),
                        });
                      }}
                      style={{
                        minWidth: 80,
                      }}
                    />
                    {isDisabled && (
                      <Tooltip title={language.study_combination_is_allocated as string}>
                        <WarningOutlined
                          style={{ position: 'absolute', top: 4, right: 6 }}
                          className="te-absolute text--danger"
                        />
                      </Tooltip>
                    )}
                  </div>
                );
              }}
            />
          );
        })}
      </div>
    </Modal>
  );
}

export default ModalEditExpectedEnrollmentOfCourse;
