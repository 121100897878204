import React from 'react';
import { DatePicker, Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { activityFilterPeriodSelector, changeFilterPeriodValue } from 'activities/pages/slices/activity.slice';
import intl from 'i18n/intl';

const language = intl.messages;

function ActivityDateRangeFilter() {
  const filterPeriod = useSelector(activityFilterPeriodSelector);
  const dispatch = useDispatch();

  const onChange = (values: any) => {
    dispatch(changeFilterPeriodValue(values.map((date: Dayjs) => date.toJSON())));
  };
  return (
    <Form.Item label={language.period as string} style={{ marginBottom: 0 }}>
      <DatePicker.RangePicker
        value={filterPeriod ? [dayjs(filterPeriod[0]), dayjs(filterPeriod[1])] : undefined}
        onChange={onChange}
        size="small"
        allowClear={false}
        allowEmpty={false}
        disabledDate={(currentDate, { from }) => {
          if (!from) return false;
          return currentDate.diff(from, 'year') > 2;
        }}
      />
    </Form.Item>
  );
}
export default ActivityDateRangeFilter;
