import { WarningOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import intl from 'i18n/intl';
import React from 'react';
import { TPathwayRow } from 'study-combinations/pages/PathwayService.type';

const language = intl.messages;

interface Props {
  courses?: TPathwayRow['courses'];
  inactiveCourses?: string[];
}
export default function CoursesLabel({ courses, inactiveCourses }: Props) {
  if (!courses) return null;
  return (
    <div className="te-flex te-items-center">
      {courses.map((course, courseIndex) => (
        <div key={course.te_extid} className="te-flex te-items-center">
          {course.label}
          {inactiveCourses?.includes(course.te_extid) && (
            <Tooltip title={language.inactive_course as string}>
              <WarningOutlined className="text--danger" style={{ fontSize: 10, marginLeft: 2 }} />
            </Tooltip>
          )}
          {courseIndex < courses.length - 1 && <>,&nbsp;&nbsp;</>}
        </div>
      ))}
    </div>
  );
}
