import React, { useEffect, useMemo, useState } from 'react';

import { Checkbox, Form, Modal, ModalProps, Select, Tooltip } from 'antd';
import { DatasourceSelector } from '@timeedit/ui-components';
import { useSelector } from 'react-redux';
import { TEObjectMappingSelector } from '../../../slices/integration.slice';
import { isEmpty, keyBy } from 'lodash';
import intl, { getInlineString } from '../../../i18n/intl';
import { pathwayIsAcceptedOrRejected } from '../PathwayService.utils';
import { ArrowRightOutlined, WarningOutlined } from '@ant-design/icons';

export type TSubmitReplaceModalPayload = {
  action: 'replace' | 'remove';
  data: {
    mandatory?: string[];
    elective?: string[];
    selectedCourse?: string;
  };
};
const language = intl.messages;

interface Props extends ModalProps {
  course: any;
  courses: any[];
  onSubmit: ({ action, data }: TSubmitReplaceModalPayload) => void;
  courseLabelField: string;
  datasource?: string;
  courseDatasourceField: string;
}

function ModalReplaceCourse(props: Props) {
  const { onCancel, onSubmit, course, courses = [], courseLabelField, datasource = '', courseDatasourceField } = props;
  const mapping = useSelector(TEObjectMappingSelector());
  const [selectedCourse, setSelectedCourse] = useState<string>('');
  const [form] = Form.useForm();
  const [action, setAction] = useState<string>('');

  const doSubmit = async () => {
    const values = await form.getFieldsValue();
    const { action, mandatory, elective } = values;
    onSubmit({
      action,
      data: {
        selectedCourse,
        mandatory,
        elective,
      },
    });
  };

  const onValuesChange = (changedValues: Record<string, string>, a: any) => {
    if (Object.keys(changedValues).includes('action')) {
      setAction(changedValues.action);
    }
  };

  const indexedCourses = useMemo(() => {
    return keyBy(courses, 'te_extid');
  }, [courses]);

  const pathwaysByCourseType = useMemo(() => {
    const mandatory: string[] = [];
    const elective: string[] = [];
    course?.pathways.forEach((pathway: any) => {
      if (pathway.mandatoryCourseIds.includes(course.te_extid)) mandatory.push(pathway);
      if (pathway.electiveCourseIds.includes(course.te_extid)) elective.push(pathway);
    });
    return {
      mandatory,
      elective,
    };
  }, [course]);

  useEffect(() => {
    if (!course) {
      setSelectedCourse('');
      form.resetFields();
      setAction('');
    }
  }, [course]);

  useEffect(() => {
    form.setFieldValue('selectedCourse', selectedCourse);
  }, [form, selectedCourse]);

  const renderPathwaysSelection = (pathways: any) => {
    return (
      <Checkbox.Group>
        {pathways.map((pathway: any) => {
          const courseIsIncluded = [...pathway.mandatoryCourseIds, ...pathway.electiveCourseIds].includes(
            selectedCourse,
          );
          const isDisabled = pathwayIsAcceptedOrRejected(pathway.reviewStatus);
          return (
            <div key={pathway._id}>
              <Checkbox value={pathway._id} disabled={courseIsIncluded || isDisabled}>
                <span className="te-flex te-items-center te-gap-x-2">
                  {pathway.name}
                  {(courseIsIncluded || isDisabled) && (
                    <Tooltip
                      title={
                        isDisabled
                          ? (language.study_combination_is_accepted_or_rejected as string)
                          : getInlineString('course_is_included_in_this_study_combination', course?.name)
                      }
                    >
                      <WarningOutlined className="text--danger" />
                    </Tooltip>
                  )}
                </span>
              </Checkbox>
            </div>
          );
        })}
      </Checkbox.Group>
    );
  };

  return (
    <Modal title={course?.name} open={!!course} onCancel={onCancel} okText="OK" onOk={() => form.submit()}>
      <div className="te-flex te-flex-col te-gap-y-2">
        <Form requiredMark={false} onFinish={doSubmit} form={form} layout="vertical" onValuesChange={onValuesChange}>
          <Form.Item
            label={language.select_action as string}
            name="action"
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: language.action_is_required as string,
              },
            ]}
          >
            <Select
              placeholder={language.select_action as string}
              options={[
                {
                  value: 'replace',
                  label: language.replace_course_in_study_combination as string,
                },
                {
                  value: 'remove',
                  label: language.remove_course_from_study_combination as string,
                },
              ]}
            />
          </Form.Item>
          {action === 'replace' ? (
            <div className="te-flex te-pt-2 te-w-full">
              <ArrowRightOutlined className="te-mr-2" style={{ paddingTop: 8 }} />
              <Form.Item
                name="selectedCourse"
                rules={[{ required: true, message: language.required as string }]}
                style={{ flex: 1 }}
              >
                <div className="te-flex te-items-center">
                  <DatasourceSelector
                    objects={courses.filter((c) => c.te_extid !== course?.te_extid)}
                    fields={mapping.objectTypes?.[courseDatasourceField]?.fields || []}
                    datasource={datasource}
                    onChange={(selectedObjectIds) => {
                      setSelectedCourse(selectedObjectIds?.[0]);
                    }}
                    values={[selectedCourse]}
                    valueRenderer={(val) => {
                      const course = indexedCourses[val];
                      if (!course) return val;
                      return course[courseLabelField];
                    }}
                    selectStyle={{ width: '100%' }}
                  />
                </div>
              </Form.Item>
            </div>
          ) : (
            <Form.Item style={{ height: 0 }} />
          )}
          <b style={{ fontSize: 16 }}>Select study combination(s):</b>
          {!!pathwaysByCourseType.mandatory.length && (
            <div className="te-pt-2">
              <Form.Item
                style={{ marginBottom: 0 }}
                name="mandatory"
                label={`${getInlineString('study_combinations_where_is_mandatory', course?.name)}:`}
              >
                {renderPathwaysSelection(pathwaysByCourseType.mandatory)}
              </Form.Item>
            </div>
          )}
          {!!pathwaysByCourseType.elective.length && (
            <div className="te-pt-2">
              <Form.Item
                style={{ marginBottom: 0 }}
                name="elective"
                label={`${getInlineString('study_combinations_where_is_elective', course?.name)}:`}
              >
                {renderPathwaysSelection(pathwaysByCourseType.elective)}
              </Form.Item>
            </div>
          )}
          <Form.Item
            style={{ height: 0, marginBottom: 0 }}
            className="form-item__placeholder"
            name="courses"
            rules={[
              {
                validator: () => {
                  if (isEmpty(form.getFieldValue('mandatory')) && isEmpty(form.getFieldValue('elective'))) {
                    return Promise.reject(new Error(language.please_select_study_combination as string));
                  }
                  return Promise.resolve();
                },
                message: language.required as string,
              },
            ]}
          />
        </Form>
      </div>
    </Modal>
  );
}

export default ModalReplaceCourse;
