import React from 'react';
import { Steps } from 'antd';
import { TABS } from './CreateNewImportTemplateModal.constants';
import { useCreateNewImportTemplateModal } from './CreateNewImportTemplateModal.context';

export default function CreateNewImportTemplateModalNav() {
  const { activeTabIndex } = useCreateNewImportTemplateModal();
  return (
    <Steps
      data-testid="CREATE_NEW_IMPORT_TEMPLATE_MODAL_NAV"
      style={{ margin: '20px 0' }}
      size="small"
      current={activeTabIndex}
      items={TABS}
    />
  );
}
