import React, { useMemo } from 'react';
import { Steps } from 'antd';
import { TABS } from './ImportReservationsModal.constants';
import { useImportReservationsModal } from './ImportReservationsModal.context';

export default function ImportActivitiesModalNav() {
  const { tab } = useImportReservationsModal();
  const activeStep = useMemo(() => {
    return TABS.findIndex(({ key }) => key === tab);
  }, [tab]);
  return (
    <Steps
      data-testid="IMPORT_RESERVATIONS_MODAL_NAV"
      style={{ margin: '20px 0' }}
      size="small"
      current={activeStep}
      items={TABS}
    />
  );
}
