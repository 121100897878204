export const getLdClientSideId = (env: string | undefined) => {
  switch (env) {
    case 'development':
    case 'beta':
      return '65e829cc7386bf105e2e28d5';
    case 'staging':
      return '65e829836880eb10b9f4edca';
    // Production
    default:
      return '65a938f0183d141061d386f9';
  }
};
