import { regionPlaceHolderInUrl } from '@timeedit/preferences-and-dm-commons/lib/src';

const isOldAdmin = !!process.env.REACT_APP_TE_0LD_ADMIN;

const genUrls = {
  pathway: 'pathway',
  restApi: 'api',
  activityImporter: 'activityimporter',
  am: 'activitymanager',
  integration: 'integration',
  registration: 'registration',
  preferences: 'preferences',
  dataManager: 'data-manager',
};

export const configsCloudRun2024 = {
  teCtl: {
    REACT_APP_URL: `http://localhost:3025/`,
    REACT_APP_BASE_URL: 'http://localhost:3010/v1',
    REACT_APP_AUTH_DOMAIN: 'http://localhost:3000',
    REACT_APP_INTEGRATION_SERVICE_URL: 'http://localhost:3006/',
    REACT_APP_PATHWAY_SERVICE_URL: 'http://localhost:3026/',
    REACT_APP_PATHWAY_SERVICE_WS_URL: 'ws://localhost:3026/',
    REACT_APP_ACTIVITY_MANAGER_SERVICE_URL: 'http://localhost:3021/',
    REACT_APP_NODE_CSV_URL: 'http://localhost:3027/',
    REACT_APP_REST_API_URL: 'http://localhost:3010/',
    REACT_APP_REGISTRATION_URL: 'http://localhost:4567', // TODO: This is not correct
    REACT_APP_PREFERENCES_URL: 'http://localhost:3024/',
    REACT_APP_UNIVERSAL_COOKIE_PATHNAME: 'timeedit.dev',
    CLIENT_ID: 'teAdmin',
    APP_NAME: 'teAdmin',
    VIEWS_URL: `https://views.timeedit.io/v1`, // TODO: Should be on localhost
    NOTIFICATION_KEY: 'TE_ADMIN_NOTIFICATION',
  },
  developmentFilip: {
    REACT_APP_URL: `http://localhost:3060/`,
    REACT_APP_BASE_URL: 'http://localhost:3010/v1',
    REACT_APP_AUTH_DOMAIN: 'http://localhost:3000',
    REACT_APP_PATHWAY_SERVICE_URL: 'http://localhost:3030/',
    REACT_APP_PATHWAY_SERVICE_WS_URL: 'ws://localhost:3030/',
    REACT_APP_INTEGRATION_SERVICE_URL: `https://${genUrls.integration}.${regionPlaceHolderInUrl}.timeedit.dev/`,
    REACT_APP_ACTIVITY_MANAGER_SERVICE_URL: `https://new-beta-activity-manager.timeedit.io/`,
    REACT_APP_NODE_CSV_URL: `https://${genUrls.activityImporter}.${regionPlaceHolderInUrl}.timeedit.dev/`,
    REACT_APP_REST_API_URL: `https://${genUrls.restApi}.${regionPlaceHolderInUrl}.timeedit.dev/`,
    REACT_APP_REGISTRATION_URL: `http://localhost:8085/api`,
    REACT_APP_PREFERENCES_URL: 'http://localhost:3012',
    REACT_APP_UNIVERSAL_COOKIE_PATHNAME: 'timeedit.dev',
    CLIENT_ID: 'teAdmin',
    APP_NAME: 'teAdmin',
    NOTIFICATION_KEY: 'TE_ADMIN_NOTIFICATION',
  },
  development: {
    REACT_APP_URL: `http://localhost:3060/`,
    REACT_APP_BASE_URL: 'http://localhost:3010/v1',
    REACT_APP_AUTH_DOMAIN: 'http://localhost:3000',
    REACT_APP_PATHWAY_SERVICE_URL: 'http://localhost:3030/',
    REACT_APP_PATHWAY_SERVICE_WS_URL: 'ws://localhost:3030/',
    REACT_APP_INTEGRATION_SERVICE_URL: `https://${genUrls.integration}.${regionPlaceHolderInUrl}.timeedit.dev/`,
    REACT_APP_ACTIVITY_MANAGER_SERVICE_URL: `https://${genUrls.am}.${regionPlaceHolderInUrl}.timeedit.dev/`,
    REACT_APP_NODE_CSV_URL: `https://${genUrls.activityImporter}.${regionPlaceHolderInUrl}.timeedit.dev/`,
    REACT_APP_REGISTRATION_URL: `http://localhost:8085/api`,
    REACT_APP_PREFERENCES_URL: 'http://localhost:3012',
    REACT_APP_REST_API_URL: 'http://localhost:4200/',
    REACT_APP_UNIVERSAL_COOKIE_PATHNAME: '/',
    CLIENT_ID: 'teAdmin',
    APP_NAME: 'teAdmin',
    NOTIFICATION_KEY: 'TE_ADMIN_NOTIFICATION',
  },
  beta: {
    REACT_APP_URL: `https://${genUrls.dataManager}.timeedit.dev/`,
    REACT_APP_BASE_URL: isOldAdmin
      ? 'https://old-admin-beta.timeedit.io/v1'
      : `https://${genUrls.restApi}.${regionPlaceHolderInUrl}.timeedit.dev/v1`,
    REACT_APP_AUTH_DOMAIN: `https://auth.timeedit.dev`,
    REACT_APP_PATHWAY_SERVICE_URL: `https://${genUrls.pathway}.${regionPlaceHolderInUrl}.timeedit.dev/`,
    REACT_APP_PATHWAY_SERVICE_WS_URL: `wss://${genUrls.pathway}.${regionPlaceHolderInUrl}.timeedit.dev/`,
    REACT_APP_INTEGRATION_SERVICE_URL: `https://${genUrls.integration}.${regionPlaceHolderInUrl}.timeedit.dev/`,
    REACT_APP_ACTIVITY_MANAGER_SERVICE_URL: `https://${genUrls.am}.${regionPlaceHolderInUrl}.timeedit.dev/`,
    REACT_APP_NODE_CSV_URL: `https://${genUrls.activityImporter}.${regionPlaceHolderInUrl}.timeedit.dev/`,
    REACT_APP_REST_API_URL: `https://${genUrls.restApi}.${regionPlaceHolderInUrl}.timeedit.dev/`,
    REACT_APP_REGISTRATION_URL: `https://${genUrls.registration}.${regionPlaceHolderInUrl}.timeedit.dev/api`,
    REACT_APP_PREFERENCES_URL: `https://${genUrls.preferences}.${regionPlaceHolderInUrl}.timeedit.dev/`,
    REACT_APP_UNIVERSAL_COOKIE_PATHNAME: 'timeedit.dev',
    CLIENT_ID: 'teAdmin',
    APP_NAME: 'teAdmin',
    NOTIFICATION_KEY: 'TE_ADMIN_NOTIFICATION',
  },
  staging: {
    REACT_APP_URL: `https://${genUrls.dataManager}.timeedit.io/`,
    REACT_APP_BASE_URL: isOldAdmin
      ? `https://old-admin.timeedit.io/v1`
      : `https://${genUrls.restApi}.${regionPlaceHolderInUrl}.timeedit.io/v1`,
    REACT_APP_AUTH_DOMAIN: `https://auth.timeedit.io`,
    REACT_APP_PATHWAY_SERVICE_URL: `https://${genUrls.pathway}.${regionPlaceHolderInUrl}.timeedit.io/`,
    REACT_APP_PATHWAY_SERVICE_WS_URL: `wss://${genUrls.pathway}.${regionPlaceHolderInUrl}.timeedit.io/`,
    REACT_APP_INTEGRATION_SERVICE_URL: `https://${genUrls.integration}.${regionPlaceHolderInUrl}.timeedit.io/`,
    REACT_APP_ACTIVITY_MANAGER_SERVICE_URL: `https://${genUrls.am}.${regionPlaceHolderInUrl}.timeedit.io/`,
    REACT_APP_NODE_CSV_URL: `https://${genUrls.activityImporter}.${regionPlaceHolderInUrl}.timeedit.io/`,
    REACT_APP_REST_API_URL: `https://${genUrls.restApi}.${regionPlaceHolderInUrl}.timeedit.io/`,
    REACT_APP_REGISTRATION_URL: `https://${genUrls.registration}.${regionPlaceHolderInUrl}.timeedit.io/api`,
    REACT_APP_PREFERENCES_URL: `https://${genUrls.preferences}.${regionPlaceHolderInUrl}.timeedit.io/`,
    REACT_APP_UNIVERSAL_COOKIE_PATHNAME: 'timeedit.io',
    CLIENT_ID: 'teAdmin',
    APP_NAME: 'teAdmin',
    NOTIFICATION_KEY: 'TE_ADMIN_NOTIFICATION',
  },
  production: {
    REACT_APP_URL: `https://${genUrls.dataManager}.timeedit.net/`,
    REACT_APP_BASE_URL: isOldAdmin
      ? 'https://old-admin.timeedit.net/v1'
      : `https://${genUrls.restApi}.${regionPlaceHolderInUrl}.timeedit.net/v1`,
    REACT_APP_AUTH_DOMAIN: `https://auth.timeedit.net`,
    REACT_APP_PATHWAY_SERVICE_URL: `https://${genUrls.pathway}.${regionPlaceHolderInUrl}.timeedit.net/`,
    REACT_APP_PATHWAY_SERVICE_WS_URL: `wss://${genUrls.pathway}.${regionPlaceHolderInUrl}.timeedit.net/`,
    REACT_APP_INTEGRATION_SERVICE_URL: `https://${genUrls.integration}.${regionPlaceHolderInUrl}.timeedit.net/`,
    REACT_APP_ACTIVITY_MANAGER_SERVICE_URL: `https://${genUrls.am}.${regionPlaceHolderInUrl}.timeedit.net/`,
    REACT_APP_NODE_CSV_URL: `https://${genUrls.activityImporter}.${regionPlaceHolderInUrl}.timeedit.net/`,
    REACT_APP_REST_API_URL: `https://${genUrls.restApi}.${regionPlaceHolderInUrl}.timeedit.net/`,
    REACT_APP_REGISTRATION_URL: `https://${genUrls.registration}.${regionPlaceHolderInUrl}.timeedit.net/api`,
    REACT_APP_PREFERENCES_URL: `https://${genUrls.preferences}.${regionPlaceHolderInUrl}.timeedit.net/`,
    REACT_APP_UNIVERSAL_COOKIE_PATHNAME: 'timeedit.net',
    CLIENT_ID: 'teAdmin',
    APP_NAME: 'teAdmin',
    NOTIFICATION_KEY: 'TE_ADMIN_NOTIFICATION',
  },
};
