import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import WeekSelector, { TWeekSelectorBaseProps, TWeekSelectorValue } from './WeekSelector';
import { castArray } from 'lodash';
import intl from 'i18n/intl';

const language = intl.messages as Record<string, string>;

type TWeekSelectorModalProps = TWeekSelectorBaseProps & {
  value: number | number[];
  onSubmit: (value: TWeekSelectorValue[]) => void;
};
export default function WeekSelectorModal(props: TWeekSelectorModalProps) {
  const { value, allowMultiple, onSubmit, editable, disabledWeeks, allWeeks, allowClear, numberOfTracks } = props;

  const [open, setOpen] = useState(false);
  const [localValue, setLocalValue] = useState<TWeekSelectorValue[]>(castArray(value));

  useEffect(() => {
    setLocalValue(castArray(value));
  }, [value]);

  useEffect(() => {
    if (!open) {
      setLocalValue(castArray(value));
    }
  }, [open]);

  return (
    <>
      <Button size="small" onClick={() => setOpen(true)}>
        {editable ? language.edit : language.view}
      </Button>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        maskClosable={false}
        title={language.edit_weeks}
        width={676}
        onOk={() => {
          onSubmit(localValue);
          setOpen(false);
        }}
        okButtonProps={{
          style: {
            display: !editable ? 'none' : undefined,
          },
        }}
        cancelButtonProps={{
          type: !editable ? 'primary' : undefined,
        }}
        cancelText={language.close}
        okText={language.save}
      >
        <WeekSelector
          value={localValue}
          onChange={setLocalValue}
          allowMultiple={allowMultiple}
          disabledWeeks={disabledWeeks}
          editable={editable}
          allWeeks={allWeeks}
          numberOfTracks={numberOfTracks}
        />
      </Modal>
    </>
  );
}
