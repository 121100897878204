import { fetchActivityImportAttemptResult } from 'activities/services/activities.service';
import wsClient from '../../../services/socket.service';
import { TActivityImportResultData } from '@timeedit/activity-manager-shared-lib/lib/public/types/ActivityImportPubSub.type';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'slices/auth.slice';

const POLLING_INIT_WAIT_TIME = 30 * 1000;
const POLLING_INTERVAL_TIME = 10 * 1000;

type ListenerOptions = {
  callback: (data: TActivityImportResultData) => void;
  importAttemptId?: string;
  enabled: boolean;
};

export const useActivityImportResultListener = ({ callback, importAttemptId, enabled }: ListenerOptions) => {
  const user = useSelector(userSelector);
  const pollerTimeout = useRef<ReturnType<typeof setTimeout>>();

  const handleMessage = (event: MessageEvent<any>) => {
    if (!event?.data.startsWith('{')) {
      return;
    }
    try {
      const { eventName } = JSON.parse(event.data) as any;
      if (eventName === 'ACTIVITY_IMPORT_RESULT_RECEIVED') {
        const parsedResultData = JSON.parse(event?.data).payload as TActivityImportResultData;
        disable();
        callback(parsedResultData);
      }
    } catch (err) {
      disable();
      console.log('Got error when receiving activity import result through websocket', event);
    }
  };

  const fallbackPoller = async () => {
    if (!user?.organizationId || !importAttemptId) return;

    const result = await fetchActivityImportAttemptResult(user.organizationId, importAttemptId);
    const data = result?.activityImportResult;

    // If we get data in this poll.
    if (data) {
      disable();
      callback(data);
      return;
    }

    // Try to poll result again.
    pollerTimeout.current = setTimeout(fallbackPoller, POLLING_INTERVAL_TIME);
  };

  const enable = async () => {
    // Start listening for websocket events.
    wsClient.instance?.addEventListener('message', handleMessage);
    // Init fallback polling for websockets. We need this because websockets have proven to be unstable.
    pollerTimeout.current = setTimeout(fallbackPoller, POLLING_INIT_WAIT_TIME);
  };

  const disable = () => {
    // Remove websocket listener
    wsClient.instance?.removeEventListener('message', handleMessage);
    // Clear fallback timeout
    clearTimeout(pollerTimeout.current);
  };

  useEffect(() => {
    if (enabled) {
      enable();
    } else {
      disable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);
};
