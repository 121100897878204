import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  combinationFiltersSelector,
  combinationSelectedFormSelector,
  fetchCombinationsCount,
  fetchFormCombinations,
  generateStudentSetsPaginationSelector,
  generateStudentSetsSortingSelector,
} from '../slices/combinationStudents.slice';
import {
  TStudentSetGenerationTrigger,
  pathwayFiltersSelector,
  resetStudentSetGenerationTrigger,
  studentSetGenerationTriggerSelector,
} from '../slices/pathway.slice';
import { TEProgramObjectSelector, fetchTEObjects } from '../../slices/integration.slice';
import { usePrevious } from '../../hooks/usePrevious';

export const generateGetCombinationsQuery = (filters: {
  activityDataStatus: string[];
  scopedObject: string[];
  filteredCourses: string[];
  nameContains?: string;
}) => ({
  studentSetGenerationStatus: filters.activityDataStatus,
  programExtId: filters.scopedObject,
  courseExtId: filters.filteredCourses,
  nameContains: filters.nameContains || undefined,
});

export const useCombinationsWatchers = () => {
  const form = useSelector(combinationSelectedFormSelector);
  const dispatch = useDispatch();
  const filters = useSelector(combinationFiltersSelector);
  const filtersPathway = useSelector(pathwayFiltersSelector);
  const programObject = useSelector(TEProgramObjectSelector());
  const studentSetGenerationTrigger = useSelector(studentSetGenerationTriggerSelector());
  const previousStudentSetGenerationTrigger = usePrevious(studentSetGenerationTrigger) as
    | undefined
    | TStudentSetGenerationTrigger;

  const { generateStudentSetsPage, generateStudentSetsPerPage } = useSelector(generateStudentSetsPaginationSelector);
  const { generateStudentSetsSortBy, generateStudentSetsSortOrder } = useSelector(generateStudentSetsSortingSelector);

  const { formId, scopedObject, activityDataStatus } = filters;

  const selectedFormId = useMemo(
    () => filters.formId || filtersPathway.formId,
    [filters.formId, filtersPathway.formId],
  );

  useEffect(() => {
    if (!form) return;
    const { pathwaysSettings } = form;
    const { course, pathways } = pathwaysSettings;

    dispatch(fetchTEObjects(programObject?.objectTypeExtId || 'program'));
    dispatch(fetchTEObjects(course.datasource, { withInactiveObjects: true }));
    dispatch(fetchTEObjects(pathways.datasource));
  }, [dispatch, form, form?._id, programObject]);

  useEffect(() => {
    if (selectedFormId) {
      if (
        !studentSetGenerationTrigger ||
        studentSetGenerationTrigger.triggerNumberValue === 0 ||
        (previousStudentSetGenerationTrigger &&
          studentSetGenerationTrigger &&
          studentSetGenerationTrigger.triggerNumberValue !== previousStudentSetGenerationTrigger.triggerNumberValue)
      ) {
        dispatch(
          fetchFormCombinations(
            selectedFormId,
            {
              ...generateGetCombinationsQuery(filters),
              withCourseData: true,
            },
            generateStudentSetsPage,
            generateStudentSetsPerPage,
            generateStudentSetsSortBy,
            generateStudentSetsSortOrder,
            studentSetGenerationTrigger?.onlyCounts,
          ),
        );
        dispatch(resetStudentSetGenerationTrigger());
      }
    }
  }, [
    activityDataStatus,
    dispatch,
    filters,
    formId,
    scopedObject,
    selectedFormId,
    generateStudentSetsPage,
    generateStudentSetsPerPage,
    generateStudentSetsSortBy,
    generateStudentSetsSortOrder,
    studentSetGenerationTrigger,
    previousStudentSetGenerationTrigger,
  ]);

  useEffect(() => {
    if (formId) {
      dispatch(fetchCombinationsCount(formId));
      if (form) {
        const { pathwaysSettings } = form;
        const { course, pathways } = pathwaysSettings;

        dispatch(fetchTEObjects(programObject?.objectTypeExtId || 'program'));
        dispatch(fetchTEObjects(course.datasource, { withInactiveObjects: true }));
        dispatch(fetchTEObjects(pathways.datasource));
      }
    }
  }, [dispatch, formId, programObject]);
};
