import { Button, Modal } from 'antd';
import { isEmpty } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changePathwayStatus, changeAllPathwayStatus } from '../../slices/pathway.slice';
import { pathwayIsAcceptedOrRejected } from '../PathwayService.utils';
import { generateGetPathwaysQuery } from '../usePathwayWatchers';

// i18N
import intl, { getInlineString } from '../../../i18n/intl';
import api from '../../../services/api.service';
import { configService } from 'services/config.service';
import { DataManagerObjectReviewStatus } from '@timeedit/types/lib/constants';

const language: any = intl.messages;

interface Props {
  selectedRowKeys: string[];
  indexedRowData: Record<string, any>;
  selectedForm: any;
  onOpenCreatePathwayModal: () => void;
  isSelectingAllInAllPage?: boolean;
  filters: any;
}

const countDataManagerObjectsForBatchOperation = async (action: DataManagerObjectReviewStatus, filters: any) => {
  const res = await api.post({
    endpoint: `${configService().REACT_APP_PATHWAY_SERVICE_URL}v1/data-manager-objects/count/${action}`,
    data: {
      ...generateGetPathwaysQuery(filters),
      withCourseData: undefined,
      pathwayConfigFromUserId: filters.formId,
    },
    successMessage: false,
  });
  return res?.data?.dataManagerObjectsCount;
};

function PathwaysFooterActions({
  selectedRowKeys,
  indexedRowData,
  selectedForm,
  onOpenCreatePathwayModal,
  isSelectingAllInAllPage,
  filters,
}: Props) {
  const [operating, setOperating] = useState<undefined | 'approving' | 'rejecting' | 'reseting'>(undefined);
  const dispatch = useDispatch();
  const [modal, modalContextHolder] = Modal.useModal();

  const onAccept = async () => {
    setOperating('approving');
    let editableRowsLength = editableRows.length;
    if (isSelectingAllInAllPage) {
      editableRowsLength = await countDataManagerObjectsForBatchOperation('KEEP', filters);
    }
    if (!editableRowsLength) {
      modal.error({
        content: language.no_study_combinations_can_be_approved,
      });
      setOperating(undefined);
      return;
    }
    modal.confirm({
      title: getInlineString('approve_study_combination', editableRowsLength),
      onOk: async () => {
        if (isSelectingAllInAllPage) {
          await dispatch(changeAllPathwayStatus('KEEP'));
        } else {
          await dispatch(changePathwayStatus(editableRows, 'KEEP'));
        }
        setOperating(undefined);
      },
      onCancel: () => setOperating(undefined),
    });
  };

  const onReject = async () => {
    setOperating('rejecting');
    let editableRowsLength = editableRows.length;
    if (isSelectingAllInAllPage) {
      editableRowsLength = await countDataManagerObjectsForBatchOperation('REJECTED', filters);
    }
    if (!editableRowsLength) {
      modal.error({
        content: language.no_study_combinations_can_be_rejected,
      });
      setOperating(undefined);
      return;
    }
    modal.confirm({
      title: getInlineString('reject_study_combination', editableRowsLength),
      onOk: async () => {
        if (isSelectingAllInAllPage) {
          await dispatch(changeAllPathwayStatus('REJECTED'));
        } else {
          await dispatch(changePathwayStatus(editableRows, 'REJECTED'));
        }
        setOperating(undefined);
      },
      onCancel: () => setOperating(undefined),
    });
  };
  const editableRows = useMemo(() => {
    return selectedRowKeys.filter((rowId) => !pathwayIsAcceptedOrRejected(indexedRowData[rowId]?.reviewStatus));
  }, [selectedRowKeys, indexedRowData]);

  const onNotReviewed = async () => {
    setOperating('reseting');
    let editableRowsLength = rowsAbleToBackToNoReviewed.length;
    if (isSelectingAllInAllPage) {
      editableRowsLength = await countDataManagerObjectsForBatchOperation('NOT_REVIEWED', filters);
    }
    if (!editableRowsLength) {
      modal.error({
        content: language.no_study_combinations_can_be_set_back_to_not_reviewed,
      });
      setOperating(undefined);
      return;
    }
    modal.confirm({
      title: getInlineString('back_to_not_reviewed_study_combination', editableRowsLength),
      onOk: async () => {
        if (isSelectingAllInAllPage) {
          await dispatch(changeAllPathwayStatus('NOT_REVIEWED'));
        } else {
          await dispatch(changePathwayStatus(rowsAbleToBackToNoReviewed, 'NOT_REVIEWED'));
        }
        setOperating(undefined);
      },
      onCancel: () => setOperating(undefined),
    });
  };
  const rowsAbleToBackToNoReviewed = useMemo(() => {
    return selectedRowKeys.filter((rowId) => pathwayIsAcceptedOrRejected(indexedRowData[rowId]?.reviewStatus));
  }, [selectedRowKeys, indexedRowData]);

  return (
    <>
      <Button
        size="small"
        disabled={!isSelectingAllInAllPage && isEmpty(editableRows)}
        onClick={onAccept}
        type="primary"
        loading={operating === 'approving'}
      >
        {language.approve_selected}
      </Button>
      <Button
        size="small"
        disabled={!isSelectingAllInAllPage && isEmpty(editableRows)}
        onClick={onReject}
        loading={operating === 'rejecting'}
      >
        {language.reject_selected}
      </Button>
      <Button
        size="small"
        disabled={!isSelectingAllInAllPage && isEmpty(rowsAbleToBackToNoReviewed)}
        onClick={onNotReviewed}
        loading={operating === 'reseting'}
      >
        {language.not_reviewed_selected}
      </Button>
      <Button size="small" disabled={!selectedForm} onClick={onOpenCreatePathwayModal}>
        {language.create_new}
      </Button>
      {modalContextHolder}
    </>
  );
}

export default PathwaysFooterActions;
