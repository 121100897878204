/* eslint-disable react/function-component-definition */
import React from 'react';

export const TEImportOutlined = () => (
  <span className="anticon anticon-download">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8797 11.8344H13.0391C12.9734 11.8344 12.9187 11.8891 12.9187 11.9547V12.9203H3.07969V3.07969H12.9203V4.04531C12.9203 4.11094 12.975 4.16563 13.0406 4.16563H13.8813C13.9469 4.16563 14.0016 4.1125 14.0016 4.04531V2.47969C14.0016 2.21406 13.7875 2 13.5219 2H2.47969C2.21406 2 2 2.21406 2 2.47969V13.5203C2 13.7859 2.21406 14 2.47969 14H13.5203C13.7859 14 14 13.7859 14 13.5203V11.9547C14 11.8875 13.9453 11.8344 13.8797 11.8344ZM14.0938 7.4375H9.1875V6.25C9.1875 6.14531 9.06563 6.08594 8.98438 6.15156L6.76719 7.90156C6.75225 7.91326 6.74016 7.92819 6.73185 7.94525C6.72355 7.96231 6.71923 7.98103 6.71923 8C6.71923 8.01897 6.72355 8.03769 6.73185 8.05475C6.74016 8.0718 6.75225 8.08674 6.76719 8.09844L8.98438 9.84844C9.06719 9.91406 9.1875 9.85469 9.1875 9.75V8.5625H14.0938C14.1625 8.5625 14.2188 8.50625 14.2188 8.4375V7.5625C14.2188 7.49375 14.1625 7.4375 14.0938 7.4375Z"
        fill="currentColor"
        fillOpacity="0.88"
      />
    </svg>
  </span>
);
