/* eslint-disable no-param-reassign */
import { IDefaultReducerState } from '../types/state.interface';

/**
 * @function finishedLoadingSuccess
 * @description standardized way of indicating API call has finished loading successfully in redux state
 * @param {IDefaultReducerState} state
 * @returns {void}
 */
export const finishedLoadingSuccess = (state: IDefaultReducerState): void => {
  state.loading = false;
  state.hasErrors = false;
};

/**
 * @function finishedLoadingFailure
 * @description standardized way of indicating API call has finished loading with failure in redux state
 * @param {IDefaultReducerState} state
 * @returns {void}
 */
export const finishedLoadingFailure = (state: IDefaultReducerState): void => {
  state.loading = false;
  state.hasErrors = true;
};

/**
 * @function isLoadingRequest
 * @description standardized way of indicating API call is currently loading in redux state
 * @param {IDefaultReducerState} state
 * @returns {void}
 */
export const isLoadingRequest = (state: IDefaultReducerState): void => {
  state.loading = true;
  state.hasErrors = false;
};
