import { createContext, useContext } from 'react';
import { TABS } from './ImportReservationsModal.constants';
import { TImportTemplate } from 'activities/pages/types/importTemplate.type';
import { TActivityImportResultData } from '@timeedit/activity-manager-shared-lib/lib/public/types/ActivityImportPubSub.type';
import { Dayjs } from 'dayjs';

export type TImportReservationsSettings = {
  name: string;
  description?: string;
  reservationMode?: { id: number; extId: string };
  startDate?: Dayjs;
  endDate?: Dayjs;
  activityCreateTemplateId?: string;
};
interface IImportReservationsModalContext {
  settings: TImportReservationsSettings;
  setSettings: (settings: TImportReservationsSettings) => void;
  importTemplates: Record<string, TImportTemplate>;
  tab: (typeof TABS)[number]['key'];
  setTab: (tab: (typeof TABS)[number]['key']) => void;
  onClose: () => void;
  onReset: () => void;
  file?: File;
  setFile: (file?: File) => void;
  importing: boolean;
  importErrors: string[];
  importResult?: TActivityImportResultData;
  setImportResult: (data: TActivityImportResultData) => void;
  onFinish: () => void;
  setImporting: (importing: boolean) => void;
}

export const initialValue: IImportReservationsModalContext = {
  settings: {
    name: '',
    description: '',
    reservationMode: undefined,
  },
  setSettings: () => {},
  importTemplates: {},
  tab: TABS[0].key,
  setTab: () => {},
  onClose: () => {},
  onReset: () => {},
  file: undefined,
  setFile: () => {},
  importing: false,
  importErrors: [],
  importResult: undefined,
  setImportResult: () => undefined,
  onFinish: () => undefined,
  setImporting: () => undefined,
};

const ImportReservationsModalContext = createContext<IImportReservationsModalContext>(initialValue);
export const useImportReservationsModal = () => useContext(ImportReservationsModalContext);
export default ImportReservationsModalContext;
