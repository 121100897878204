import React, { useMemo } from 'react';
import { Flex, Form } from 'antd';
import { castArray, orderBy, sortBy } from 'lodash';
import dayjs from 'dayjs';
import TEFormItem, { getValueFromFormItem } from '../BaseElements/TEFormItem';
import WeekSelectorModal from '../WeekSelector/WeekSelectorModal';
import intl from 'i18n/intl';
import { TWeekSelectorBaseProps, TWeekSelectorValue } from '../WeekSelector/WeekSelector';
import { startAndEndToWeeks } from '../Table/ActivitiesTable.utils';

const language = intl.messages as Record<string, string>;

export default function ActivityWeeks({
  editable,
  allowMultiple,
  disabledWeeks,
  allWeeks,
  numberOfTracks,
  onSubmit,
}: TWeekSelectorBaseProps & {
  onSubmit?: (weeks: TWeekSelectorValue[]) => void;
}) {
  const form = Form.useFormInstance();
  const weeks = castArray(getValueFromFormItem(Form.useWatch('weeks', form)) || []);
  const formattedWeeks = useMemo(() => {
    try {
      if (Array.isArray(weeks)) return weeks;
      return JSON.parse(weeks);
    } catch {
      return weeks;
    }
  }, [weeks]);

  const weeksDisplay = useMemo(() => {
    if (!formattedWeeks) return '';

    const _startAndEndWeeks = weeks.sort().map((week: TWeekSelectorValue) => {
      if (!Array.isArray(week))
        return startAndEndToWeeks({
          startDate: dayjs(week).toJSON(),
          endDate: dayjs(week).toJSON(),
        });
      const sorted = week.sort();
      return startAndEndToWeeks({
        startDate: dayjs(sorted[0]).toJSON(),
        endDate: dayjs(sorted[sorted.length - 1]).toJSON(),
      });
    });
    const sorted = orderBy(_startAndEndWeeks, ['asc']);
    const chunks: (string | number)[][] = [];
    sorted.forEach((week) => {
      const currentChunk = chunks[chunks.length - 1];
      if (!currentChunk) {
        chunks.push([week]);
        return;
      }
      if (Number.isNaN(Number(week))) {
        chunks.push([week as string]);
        return;
      }
      if (Number(week) - Number(currentChunk[currentChunk.length - 1]) === 1) {
        if (currentChunk.length === 1) currentChunk.push(week);
        else currentChunk[currentChunk.length - 1] = week;
        return;
      }
      chunks.push([week]);
    });

    const startAndEndWeeks = chunks.map((item) => item.join('-')).join(', ');
    return (
      <Flex gap={6} align="center">
        {startAndEndWeeks}
        &nbsp;
        {formattedWeeks.length > 1 ? `(x${formattedWeeks.length})` : ''}
        <TEFormItem name="weeks">
          {({ value, onChange }) => (
            <WeekSelectorModal
              allowMultiple={allowMultiple}
              value={value as unknown as number[]}
              onSubmit={(weeks) => {
                onChange(weeks);
                if (typeof onSubmit === 'function') {
                  onSubmit(weeks);
                }
              }}
              editable={editable}
              disabledWeeks={disabledWeeks}
              allWeeks={allWeeks}
              numberOfTracks={numberOfTracks}
            />
          )}
        </TEFormItem>
      </Flex>
    );
  }, [formattedWeeks, editable]);

  const weekLabel = useMemo(() => {
    if (formattedWeeks?.length === 1) return language.week;
    return language.weeks;
  }, [formattedWeeks?.length]);

  return (
    <Form.Item name={editable && 'dateRanges'} data-testid="ACTIVITY_WEEKS" label={weekLabel} tooltip={weekLabel}>
      {weeksDisplay}
    </Form.Item>
  );
}
