import React, { useEffect, useMemo, useState } from 'react';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { TActivityElementCategoryValue } from 'activities/pages/types/activity.type';
import { Button, Typography } from 'antd';
import { toActivityValueDisplay } from './ActivitiesTable.utils';
import TEObjectManager from 'activities/services/TEObjects.service';

export default function ActivityCategoriesValue({
  activityValueCategories,
  flatten,
}: {
  activityValueCategories: TActivityElementCategoryValue[];
  flatten?: boolean;
}) {
  const [activeCategoryIndex, setActivityCategoryIndex] = useState<number>(0);

  const allKeys = useMemo(() => {
    return activityValueCategories.map(({ id }) => {
      const fieldId = typeof id === 'number' ? TEObjectManager.fieldsById[id] : id;
      return TEObjectManager.getFieldLabel(fieldId);
    });
  }, [activityValueCategories, TEObjectManager.loadingFields]);

  useEffect(() => {
    if (activityValueCategories.length && !activityValueCategories[activeCategoryIndex]) {
      setActivityCategoryIndex(0);
    }
  }, [activityValueCategories, activeCategoryIndex]);

  if (flatten) {
    return (
      <div className="">
        {activityValueCategories.map((category, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${category.id}_${idx}`}>
            {allKeys[idx]}: {category.values.join(', ')}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="te-flex te-items-center">
      <div className="te-flex te-items-center" style={{ whiteSpace: 'nowrap' }}>
        <Button
          type="link"
          onClick={(e) => {
            e.stopPropagation();
            setActivityCategoryIndex(Math.max(activeCategoryIndex - 1, 0));
          }}
          disabled={activeCategoryIndex === 0}
        >
          <CaretLeftOutlined />
        </Button>
        {Math.min(1 + activeCategoryIndex, allKeys.length)} / {allKeys.length}
        <Button
          type="link"
          onClick={(e) => {
            e.stopPropagation();
            setActivityCategoryIndex(Math.min(activeCategoryIndex + 1, allKeys.length - 1));
          }}
          disabled={!allKeys.length || activeCategoryIndex === allKeys.length - 1}
        >
          <CaretRightOutlined />
        </Button>
      </div>
      &nbsp;
      <div className="te-flex te-flex-col">
        <Typography.Text type="secondary" style={{ lineHeight: 1 }}>
          <small>{allKeys[activeCategoryIndex] || ' '}</small>
        </Typography.Text>
        <Typography.Text>
          {toActivityValueDisplay(activityValueCategories[activeCategoryIndex]?.values) || 'N/A'}
        </Typography.Text>
      </div>
    </div>
  );
}
