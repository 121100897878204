import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// i18N
import intl from '../../../i18n/intl';
import { ReviewByCourseQuery } from '@timeedit/types/lib/constants';
import { harmonizeEnrollmentDiffs } from 'study-combinations/slices/pathway.slice';
import { TExpectedEnrollmentHarmonizationReturnSuccess } from '@timeedit/preferences-and-dm-commons/lib/src';

const language: any = intl.messages;

interface Props {
  reviewByCourseQuery: ReviewByCourseQuery;
  courseLength: number;
  formId: string;
}

function ReviewByCourseFooterActions({ reviewByCourseQuery, courseLength, formId }: Props) {
  const [operating, setOperating] = useState<undefined | 'harmonizingEnrollmentDiffs'>(undefined);
  const dispatch = useDispatch();
  const [modal, modalContextHolder] = Modal.useModal();

  const showResultModalOfHarmonizingEnrollmentDiffs = (result: TExpectedEnrollmentHarmonizationReturnSuccess) => {
    const maxOverFlowStyle = {
      maxHeight: '300px',
      overflowY: 'auto' as const,
    };
    modal.success({
      title: language.expected_enrollment_harm,
      content: (
        <div>
          {result.coursesFailed.length > 0 && (
            <div>
              {language.expected_enrollment_harm_failures}

              <div style={maxOverFlowStyle}>
                {result.coursesFailed.map((failedCourse) => (
                  <div key={failedCourse._id}>
                    {failedCourse._id} - {failedCourse.reason}
                  </div>
                ))}
              </div>
              <hr />
            </div>
          )}

          {result.coursesSucceeded.length > 0 && result.coursesFailed.length === 0 && (
            <div>{language.expected_enrollment_harm_success}</div>
          )}

          <Button
            size="small"
            onClick={() => {
              // useSate shows weird behavior and doesn't really work inside a Modal. So, back to good old plain DOM manipulation!
              const elem = document.getElementById('harmonizingEnrollmentDiffsProtocolDiv');
              if (elem && elem.style.visibility === 'hidden') {
                elem.style.visibility = 'visible';
              } else if (elem) {
                elem.style.visibility = 'hidden';
              }
            }}
            type="default"
          >
            {language.show_protocol}
          </Button>
          <div id="harmonizingEnrollmentDiffsProtocolDiv" style={{ ...maxOverFlowStyle, visibility: 'hidden' }}>
            {result.protocol.join('. ')}
          </div>
        </div>
      ),
    });
  };

  const onHarmonizingEnrollmentDiffs = async () => {
    setOperating('harmonizingEnrollmentDiffs');
    modal.confirm({
      title: language.expected_enrollment_harm,
      content: language.expected_enrollment_harm_explanation,
      onOk: async () => {
        const resultOfHarmonizeEnrollmentDiffs = await dispatch(
          harmonizeEnrollmentDiffs({ ...reviewByCourseQuery, pathwayConfigFromUserId: formId }),
        );
        showResultModalOfHarmonizingEnrollmentDiffs(
          (resultOfHarmonizeEnrollmentDiffs as unknown as { payload: TExpectedEnrollmentHarmonizationReturnSuccess })
            .payload,
        );
        setOperating(undefined);
      },
      onCancel: () => setOperating(undefined),
    });
  };

  return (
    <>
      <Button
        size="small"
        disabled={courseLength === 0}
        onClick={onHarmonizingEnrollmentDiffs}
        type="primary"
        loading={operating === 'harmonizingEnrollmentDiffs'}
      >
        {language.expected_enrollment_harm}
      </Button>
      {modalContextHolder}
    </>
  );
}

export default ReviewByCourseFooterActions;
