/* eslint-disable no-useless-computed-key */
// @ts-nocheck
import { configService } from './config.service';
import { getAccessTokenRaw, getAccessTokenDecoded } from './token.service';

const retryIntervalTime = 100;

class WebSocketClient {
  instance?: WebSocket;

  onRetry?: () => void;

  retryInterval: ReturnType<typeof setInterval>;

  connectionAttemptUnderWay: boolean;

  constructor() {
    this.instance = undefined;
  }

  init(onRetry?: () => void) {
    if (process.env.REACT_APP_TE_APP_ENV === 'developmentFilip') {
      return;
    }
    const self = this;

    if (typeof onRetry === 'function') {
      self.onRetry = onRetry;
    }
    if (self.connectionAttemptUnderWay) return;
    if (self.instance?.readyState === 2) return;

    self.connectionAttemptUnderWay = true;

    const authToken = getAccessTokenRaw();

    try {
      const userTokenPayload = getAccessTokenDecoded();

      document.cookie =
        process.env.REACT_APP_TE_APP_ENV === 'development' || process.env.REACT_APP_TE_APP_ENV === 'teCtl'
          ? `te_auth_token_for_wss=Bearer ${authToken}; path=/;`
          : `te_auth_token_for_wss=Bearer ${authToken}; path=/; domain=${
              configService().REACT_APP_UNIVERSAL_COOKIE_PATHNAME
            }; SameSite=None; Secure`;

      const webSocket = new WebSocket(
        `${configService().REACT_APP_PATHWAY_SERVICE_WS_URL}?region=${userTokenPayload?.region}`,
      );

      this.instance = webSocket;

      webSocket.onmessage = (message) => {
        if (message?.data === 'Connected successfully') {
          self.connectionAttemptUnderWay = false;
          clearInterval(self.retryInterval);
          // Reconnect only after once successfully connected so not to add pointless retries for auth failures or something
          webSocket.onclose = () => {
            self.retryInterval = setInterval(self.triggerRetryInit()?.bind(self), retryIntervalTime);
          };
        }
      };

      webSocket.onerror = async (error) => {
        console.log(error);
        self.connectionAttemptUnderWay = false;
        await new Promise((resolve) => {
          setTimeout(resolve, retryIntervalTime);
        });
        self.triggerRetryInit()?.bind(self)();
      };
    } catch (err) {
      console.log('Problem in WebSocketClient init function. Probably just not logged-in.');
      console.error(err);
    }
  }

  triggerRetryInit() {
    if (typeof this.onRetry === 'function') {
      this.onRetry();
    }
    this.init();
  }
}

export default new WebSocketClient();
