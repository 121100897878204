import React, { useMemo } from 'react';
import { TWeekSelectorValue } from './WeekSelector';
import intl from 'i18n/intl';

const language = intl.messages as Record<string, string>;

export default function SelectedWeeksCounter({ weeks }: { weeks: TWeekSelectorValue[] }) {
  const filteredWeeks = useMemo(() => weeks.filter((week) => (Array.isArray(week) ? week.length : week)), [weeks]);
  const singleWeeks = useMemo(() => {
    return filteredWeeks.filter((week) => !Array.isArray(week)).length;
  }, [filteredWeeks]);

  const ranges = useMemo(() => {
    return filteredWeeks.length - singleWeeks;
  }, [filteredWeeks]);
  return (
    <div>
      {!!singleWeeks && (
        <span>
          {singleWeeks} {singleWeeks > 1 ? language.weeks?.toLowerCase() : language.week?.toLowerCase()} selected
        </span>
      )}
      {!!singleWeeks && !!ranges && ', '}
      {!!ranges && (
        <span>
          {ranges} {ranges > 1 ? language.ranges?.toLocaleLowerCase() : language.range?.toLocaleLowerCase()} selected
        </span>
      )}
    </div>
  );
}
