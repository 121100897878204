import { PageInfo } from '@timeedit/registration-components';
import React from 'react';
import { getAllocateUrl } from '../../redirects';
import { EAppEnv } from '@timeedit/types/lib/enums/appEnv.enum';

export function RegistrationSettingsPage() {
  return (
    <PageInfo
      onOk={() => window.location.replace(getAllocateUrl(process.env.REACT_APP_TE_APP_ENV as EAppEnv, ''))}
      translations={{
        descriptionText: 'Allocation is now available at a new URL',
        okText: 'Go to Allocate',
        titleText: 'Allocation has moved',
      }}
    />
  );
}
