import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import { Filters } from '@timeedit/ui-components';

import moment from 'moment';
import { MAPPING_COMBINATION_STUDY_STATUS_TO_TAG_TYPE } from '../PathwayServiceConstants';
import {
  EKindOfFilter,
  TBaseFilterOption,
  TFilterOptions,
} from '@timeedit/ui-components/lib/src/components/Filters/Filters.type';
import intl from '../../../i18n/intl';
import { intersection, isEmpty } from 'lodash';
import {
  TEFieldsByKindSelector,
  TEObjectFieldLabelsMappingSelector,
  TEObjectMappingSelector,
  TEObjectSelector,
} from '../../../slices/integration.slice';
import {
  combinationFiltersSelector,
  combinationFormsSelector,
  combinationSelectedFormSelector,
  generateStudentSetsChangePagination,
  generateStudentSetsPaginationSelector,
  selectedFormByFormIdSelector,
  updateCombinationFilteredCourses,
  updateCombinationFilters,
} from '../../slices/combinationStudents.slice';
import { pathwayFiltersSelector, updateFilters } from '../../slices/pathway.slice';

const language = intl.messages;

function StudyCombinationFilters() {
  const pathwayFiltersValue = useSelector(pathwayFiltersSelector);
  const combinationFiltersValue = useSelector(combinationFiltersSelector);

  const selectedFormId = useMemo(
    () => combinationFiltersValue.formId || pathwayFiltersValue.formId,
    [pathwayFiltersValue, combinationFiltersValue],
  );

  const forms = useSelector(combinationFormsSelector);
  const form = useSelector(combinationSelectedFormSelector);
  const courses = useSelector(TEObjectSelector(form?.pathwaysSettings.course.datasource));
  const selectedForm: any = useSelector(selectedFormByFormIdSelector(selectedFormId));
  const dispatch = useDispatch();
  const fieldLabelsMapping = useSelector(TEObjectFieldLabelsMappingSelector());
  const fieldsByKind = useSelector(TEFieldsByKindSelector());
  const mapping = useSelector(TEObjectMappingSelector());
  const currentPagination = useSelector(generateStudentSetsPaginationSelector);

  const getCategorizedFilterFields = useCallback(
    (field?: string): string[] => {
      if (!field) return [];
      return (mapping.objectTypes?.[field]?.fields || []).map((field: any) => {
        return field.fieldExtId;
      });
    },
    [mapping],
  );

  const generateCategorizedFilterOptions = useCallback(
    (datasource?: string): TFilterOptions => {
      if (!datasource) return {};
      const allFields = getCategorizedFilterFields(datasource);
      const check = allFields
        .filter((field) => fieldsByKind[field])
        .reduce((results, field) => {
          const opts: string[] = fieldsByKind[field]?.categories || [];
          const filterOption: TBaseFilterOption = {
            key: field,
            parentKey: datasource,
            parentLabel: mapping?.objectTypes[datasource]?.applicationObjectTypeLabel || '',
            label: fieldLabelsMapping[datasource || ''][field] || field,
            options: opts.map((opt) => ({
              label: opt,
              value: opt,
            })),
            kindOfFilter: EKindOfFilter.DROPDOWN,
          };
          return {
            ...results,
            [field]: filterOption,
          };
        }, {});

      return check;
    },
    [getCategorizedFilterFields, fieldsByKind, fieldLabelsMapping, mapping],
  );

  const courseFilterOptions = generateCategorizedFilterOptions(
    selectedForm ? selectedForm.pathwaysSettings?.course.datasource : '',
  );

  const filterProps = useMemo(() => {
    const filterOptions: TFilterOptions = {
      formId: {
        label: 'Period',
        options: forms?.map((form) => {
          return {
            value: form._id,
            label: [
              moment(form.period.startDate).format("MMM DD 'YY"),
              '-',
              moment(form.period.endDate).format("MMM DD 'YY"),
              `(${form.name})`,
            ].join(' '),
          };
        }),
        value: selectedFormId,
        kindOfFilter: EKindOfFilter.DROPDOWN,
        key: 'formId',
      },
      activityDataStatus: {
        label: 'Status',
        options: Object.entries(MAPPING_COMBINATION_STUDY_STATUS_TO_TAG_TYPE).map((c) => ({
          label: c[1].status,
          value: c[0],
        })),
        allowMultiple: true,
        disabled: !selectedFormId,
        kindOfFilter: EKindOfFilter.DROPDOWN,
        key: 'activityDataStatus',
      },
      nameContains: {
        kindOfFilter: EKindOfFilter.TEXT_INPUT,
        key: 'nameContains',
        label: language.study_combination as string,
        disabled: !selectedForm,
        debounce: true,
      },
    };
    return {
      filterOptions,
      otherFilterOptionsResetTriggers: ['formId'],
      otherFilterOptions: courseFilterOptions,
      filtersValue: combinationFiltersValue,
      onChange: (filters: Record<string, any>) => {
        if (form && filters && !isEmpty(filters[form.pathwaysSettings.course.datasource])) {
          const fields = Object.keys(filters[form.pathwaysSettings.course.datasource]);

          const filteredCourses = courses
            .filter((course) => {
              return fields
                .filter((field) => !isEmpty(filters[form.pathwaysSettings.course.datasource][field]))
                .every((field) => {
                  const items = filters[form.pathwaysSettings.course.datasource][field];
                  if (!items) {
                    return true;
                  }
                  if (Array.isArray(course[field]) && !isEmpty(intersection(items, course[field]))) {
                    return true;
                  }
                  if (typeof course[field] === 'string' && items.includes(course[field])) {
                    return true;
                  }
                  return false;
                });
            })
            // eslint-disable-next-line camelcase
            .map(({ te_extid }) => te_extid)
            .sort();

          if (JSON.stringify(filteredCourses) !== JSON.stringify(filters.filteredCourses)) {
            const filtersToSend =
              filteredCourses.length === courses.length ? { ...filters } : { ...filters, filteredCourses };
            dispatch(updateCombinationFilters(filtersToSend));
          } else {
            dispatch(updateCombinationFilters(filters));
          }
        } else {
          dispatch(updateCombinationFilters(filters));
        }

        dispatch(
          generateStudentSetsChangePagination({
            generateStudentSetsPage: 1,
            generateStudentSetsPerPage: currentPagination.generateStudentSetsPerPage,
          }),
        );
        dispatch(updateFilters(filters));
      },
    };
  }, [forms, selectedFormId, courseFilterOptions, combinationFiltersValue, dispatch]);

  return (
    // @ts-ignore
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Filters {...filterProps} />
  );
}

export default StudyCombinationFilters;
