import { usePrevious } from '../../hooks/usePrevious';
import { isEmpty, keyBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { combinationFiltersSelector, updateCombinationFilters } from '../slices/combinationStudents.slice';
import { TEObjectLabeledFieldSelector, TEObjectSelector } from '../../slices/integration.slice';
import {
  fetchDataManagerObjectCount,
  fetchFormPathways,
  pathwayFiltersSelector,
  pathwayReviewByStudyCombinationPaginationSelector,
  pathwayReviewByStudyCombinationSortingSelector,
  pathwayPrimaryObjectsSelector,
  pathwaySelectedFormSelector,
  pathwaySelector,
  pathwaysFetchingTrigger,
} from '../slices/pathway.slice';
import { DataManagerObjectReviewStatus, pathwayStatuses } from '@timeedit/types/lib/constants';

export const generateGetPathwaysQuery = (filters: {
  reviewStatus?: DataManagerObjectReviewStatus;
  pathwayStatus?: (typeof pathwayStatuses)[keyof typeof pathwayStatuses];
  scopedObject?: string[];
  courseExtId?: string[];
  programExtId?: string[];
  pathwayExtId?: string[];
  expectedEnrollment?: { GREATER_THAN?: number; LESS_THAN?: number };
  credits?: { GREATER_THAN?: number; LESS_THAN?: number };
  nameContains?: string;
}) => {
  const queryOutput: Record<string, unknown> = {
    reviewStatus: filters.reviewStatus || undefined,
    pathwayStatus: filters.pathwayStatus || undefined,
    pathwayExtId: filters?.pathwayExtId || undefined,
    withCourseData: false,
    expectedEnrollmentMin: filters?.expectedEnrollment?.GREATER_THAN ?? undefined,
    expectedEnrollmentMax: filters?.expectedEnrollment?.LESS_THAN ?? undefined,
    creditsMin: filters?.credits?.GREATER_THAN ?? undefined,
    creditsMax: filters?.credits?.LESS_THAN ?? undefined,
    nameContains: filters.nameContains || undefined,
  };

  if (filters?.courseExtId) {
    queryOutput.courseExtId = filters?.courseExtId;
  }

  let programExtIdOut: null | string[] = null;
  if (filters.scopedObject) {
    programExtIdOut = filters.scopedObject;
  }

  if (filters?.programExtId) {
    if (!isEmpty(filters.scopedObject)) {
      programExtIdOut = filters?.programExtId.filter((extId) => filters.scopedObject?.includes(extId));
    } else {
      programExtIdOut = [...(programExtIdOut || []), ...(filters?.programExtId || [])];
    }
  }

  if (programExtIdOut) {
    queryOutput.programExtId = programExtIdOut;
  }

  return queryOutput;
};

export const useTEObjectWatcher = () => {
  const form = useSelector(pathwaySelectedFormSelector);
  const dispatch = useDispatch();
  const filters = useSelector(pathwayFiltersSelector);

  const { reviewStudyCombinationsPage, reviewStudyCombinationsPerPage } = useSelector(
    pathwayReviewByStudyCombinationPaginationSelector,
  );
  const { reviewStudyCombinationsSortBy, reviewStudyCombinationsSortOrder } = useSelector(
    pathwayReviewByStudyCombinationSortingSelector,
  );

  const filtersCombinations = useSelector(combinationFiltersSelector);

  const { formId, reviewStatus, scopedObject, pathwayStatus } = filters;

  const trigger = useSelector(pathwaysFetchingTrigger());
  const prevTrigger = usePrevious(trigger);

  const courses = useSelector(TEObjectSelector(form?.pathwaysSettings.course.datasource));
  const programs = useSelector(pathwayPrimaryObjectsSelector);
  const pathwayObjects = useSelector(TEObjectSelector(form?.pathwaysSettings.pathways.datasource));
  const pathways = useSelector(pathwaySelector());
  const labeledFields = useSelector(TEObjectLabeledFieldSelector());

  const fieldsSetting = useMemo(() => {
    return {
      labeledCourseField: labeledFields?.[form?.pathwaysSettings?.course?.datasource || ''],
      labeledPathwayField: labeledFields?.[form?.pathwaysSettings?.pathways?.datasource || ''],
      creditField: form?.pathwaysSettings?.course?.fields?.credits,
      historicalField: form?.pathwaysSettings?.pathways.enrollment.historicalEnrollment,
    };
  }, [form, labeledFields]);

  const indexedPathways = useMemo(() => {
    return keyBy(pathways, '_id');
  }, [pathways]);

  useEffect(() => {
    if (formId && !filtersCombinations.formId) {
      dispatch(
        updateCombinationFilters({
          ...filtersCombinations,
          formId,
        }),
      );
    }
  }, [formId]);

  useEffect(() => {
    if (formId) {
      dispatch(
        fetchFormPathways(
          formId,
          generateGetPathwaysQuery(filters),
          reviewStudyCombinationsPage,
          reviewStudyCombinationsPerPage,
          reviewStudyCombinationsSortBy,
          reviewStudyCombinationsSortOrder,
        ),
      );
    }
  }, [
    filters,
    formId,
    reviewStatus,
    scopedObject,
    reviewStudyCombinationsPage,
    reviewStudyCombinationsPerPage,
    reviewStudyCombinationsSortBy,
    reviewStudyCombinationsSortOrder,
  ]);

  useEffect(() => {
    if (formId) {
      dispatch(fetchDataManagerObjectCount(formId));
    }
  }, [formId]);

  useEffect(() => {
    if (trigger && prevTrigger && trigger !== prevTrigger) {
      if (formId) {
        dispatch(
          fetchFormPathways(
            formId,
            generateGetPathwaysQuery(filters),
            reviewStudyCombinationsPage,
            reviewStudyCombinationsPerPage,
            reviewStudyCombinationsSortBy,
            reviewStudyCombinationsSortOrder,
            trigger.split('.')?.[1]?.length > 0,
            // We "abuse" number of decimals to decide about what to do: 0 decimals => fetch everything (onlyCounts: false); one decimal (like 1.1) => fetch "onlyCounts" true
          ),
        );
        dispatch(fetchDataManagerObjectCount(formId));
      }
    }
  }, [
    prevTrigger,
    trigger,
    filters,
    formId,
    reviewStudyCombinationsPage,
    reviewStudyCombinationsPerPage,
    reviewStudyCombinationsSortBy,
    reviewStudyCombinationsSortOrder,
  ]);

  return {
    filters,
    pathways,
    indexedPathways,
    courses,
    programs,
    pathwayObjects,
    fieldsSetting,
  };
};
