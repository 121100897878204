import intl from 'i18n/intl';

const language = intl.messages;

export const TABS = [
  {
    key: 'upload',
    title: language.upload as string,
  },
  {
    key: 'preview',
    title: language.preview as string,
  },
  {
    key: 'import',
    title: language.import as string,
  },
];
