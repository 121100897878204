import React from 'react';
import { useCreateNewImportTemplateModal } from './CreateNewImportTemplateModal.context';
import { TABS } from './CreateNewImportTemplateModal.constants';
import intl from 'i18n/intl';

const language = intl.messages;

export default function CreateNewImportTemplateModalQuickSummary() {
  const { getSummary, tab } = useCreateNewImportTemplateModal();
  const summary = getSummary();

  if (tab === TABS[0].key) return null;

  return (
    <div
      data-testid="CREATE_NEW_IMPORT_TEMPLATE_MODAL_QUICK_SUMMARY"
      className="te-flex te-items-center te-gap-3 create-new-import-template-modal__quick-summary"
    >
      <div className="te-flex-1">
        <b>{language.reservation_mode as string}: </b>
        <span>{summary.reservationMode}</span>
      </div>
      <div className="te-flex-1">
        <b>{language.primary_object as string}: </b>
        <span>{summary.primaryObject}</span>
      </div>
      <div className="te-flex-1">
        <b>{language.activity_type as string}: </b>
        <span>{summary.activityType}</span>
      </div>
      <div className="te-flex-1" />
    </div>
  );
}
