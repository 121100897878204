import { StudentSetGenerationStatusString } from '@timeedit/types/lib/constants';

export const MAPPING_COMBINATION_STUDY_STATUS_TO_TAG_TYPE: Record<
  StudentSetGenerationStatusString,
  { color: string; status: string }
> = {
  ACTIVITY_DATA_ERROR: {
    color: 'warning',
    status: 'Missing activity data',
  },
  COMPLETE: {
    color: 'default',
    status: 'Complete',
  },
  GENERATING: {
    color: 'processing',
    status: 'Generating',
  },
  STUDENT_SETS_GENERATED: {
    color: 'success',
    status: 'Students sets generated',
  },
  STUDENT_SETS_ALLOCATED: {
    color: 'success',
    status: 'Students sets allocated',
  },
  GENERATION_ERROR: {
    color: 'warning',
    status: 'Generating student sets error',
  },
  ALLOCATION_ERROR: {
    color: 'warning',
    status: 'Allocating student sets error',
  },
  DEALLOCATION_ERROR: {
    color: 'warning',
    status: 'Deallocating student sets error',
  },
};

export const ACTIVITY_DATA_ERROR = 'ACTIVITY_DATA_ERROR';
export const GENERATING = 'GENERATING';
