import { isEmpty, pick } from 'lodash';
import api from '../../services/api.service';
import { configService } from '../../services/config.service';

type TTag = {
  _id: string;
  name: string;
  formId: string;
};
class TagsManager {
  organizationId?: undefined | string;

  tags: Record<string, TTag>;

  tagsByFormId: Record<string, string[]>;

  constructor() {
    this.organizationId = undefined;
    this.tags = {};
    this.tagsByFormId = {};
  }

  init(organizationId: string) {
    this.organizationId = organizationId;
  }

  async getAllTags(forceRefetch?: boolean) {
    if (!forceRefetch && !isEmpty(this.tags)) return;
    const results: { tags: TTag[] } = await api.get({
      successMessage: false,
      errorMessage: false,
      endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${this.organizationId}/tags`,
    });
    results.tags.forEach((tag: TTag) => {
      this.tags[tag._id] = tag;
    });
  }

  async getTags(ids: string[]): Promise<Record<string, TTag>> {
    const missingIds = ids.filter((id) => !this.tags[id]);
    if (missingIds.length) {
      const results: { tags: TTag[] } = await api.get({
        successMessage: false,
        errorMessage: false,
        endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${this.organizationId}/tags`,
        data: {
          _id: ids,
        },
      });
      results.tags.forEach((tag: TTag) => {
        this.tags[tag._id] = tag;
      });
    }
    return pick(this.tags, ids);
  }

  getTagLabel(tagId: string) {
    const tag = this.tags[tagId];
    return tag?.name || tagId;
  }

  async getTagsInForm(formId: string) {
    if (!this.tagsByFormId[formId]?.length) {
      const { results }: { results: TTag[] } = await api.get({
        successMessage: false,
        errorMessage: false,
        endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/forms/${formId}/tags`,
      });
      results.forEach((tag) => {
        this.tags[tag._id.toString()] = tag;
      });
      this.tagsByFormId[formId] = results.map(({ _id }) => _id.toString());
    }

    return pick(this.tags, this.tagsByFormId[formId]);
  }
}

export default new TagsManager();
