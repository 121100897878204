import React from 'react';
import { Form, Select, Upload } from 'antd';
import { CloudUploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useImportActivitiesModal } from './ImportActivitiesModal.context';
import { TABS } from './ImportActivitiesModal.constants';
import intl from 'i18n/intl';

const language = intl.messages;

export default function ImportActivitiesModalUpload() {
  const { file, setFile, setTab, templates, settings, setSettings } = useImportActivitiesModal();
  return (
    <>
      <div>
        <Form layout="vertical">
          <Form.Item
            colon={false}
            label={
              <div>
                {language['activities.import.actions.select_template'] as string} <QuestionCircleOutlined />
              </div>
            }
          >
            <Select
              showSearch
              filterOption={(input: string, option?: { label?: string; value?: string }) => {
                return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
              }}
              value={settings.templateId}
              onChange={(templateId) => setSettings({ ...settings, templateId })}
              style={{ width: 320 }}
              options={Object.values(templates).map((template) => ({
                label: template.name,
                value: template._id,
              }))}
              data-testid="IMPORT_ACTIVITIES_MODAL_TEMPLATE_SELECTOR"
            />
          </Form.Item>
        </Form>
      </div>
      <div data-testid="IMPORT_ACTIVITIES_MODAL_UPLOADER">
        {file ? (
          <span>{file?.name}</span>
        ) : (
          <Upload.Dragger
            // beforeUpload returns null to make sure it will not make any api call
            beforeUpload={() => false}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            multiple={false}
            showUploadList={false}
            action={undefined}
            onChange={(e) => {
              // With beforeUpload returns false, e.file will be File, not UploadFile<File> any more
              setFile(e.file as any as File);
              if (e.file && settings.templateId) {
                setTab(TABS[1].key);
              }
            }}
          >
            <CloudUploadOutlined className="te-mb-2" style={{ fontSize: 32 }} />
            <h4 style={{ fontSize: 16 }}>{language['activities.import.actions.choose_file_to_upload'] as string}</h4>
            <span>{language['activities.import.actions.or_drag_and_drop_file'] as string}</span>
          </Upload.Dragger>
        )}
      </div>
    </>
  );
}
