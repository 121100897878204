import { castArray } from 'lodash';
import { TWeekSelectorValue } from './WeekSelector';
import { toCenterOfWeek } from 'activities/utils';

export const weekIsSelected = (week: number, value: TWeekSelectorValue[]) => {
  return value.some((val) => {
    if (!Array.isArray(val) || val.length === 1) return castArray(val)[0] === week;
    const sorted = val.sort();
    return week === sorted[0] || week === sorted[sorted.length - 1];
  });
};

export const isInWeekRange = (week: number, value: number | number[]) => {
  if (!Array.isArray(value) || value.length === 1) return castArray(week)[0] === value;
  if (!value.length) return false;
  const sorted = value.sort();
  return (
    (sorted[0] <= week && week <= sorted[sorted.length - 1]) || (sorted[0] >= week && week >= sorted[sorted.length - 1])
  );
};

export const formatToCenterOfWeekDay = (value: TWeekSelectorValue[]) => {
  return value.map((val) => {
    if (Array.isArray(val)) {
      return val.map((week) => toCenterOfWeek(week).valueOf());
    }
    return toCenterOfWeek(val).valueOf();
  });
};
