/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

// REDUX
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from '../utils/sliceHelpers';

const initialState = {
  loading: false,
  hasErrors: false,
  allLanguages: [],
};

// Slice
const slice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    fetchLanguagesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchLanguagesSuccess: (state: any, { payload }) => {
      state.allLanguages = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchLanguagesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;
