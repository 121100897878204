import { createContext, useContext } from 'react';
import { TABS } from './ImportActivitiesModal.constants';
import { TImportTemplate } from 'activities/pages/types/importTemplate.type';
import { TActivityImportResultData } from '@timeedit/activity-manager-shared-lib/lib/public/types/ActivityImportPubSub.type';

export type TImportActivitiesSettings = {
  name: string;
  description?: string;
  templateId?: string;
};
interface IImportActivitiesModalContext {
  settings: TImportActivitiesSettings;
  setSettings: (settings: TImportActivitiesSettings) => void;
  templates: Record<string, TImportTemplate>;
  open: boolean;
  tab: (typeof TABS)[number]['key'];
  setTab: (tab: (typeof TABS)[number]['key']) => void;
  onClose: () => void;
  onReset: () => void;
  file?: File;
  setFile: (file?: File) => void;
  importing: boolean;
  onImport: () => void;
  importErrors: string[];
  importAttemptId?: string;
  importResult?: TActivityImportResultData;
  setImportResult: (data: TActivityImportResultData) => void;
  onFinish: () => void;
  setImporting: (importing: boolean) => void;
}

export const initialValue: IImportActivitiesModalContext = {
  settings: {
    name: '',
    description: '',
    templateId: undefined,
  },
  setSettings: () => {},
  templates: {},
  open: false,
  tab: TABS[0].key,
  setTab: () => {},
  onClose: () => {},
  onReset: () => {},
  file: undefined,
  setFile: () => {},
  importing: false,
  onImport: () => {},
  importErrors: [],
  importAttemptId: undefined,
  importResult: undefined,
  setImportResult: () => undefined,
  onFinish: () => undefined,
  setImporting: () => undefined,
};

const ImportActivitiesModalContext = createContext<IImportActivitiesModalContext>(initialValue);
export const useImportActivitiesModal = () => useContext(ImportActivitiesModalContext);
export default ImportActivitiesModalContext;
