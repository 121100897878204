import { Typography, Tabs } from 'antd';
import ReviewByCourse from './Tabs/ReviewByCourse';

import ReviewByStudyCombination from './Tabs/ReviewByStudyCombination';
import intl from '../../../i18n/intl';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { TEProgramObjectSelector, fetchTEObjects } from '../../../slices/integration.slice';
import { pathwaySelectedFormSelector } from '../../slices/pathway.slice';

const language = intl.messages;

function ReviewPathwayView() {
  const dispatch = useDispatch();
  const form = useSelector(pathwaySelectedFormSelector);
  const programObject = useSelector(TEProgramObjectSelector());

  useEffect(() => {
    if (!form) return;
    const { pathwaysSettings } = form;
    const { course, pathways } = pathwaysSettings;

    dispatch(fetchTEObjects(programObject?.objectTypeExtId || 'program'));
    dispatch(fetchTEObjects(course.datasource, { withInactiveObjects: true }));
    dispatch(fetchTEObjects(pathways.datasource));
  }, [form?._id, programObject]);
  return (
    <div className="tabpane__wrapper">
      <div className="container">
        <Typography.Title level={4}>{language['review_study_combination.title'] as string}</Typography.Title>
        <Typography.Text>{language['review_study_combination.subtitle'] as string}</Typography.Text>

        <Tabs
          destroyInactiveTabPane
          items={[
            {
              key: 'item-1',
              label: language['review_study_combination.tabs.review_by_study_combination.title'] as string,
              children: <ReviewByStudyCombination />,
            },
            {
              key: 'item-2',
              label: language['review_study_combination.tabs.review_by_course.title'] as string,
              children: <ReviewByCourse />,
            },
          ]}
        />
      </div>
    </div>
  );
}

export default ReviewPathwayView;
