import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import { Filters } from '@timeedit/ui-components';
import {
  pathwayFiltersSelector,
  pathwayPrimaryObjectsSelector,
  pathwayReviewByStudyCombinationPaginationSelector,
  pathwaySelectedFormSelector,
  reviewStudyCombinationsChangePagination,
  updateFilters,
} from '../../slices/pathway.slice';

import { usePathwayFilters } from './Filters/useFilters';
import { combinationSelectedFormSelector, updateCombinationFilters } from '../../slices/combinationStudents.slice';

import intl from '../../../i18n/intl';
import { buildHistEnrolLookup, filterByNumber, filterObjectsByCategorizedFields } from '../PathwayService.utils';
import { TEObjectSelector, TEProgramObjectSelector } from '../../../slices/integration.slice';
import { EKindOfFilter } from '@timeedit/ui-components/lib/src/components/Filters/Filters.type';
import { isEmpty } from 'lodash';

const language = intl.messages;

function PathwayFilters() {
  const filtersValue = useSelector(pathwayFiltersSelector);
  const dispatch = useDispatch();
  const formSelector = useSelector(pathwaySelectedFormSelector);
  const currentStudyCombinationPagination = useSelector(pathwayReviewByStudyCombinationPaginationSelector);
  const courses = useSelector(TEObjectSelector(formSelector?.pathwaysSettings.course.datasource));
  const programs = useSelector(pathwayPrimaryObjectsSelector);
  const pathwayObjects = useSelector(TEObjectSelector(formSelector?.pathwaysSettings.pathways.datasource));
  const form = useSelector(combinationSelectedFormSelector);
  const programDatasource = useSelector(TEProgramObjectSelector());

  const {
    filterOptions: { formId, scopedObject, pathwayStatus, reviewStatus, nameContains },
    otherFilterOptions,
  } = usePathwayFilters();

  /* Keep incase there is any changes in future */
  // const courseFilterOptions = useMemo(() => {
  //   if (isEmpty(courses)) return {};
  //   const fields = Object.keys(courses[0]);
  //   const filterOptions: any = {};
  //   courses.forEach(course => {
  //     fields.forEach(field => {
  //       if (fieldsByKind[field]) {
  //         filterOptions[field] = fieldsByKind[field].categories
  //         return;
  //       }
  //       filterOptions[field] = filterOptions[field] || [];
  //       filterOptions[field].push(course[field]);
  //     });
  //   });

  //   return Object.keys(filterOptions).reduce((results, field) => {
  //     const opts: string[] = uniq(filterOptions[field]);
  //     return {
  //       ...results,
  //       [field]: {
  //         parentKey: 'course',
  //         label: fieldLabelsMapping[selectedForm?.pathwaysSettings?.course.datasource || ''][field] || field,
  //         options: opts.map(opt => ({
  //           label: opt,
  //           value: opt,
  //         })),
  //       },
  //     }
  //   }, {});

  // }, [courses, fieldLabelsMapping, selectedForm]);

  /* ------------------------------------------------ */

  const filterProps = useMemo(() => {
    return {
      filterOptions: {
        formId,
        scopedObject,
        pathwayStatus,
        reviewStatus,
        nameContains,
      },
      otherFilterOptionsResetTriggers: ['formId'],
      filtersValue,
      onChange: (filters: Record<string, any>) => {
        const filtersCopy = { ...filters };
        if (filtersCopy?.historicalEnrollment) {
          const histEnrolLookup = buildHistEnrolLookup(
            pathwayObjects,
            form?.pathwaysSettings?.pathways.enrollment.historicalEnrollment,
          );

          filtersCopy.pathwayExtId = [];

          for (const currentNumber in histEnrolLookup) {
            if (filterByNumber(filtersCopy?.historicalEnrollment, Number(currentNumber))) {
              filtersCopy.pathwayExtId.push(...histEnrolLookup[currentNumber]);
            }
          }
        }

        const courseDatasource = form?.pathwaysSettings?.course?.datasource;

        if (filtersCopy && courseDatasource && filtersCopy[courseDatasource]) {
          filtersCopy.courseExtId = filterObjectsByCategorizedFields(courses, filtersCopy[courseDatasource]);
        }

        if (filtersCopy && programDatasource?.objectTypeExtId && filtersCopy[programDatasource?.objectTypeExtId]) {
          const foundExtIds = filterObjectsByCategorizedFields(
            programs,
            filtersCopy[programDatasource.objectTypeExtId],
          );
          if (isEmpty(filtersCopy.scopedObject)) filtersCopy.programExtId = foundExtIds;
          else {
            filtersCopy.programExtId = foundExtIds?.filter((extId) => filtersCopy.scopedObject.includes(extId));
          }
        }

        dispatch(updateFilters(filtersCopy));
        dispatch(
          reviewStudyCombinationsChangePagination({
            reviewStudyCombinationsPage: 1, // reset to page 1 when change filters
            reviewStudyCombinationsPerPage: currentStudyCombinationPagination.reviewStudyCombinationsPerPage,
          }),
        );
        dispatch(updateCombinationFilters({ formId: filters.formId }));
      },
    };
  }, [filtersValue, dispatch, formId, scopedObject, pathwayStatus, reviewStatus, otherFilterOptions]);

  return (
    <Filters
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...filterProps}
      otherFilterOptions={{
        credits: {
          kindOfFilter: EKindOfFilter.DROPDOWN,
          type: 'number',
          key: 'credits',
          label: language.credits as string,
          options: [],
        },
        historicalEnrollment: {
          kindOfFilter: EKindOfFilter.DROPDOWN,
          type: 'number',
          key: 'historicalEnrollment',
          label: language.historical_enrollment as string,
          options: [],
        },
        expectedEnrollment: {
          kindOfFilter: EKindOfFilter.DROPDOWN,
          type: 'number',
          key: 'expectedEnrollment',
          label: language.expected_enrollment as string,
          options: [],
        },
        ...otherFilterOptions.course,
        ...otherFilterOptions.program,
      }}
    />
  );
}

export default PathwayFilters;
