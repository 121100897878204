import React from 'react';

import { TActivityElementValue } from 'activities/pages/types/activity.type';
import { Typography } from 'antd';
import { toActivityValueDisplay } from './ActivitiesTable.utils';
import ActivityCategoriesValue from './ActivityCategoriesValue';
import { isEmpty } from 'lodash';

export default function ActivityValueCell({ activityValue }: { activityValue: TActivityElementValue }) {
  if (isEmpty(activityValue)) return null;

  if (activityValue.categories) {
    return <ActivityCategoriesValue activityValueCategories={activityValue.categories} />;
  }

  return <Typography.Text ellipsis>{toActivityValueDisplay(activityValue)}</Typography.Text>;
}
