import React, { useEffect, useState } from 'react';
import { useImportActivitiesModal } from './ImportActivitiesModal.context';
import { TABS } from './ImportActivitiesModal.constants';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { Alert, UploadFile } from 'antd';
import { excelToJSON } from 'activities/utils';
import intl from 'i18n/intl';

const language = intl.messages;

export default function ImportActivitiesModalPreview() {
  const { file, setTab, importErrors } = useImportActivitiesModal();
  const [noOfActivities, setNoOfActivities] = useState(0);

  const readExcelFile = async (file: File) => {
    if (!file) return;
    const fileAsJSON = await excelToJSON(file);
    setNoOfActivities(Math.max(Object.values(fileAsJSON)[0].length - 3, 0));
  };

  useEffect(() => {
    if (!file) {
      setTab(TABS[0].key);
    } else {
      readExcelFile(file);
    }
  }, [file]);

  return (
    <>
      <div className="import-activities-modal__grid" data-testid="IMPORT_ACTIVITIES_MODAL_PREVIEW">
        <span>
          {language.file_name as string} <QuestionCircleOutlined /> :
        </span>
        <span>{file?.name}</span>
        <span>
          {language.number_of_activities as string} <QuestionCircleOutlined /> :
        </span>
        <span data-testid="IMPORT_ACTIVITIES_MODAL_PREVIEW_NUMBER_OF_ACTIVITIES">{noOfActivities}</span>
      </div>
      {!!importErrors.length && (
        <div className="te-pt-2">
          <span>{language['activities.import.message.summary'] as string}:</span>
          <div className="te-flex te-flex-col te-gap-3">
            <Alert
              showIcon
              type="info"
              message={language['activities.import.message.correct_the_errors'] as string}
              closable
            />
            {importErrors.map((err, errIdx) => (
              // eslint-disable-next-line react/no-array-index-key -- use error index as key because it never changes.
              <Alert showIcon type="error" key={errIdx} message={err} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}
