import moment from 'moment';
import { useSelector } from 'react-redux';
import { EKindOfFilter, TFilterOptions } from '@timeedit/ui-components/lib/src/components/Filters/Filters.type';
import { PATHWAY_STATUSES_MAPPING } from '../../PathwayService.constants';

// @ts-ignore
import { pathwayStatuses } from '@timeedit/types/lib/constants';
import {
  pathwayFiltersSelector,
  pathwayFormsSelector,
  pathwayPrimaryObjectsSelector,
  pathwaySelectedFormSelector,
} from '../../../slices/pathway.slice';
import {
  mappingLoadingSelector,
  TEFieldsByKindSelector,
  TEObjectFieldLabelsMappingSelector,
  TEObjectLabeledFieldSelector,
  TEObjectLoadingSelector,
  TEObjectMappingSelector,
  TEObjectSelector,
} from '../../../../slices/integration.slice';
import { capitalize } from 'lodash';
import { useCallback, useMemo } from 'react';
import intl from '../../../../i18n/intl';
import { combinationFiltersSelector } from '../../../slices/combinationStudents.slice';

const language = intl.messages;

export const usePathwayFilters = () => {
  const forms = useSelector(pathwayFormsSelector);
  const selectedForm = useSelector(pathwaySelectedFormSelector);
  const labeledField = useSelector(TEObjectLabeledFieldSelector());
  const objectLoading = useSelector(TEObjectLoadingSelector());
  const programs = useSelector(pathwayPrimaryObjectsSelector);
  const courses = useSelector(TEObjectSelector(selectedForm?.pathwaysSettings.course.datasource));
  const mapping = useSelector(TEObjectMappingSelector());
  const fieldsByKind = useSelector(TEFieldsByKindSelector());
  const fieldLabelsMapping = useSelector(TEObjectFieldLabelsMappingSelector());
  const pathwayFiltersValue = useSelector(pathwayFiltersSelector);
  const combinationFiltersValue = useSelector(combinationFiltersSelector);
  const mappingLoading = useSelector(mappingLoadingSelector);

  const selectedFormId = useMemo(() => combinationFiltersValue.formId, [pathwayFiltersValue, combinationFiltersValue]);

  const filterOptions: TFilterOptions = {
    formId: {
      kindOfFilter: EKindOfFilter.DROPDOWN,
      key: 'formId',
      disabled: mappingLoading,
      label: language.period as string,
      options: forms?.map((form) => {
        return {
          value: form._id,
          label: [
            moment(form.period.startDate).format("MMM DD 'YY"),
            '-',
            moment(form.period.endDate).format("MMM DD 'YY"),
            `(${form.name})`,
          ].join(' '),
        };
      }),
      value: selectedFormId,
    },
    scopedObject: {
      kindOfFilter: EKindOfFilter.DROPDOWN,
      key: 'program',
      label: language.program as string,
      options: (programs || [])?.map((program: any) => {
        return {
          label: program[labeledField ? labeledField[selectedForm?.objectScope || ''] : program.name],
          value: program?.te_extid,
        };
      }),
      allowMultiple: true,
      disabled: !selectedForm,
      loading: (selectedForm && objectLoading[selectedForm.objectScope]) || false,
      placeholder: 'All',
    },
    courseObject: {
      kindOfFilter: EKindOfFilter.DROPDOWN,
      key: 'course',
      label: language.course as string,
      options: (courses || [])?.map((course: any) => {
        return {
          label:
            course[
              labeledField ? labeledField[selectedForm?.pathwaysSettings.course?.datasource || ''] : course.te_extid
            ],
          value: course?.te_extid,
        };
      }),
      allowMultiple: true,
      disabled: !selectedForm,
      placeholder: 'All',
    },
    pathwayStatus: {
      kindOfFilter: EKindOfFilter.DROPDOWN,
      key: 'type',
      label: language.type as string,
      options: Object.values(pathwayStatuses).map((value) => ({
        label: capitalize(value as string),
        value: value as string,
      })),
      allowMultiple: true,
      disabled: !selectedForm,
      loading: (selectedForm && objectLoading[selectedForm.objectScope]) || false,
      placeholder: 'All',
    },
    reviewStatus: {
      kindOfFilter: EKindOfFilter.DROPDOWN,
      key: 'status',
      label: language.status as string,
      options: Object.keys(PATHWAY_STATUSES_MAPPING).map((key) => ({
        label: PATHWAY_STATUSES_MAPPING[key]?.label,
        value: key,
      })),
      allowMultiple: true,
      disabled: !selectedForm,
      placeholder: 'All',
    },
    nameContains: {
      kindOfFilter: EKindOfFilter.TEXT_INPUT,
      key: 'nameContains',
      label: language.study_combination as string,
      disabled: !selectedForm,
      debounce: true,
    },
  };

  const getCategorizedFilterFields = useCallback(
    (field?: string): string[] => {
      if (!field) return [];
      return (mapping.objectTypes?.[field]?.fields || []).map((field: any) => {
        return field.fieldExtId;
      });
    },
    [mapping],
  );

  const generateCategorizedFilterOptions = useCallback(
    (datasource?: string): TFilterOptions => {
      if (!datasource) return {};
      const allFields = getCategorizedFilterFields(datasource);

      return Object.fromEntries(
        allFields
          .filter((field) => fieldsByKind[field])
          .map((field) => {
            const options: string[] = fieldsByKind[field]?.categories ?? [];
            return [
              field,
              {
                key: field,
                parentKey: datasource,
                parentLabel: mapping?.objectTypes[datasource]?.applicationObjectTypeLabel || '',
                label: fieldLabelsMapping[datasource || ''][field] || field,
                options: options.map((option) => ({
                  label: option,
                  value: option,
                })),
                kindOfFilter: EKindOfFilter.DROPDOWN, // Categorized filters are always dropdown filters for now
              },
            ];
          }),
      );
    },
    [getCategorizedFilterFields, fieldsByKind, fieldLabelsMapping, selectedForm, labeledField],
  );

  const courseFilterOptions = useMemo(() => {
    return generateCategorizedFilterOptions(selectedForm?.pathwaysSettings?.course.datasource);
  }, [generateCategorizedFilterOptions, selectedForm]);

  const programFilterOptions = useMemo(() => {
    return generateCategorizedFilterOptions(selectedForm?.objectScope);
  }, [generateCategorizedFilterOptions, selectedForm]);

  return {
    filterOptions,
    otherFilterOptions: {
      course: {
        ...courseFilterOptions,
      },
      program: {
        ...programFilterOptions,
      },
    },
  };
};
