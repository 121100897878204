/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { TBreadcrumb } from '../types/breadcrumb.type';
import { IUIState } from '../types/state.interface';

const initialState: IUIState = {
  breadcrumbs: [],
  tableViews: {},
};

type ViewColumns = {
  [column: string]: any;
};

const updateViewReducer = (
  state: IUIState,
  { payload }: { payload: { datasourceId: string; columns?: ViewColumns } },
) => {
  state.tableViews = {
    ...state.tableViews,
    [payload.datasourceId]: payload.columns,
  };
};

// Slice definition
const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setBreadcrumbs: (state: IUIState, { payload }: { payload: TBreadcrumb[] }) => {
      state.breadcrumbs = payload;
    },
    getViewSuccess: (state: IUIState, { payload }: { payload: { columns: ViewColumns; datasourceId: string } }) => {
      updateViewReducer(state, {
        payload,
      });
    },
    initView: (state: IUIState, { payload: datasourceId }: { payload: string }) => {
      updateViewReducer(state, { payload: { datasourceId } });
    },
    updateViewRequest: updateViewReducer,
  },
});

// The reducer
export default uiSlice.reducer;
