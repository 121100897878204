import { Button, TEBaseTable } from '@timeedit/ui-components';
import { TImportTemplate } from 'activities/pages/types/importTemplate.type';
import { deleteActivityImportTemplate, fetchActivityImportTemplate } from 'activities/services/activities.service';
import { Empty, Popconfirm, Typography } from 'antd';
import intl, { getInlineString } from 'i18n/intl';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { organizationSelector } from 'slices/organization.slice';
import CreateNewImportTemplate from 'activities/components/Modal/CreateNewImportTemplateModal';
import { ActivityEvents } from 'activities/pages/types/activity.type';
import { useAppFeatureFlags } from 'activities/hooks/useAppFeatureFlags';

const language = intl.messages;

function ActivityCreateTemplateOverviewPage() {
  const organization = useSelector(organizationSelector);

  const [activityCreateTemplateLoading, setActivityCreateTemplateLoading] = useState<undefined | boolean>(undefined);
  const [activityCreateTemplates, setActivityCreateTemplates] = useState<undefined | TImportTemplate[]>(undefined);

  const { dmActivityCreateTemplateManagement } = useAppFeatureFlags();

  const onOpenCreateTemplateModal = () => {
    document.dispatchEvent(new CustomEvent(ActivityEvents.OPEN_CREATE_NEW_IMPORT_TEMPLATE_MODAL));
  };

  const doGettingTemplates = useCallback(async () => {
    setActivityCreateTemplateLoading(true);
    const results = await fetchActivityImportTemplate(organization?.id);
    setActivityCreateTemplateLoading(false);
    setActivityCreateTemplates(results);
  }, [organization?.id]);

  useEffect(() => {
    doGettingTemplates();
  }, [organization?.id, doGettingTemplates]);

  const onDelete = async (templateId: string) => {
    await deleteActivityImportTemplate(templateId, organization?.id);
    doGettingTemplates();
  };

  const columns = [
    {
      title: language.name as string,
      key: 'name',
      width: 300,
      resizable: false,
      cellRenderer: (cell: any) => {
        return <span>{cell.rowData.name}</span>;
      },
    },
    {
      title: '',
      key: '_id',
      width: 100,
      resizable: false,
      cellRenderer: (cell: any) => {
        return (
          <Popconfirm
            title={getInlineString('activities.create_template_overview.table.delete_confirmation', cell.rowData.name)}
            onConfirm={() => onDelete(cell.rowData._id!)}
          >
            <Button type="primary" size="small" className="te-ml-2" data-testid="DELETE_BUTTON">
              {language.delete as string}
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  return dmActivityCreateTemplateManagement ? (
    <div className="tabpane__wrapper te-flex te-flex-col te-flex-1">
      <div className="te-flex te-flex-col te-flex-1 te-min-h-0">
        <div className="container">
          <div className="te-flex te-justify-between te-items-center">
            <div>
              <Typography.Title level={4}>
                {language['activities.create_template.overview.title'] as string}
              </Typography.Title>
            </div>
          </div>
        </div>
        <hr className="te-mt-2" />
        <div className="te-flex te-flex-col te-flex-1 te-min-h-0">
          <TEBaseTable.Component
            tableId="TABLE_REVIEW_ACTIVITY_CREATE_TEMPLATE"
            columns={columns}
            data={activityCreateTemplates ?? []}
            rowKey="_id"
            height={activityCreateTemplates?.length ? undefined : 250}
            draggable={false}
            emptyRenderer={
              <div className="prefs-p-4 text--disabled">
                <Empty description={language['activities.create_template_overview.table.no_data'] as string} />
              </div>
            }
            loading={activityCreateTemplateLoading}
            enablePagination={false}
            footerLeftActions={
              <Button type="primary" className="te-mr-2" onClick={onOpenCreateTemplateModal}>
                {language['activities.create_template.title_short'] as string}
              </Button>
            }
          />

          <CreateNewImportTemplate />
        </div>
      </div>
    </div>
  ) : (
    <span />
  );
}

export { ActivityCreateTemplateOverviewPage };
