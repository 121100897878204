import React, { ReactNode } from 'react';
import { TActivity2Be } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/Activity2.type';
import { Divider, Form, Input, Skeleton, Tooltip } from 'antd';
import TEFormItem from '../BaseElements/TEFormItem';
import Duration from '../BaseElements/Duration';
import TEObjectsService from 'activities/services/TEObjects.service';
import DatasourceSelectorV3 from 'components/DatasourceSelectorV3';
import ActivityValueCell from '../Table/ActivityValueCell';
import intl from 'i18n/intl';

const language = intl.messages as Record<string, string>;

type TActivityValuesProps = {
  track?: number;
  values?: TActivity2Be['values'];
  loading?: boolean;
  title?: string;
  editable?: boolean;
  multipleValuesExtIds?: string[];
  children?: ReactNode;
  formId?: null | string;
  timingDisabled?: boolean;
};

function ActivityFieldValue({
  extId,
  hasMultipleValues,
}: TActivity2Be['metadata']['field'][number] & { hasMultipleValues: boolean }) {
  return (
    <Form.Item label={TEObjectsService.getFieldLabel(extId)}>
      <ElementValue hasMultipleValues={hasMultipleValues}>
        <Form.Item name={extId}>
          <Input size="small" />
        </Form.Item>
      </ElementValue>
    </Form.Item>
  );
}

function ActivityObjectValue({
  extId,
  submissionType,
  hasMultipleValues,
}: TActivity2Be['metadata']['object'][number] & { hasMultipleValues: boolean }) {
  return (
    <Form.Item name={extId} label={TEObjectsService.getObjectTypeLabel(extId)}>
      <ElementValue hasMultipleValues={hasMultipleValues}>
        <TEFormItem name={extId}>
          {({ value, onChange }) => {
            const { value: elementValue, filters, valueType } = (value as any) || {};
            if (elementValue || filters) {
              return (
                <DatasourceSelectorV3
                  allowMultiple
                  value={elementValue as string[]}
                  filters={filters}
                  onChange={({ value, filters }) => onChange({ value, filters, valueType })}
                  typeExtId={extId}
                  type={submissionType}
                />
              );
            }
            return (
              <DatasourceSelectorV3 allowMultiple value={value as string[]} typeExtId={extId} onChange={onChange} />
            );
          }}
        </TEFormItem>
      </ElementValue>
    </Form.Item>
  );
}

export function ActivityTracks({ track }: { track?: number }) {
  if (track === undefined) return null;
  return (
    <Form.Item label={language.track} tooltip={language.track}>
      #{track}
    </Form.Item>
  );
}

function ElementValue({ hasMultipleValues, children }: { hasMultipleValues: boolean; children: ReactNode }) {
  if (hasMultipleValues)
    return (
      <Tooltip overlayClassName="test-tooltip" title={language.activity_has_multiple_values}>
        <div style={{ fontStyle: 'italic' }}>{children}</div>
      </Tooltip>
    );
  return <div>{children}</div>;
}

function ActivityValues(props: TActivityValuesProps) {
  const { loading, title, values, editable, children, multipleValuesExtIds, timingDisabled } = props;
  return (
    <div data-testid="ACTIVITY_VALUES_IN_DRAWER">
      {title && <Divider orientation="left">{title}</Divider>}

      <Skeleton loading={loading}>
        {children}

        <Form.Item label={language.duration} tooltip={language.duration}>
          <ElementValue hasMultipleValues={!!multipleValuesExtIds?.includes('duration')}>
            <TEFormItem
              name="duration"
              rules={[
                {
                  validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error())),
                },
              ]}
              type="number"
            >
              {({ value, onChange }) => (
                <Duration value={value as number} onChange={onChange} disabled={!editable || timingDisabled} />
              )}
            </TEFormItem>
          </ElementValue>
        </Form.Item>

        {values?.map((val) => {
          const hasMultipleValues = multipleValuesExtIds?.includes(val.extId);
          if (!editable) {
            return (
              <Form.Item
                label={TEObjectsService.getObjectTypeLabel(val.extId, TEObjectsService.getFieldLabel(val.extId))}
                key={val.extId}
              >
                <ElementValue hasMultipleValues={!!hasMultipleValues}>
                  <ActivityValueCell activityValue={val.value} />
                </ElementValue>
              </Form.Item>
            );
          }

          switch (val.type) {
            case 'object': {
              return (
                <ActivityObjectValue
                  key={val.extId}
                  editable
                  extId={val.extId}
                  submissionType={val.submissionValueType}
                  hasMultipleValues={hasMultipleValues}
                />
              );
            }
            case 'field':
              return <ActivityFieldValue key={val.extId} extId={val.extId} hasMultipleValues={hasMultipleValues} />;
            default:
              return <ActivityFieldValue key={val.extId} extId={val.extId} hasMultipleValues={hasMultipleValues} />;
          }
        })}
      </Skeleton>
    </div>
  );
}

export default ActivityValues;
