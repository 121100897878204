import React, { useEffect } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { HookAPI } from 'antd/es/modal/useModal';
import wsClient from '../../../services/socket.service';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeGenerateStudentSetsStatusSuccess,
  allocateStudentCombinationSets,
  updateGeneratingStudentSetsStatus,
} from '../../slices/combinationStudents.slice';
import intl, { getInlineString } from '../../../i18n/intl';
import { TPathwayStudentSetWebsocketResult } from '@timeedit/types/lib/types';
import { websocketTriggerSelector } from '../../../slices/auth.slice';

interface Props {
  onError: (pathwayIds?: string[], errorMessage?: string) => void;
  modal: HookAPI;
}
const useModalConfimToAllocateStudentSets = ({ onError, modal }: Props) => {
  const dispatch = useDispatch();
  const language = intl.messages;

  const websocketTrigger = useSelector(websocketTriggerSelector);

  useEffect(() => {
    const onMessageReceived = (event: any) => {
      if (!event?.data.startsWith('{')) {
        return;
      }

      try {
        const { payload, eventName } = JSON.parse(event.data) as TPathwayStudentSetWebsocketResult; // This assertion is save till we introduce more types of socket messages. Then, we need to adapt!
        if (eventName === 'STUDENT_SETS_FINISHED_GENERATING') {
          if (payload.successfullyGenerated) {
            // eslint-disable-next-line no-restricted-syntax, guard-for-in
            for (const studComb in payload.generatedStudentSets) {
              dispatch(
                changeGenerateStudentSetsStatusSuccess({
                  dataManagerObjectIds: [studComb],
                  newStatus: 'STUDENT_SETS_GENERATED',
                  generatedStudentSets: payload.generatedStudentSets[studComb].length,
                }),
              );
            }

            dispatch(updateGeneratingStudentSetsStatus(false));
            modal.confirm({
              icon: <CheckCircleOutlined color="success" type="success" />,
              title: language.confirm_to_allocate_student_sets_after_generated_title as string,
              content: (
                <div>
                  {
                    getInlineString(
                      'student_set_generation_succeeded_title',
                      Object.values(payload.generatedStudentSets).reduce((total, current) => total + current.length, 0),
                      payload.dataManagerObjectIds.length,
                    ) as string
                  }
                  <br />
                </div>
              ),
              cancelText: language.allocate_later as string,
              okText: language.allocate_student_sets as string,
              onOk: () => {
                dispatch(allocateStudentCombinationSets(payload.dataManagerObjectIds, modal));
              },
              onCancel: () => {},
            });
          } else {
            dispatch(
              changeGenerateStudentSetsStatusSuccess({
                dataManagerObjectIds: payload.dataManagerObjectIds,
                newStatus: 'GENERATION_ERROR',
                studentSetsError: {
                  error: true,
                  message: payload.errorMessage,
                },
                generatedStudentSets: undefined,
              }),
            );
            dispatch(updateGeneratingStudentSetsStatus(false));

            onError(payload.dataManagerObjectIds, payload.errorMessage);
          }
        }
      } catch (e) {
        console.error('Error: ', e);
      }
    };
    wsClient.instance?.addEventListener('message', onMessageReceived);
    return () => {
      wsClient.instance?.removeEventListener('message', onMessageReceived);
    };
  }, [dispatch, language, websocketTrigger]);

  return null;
};

export default useModalConfimToAllocateStudentSets;
