import intl from 'i18n/intl';

const language = intl.messages;

export const TABS = [
  {
    key: 'main',
    title: language['activities.export_reservations.title'] as string,
  },
];
