import React, { useEffect, useMemo, useState } from 'react';
import ActivityDrawerWrapper from './ActivityDrawerWrapper';
import { useActivitySeriesGetter } from './ActivityDrawer.hook';
import { ActivityInfo, ActivityNumberOfTracks } from './ActivityDrawerComponents';
import ActivityValues, { ActivityTracks } from './ActivityValues';
import intl from 'i18n/intl';
import { ActivityEvents } from 'activities/pages/types/activity.type';
import { updateActivitiesValues } from 'activities/services/activities.service';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';
import ActivityTags from './ActivityTags';
import ActivityWeeks from './ActivityWeeks';
import ActivityAddingNewTracksModal from './ActivityAddingNewTracksModal';

const language = intl.messages as Record<string, string>;

function ActivitySeriesDrawer() {
  const [open, setOpen] = useState(false);
  const [activitySeriesId, setActivitySeriesId] = useState<undefined | string>(undefined);
  const { activitySeries, loading, activityValues, multipleValuesExtIds, initialValues, allDateRanges } =
    useActivitySeriesGetter({
      activitySeriesId,
    });

  const editable = useMemo(() => {
    if (!activitySeries) return false;
    return activitySeries.activityStatuses.every(
      (status) => ![EActivityStatus.IN_REVIEW].includes(status as EActivityStatus),
    );
  }, [activitySeries]);

  const onSave = async (updatedValues: Record<string, string>) => {
    if (!activitySeries || !activitySeries.formId) return;
    await updateActivitiesValues(
      activitySeries.formId.toString(),
      activitySeries.activitySeriesId,
      'ACTIVITY_SERIES',
      updatedValues,
    );
    if (typeof onClose === 'function') {
      document.dispatchEvent(new CustomEvent(ActivityEvents.REFETCH_ACTIVITIES_TABLE));
      onClose();
    }
  };

  const onOpen = (e: Event) => {
    setOpen(true);
    setActivitySeriesId((e as CustomEvent<{ activitySeriesId: string }>).detail?.activitySeriesId);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener(ActivityEvents.OPEN_ACTIVITY_SERIES_DRAWER, onOpen);
    document.addEventListener(ActivityEvents.CLOSE_ACTIVITY_SERIES_DRAWER, onClose);
    return () => {
      document.removeEventListener(ActivityEvents.OPEN_ACTIVITY_SERIES_DRAWER, onOpen);
      document.removeEventListener(ActivityEvents.CLOSE_ACTIVITY_SERIES_DRAWER, onClose);
    };
  }, []);

  useEffect(() => {
    if (!open) setActivitySeriesId(undefined);
  }, [open]);

  return (
    <ActivityDrawerWrapper
      open={open}
      onClose={onClose}
      title={language['activites.activity_series']}
      initialValues={initialValues}
      primaryObject={activitySeries?.primaryObject}
      editable={editable}
      onSave={onSave}
      loading={loading}
    >
      <>
        <ActivityInfo editable={editable} loading={loading} activityType={activitySeries?.activityType} />
        <ActivityNumberOfTracks totalTracks={activitySeries?.numberOfTracks} />
        {activitySeries && (
          <ActivityAddingNewTracksModal
            activitySeries={activitySeries}
            activityValues={activityValues}
            initialValues={initialValues}
          />
        )}
        <ActivityValues
          editable={editable}
          loading={loading}
          values={activityValues}
          title={language.track_info}
          multipleValuesExtIds={multipleValuesExtIds}
        >
          <ActivityTracks track={activitySeries?.numberOfTracks} />
          <ActivityWeeks
            allowMultiple
            allWeeks={allDateRanges}
            numberOfTracks={activitySeries?.numberOfTracks}
            disabledWeeks={[]}
          />
        </ActivityValues>
        <ActivityTags formId={activitySeries?.formId?.toString()} editable={editable} />
      </>
    </ActivityDrawerWrapper>
  );
}

export default ActivitySeriesDrawer;
