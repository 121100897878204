import { Form, FormItemProps, Input, InputNumber, InputRef } from 'antd';
import React, { ReactNode, RefObject, useMemo, useRef } from 'react';
import { SUBMISSION_VALUE_TYPE } from '@timeedit/activity-manager-shared-lib/lib/internal/types/schedulingEnum.type';
import { TExactSearchField } from '@timeedit/ui-components/lib/src/components/ObjectFilter/ObjectFilter.type';
import { TWeekSelectorValue } from '../WeekSelector/WeekSelector';

type TElementValue =
  | undefined
  | null
  | string
  | string[]
  | number
  | number[]
  | boolean
  | { value: string[]; filters: TExactSearchField[]; valueType?: SUBMISSION_VALUE_TYPE }
  | TWeekSelectorValue
  | TWeekSelectorValue[];
type TTEFormItem = {
  children: ({
    value,
    onChange,
  }: {
    value?: TElementValue;
    onChange: (value: TElementValue) => void;
  }) => ReactNode | ReactNode[];
  rules?: FormItemProps['rules'];
  name: string;
  type?: 'number' | 'text';
};

export const getValueFromFormItem = (value: string) => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

function TEFormItem(props: TTEFormItem) {
  const { children, name, rules, type = 'text' } = props;
  const formInstance = Form.useFormInstance();

  const value = Form.useWatch(name, formInstance);

  const formatedValue = useMemo(() => {
    try {
      if (!value) return value;
      return JSON.parse(value);
    } catch (err) {
      return value;
    }
  }, [value]);

  const fakeInput = useRef<HTMLInputElement | InputRef>(null);

  const onValueChange = (newValue: TElementValue) => {
    if (formInstance) {
      formInstance.setFieldValue(name, newValue);
      const input = (fakeInput.current as InputRef)?.input || fakeInput.current;
      if (input) {
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
          window?.HTMLInputElement?.prototype,
          'value',
        )?.set;
        const formattedValue = typeof newValue === 'object' ? JSON.stringify(newValue) : newValue;
        nativeInputValueSetter?.call(input, formattedValue);

        // dispatch change event
        const inputEvent = new Event('change', { bubbles: true });
        (input as HTMLInputElement).dispatchEvent(inputEvent);
      }
    }
  };

  return (
    <div>
      <div>{children({ value: formatedValue, onChange: onValueChange })}</div>
      <Form.Item name={name} rules={rules} noStyle>
        {type === 'number' ? (
          <InputNumber className="te-hidden" ref={fakeInput as RefObject<HTMLInputElement>} />
        ) : (
          <Input className="te-hidden" ref={fakeInput as RefObject<InputRef>} />
        )}
      </Form.Item>
    </div>
  );
}

export default TEFormItem;
