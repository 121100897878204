import { Modal, notification } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ImportReservationsModalContext, {
  TImportReservationsSettings,
  initialValue,
} from './ImportReservationsModal.context';

import { TABS } from './ImportReservationsModal.constants';
import ImportReservationsModalNav from './ImportReservationsModalNav';
import ImportReservationsModalMain from './ImportReservationsModalMain';
import './ImportReservationsModal.scss';
import {
  downloadActivityImportTemplateWithReservations,
  fetchActivityImportTemplate,
} from 'activities/services/activities.service';
import { keyBy } from 'lodash';
import { ActivityEvents } from 'activities/pages/types/activity.type';
import intl from 'i18n/intl';
import { TActivityImportResultData } from '@timeedit/activity-manager-shared-lib/lib/public/types/ActivityImportPubSub.type';
import { TImportTemplate } from 'activities/pages/types/importTemplate.type';
import { configService } from 'services/config.service';

const language = intl.messages;

interface IProps {
  organizationId: string;
  open?: boolean;
}
export default function ImportReservationsModal(props: IProps) {
  const { organizationId } = props;
  const [open, setOpen] = useState(props.open || false);
  const [file, setFile] = useState<undefined | File>(undefined);
  const [settings, setSettings] = useState<TImportReservationsSettings>(initialValue.settings);
  const [tab, setTab] = useState<(typeof TABS)[number]['key']>(TABS[0].key);
  const [importing, setImporting] = useState(false);
  const [importErrors, setImportErrors] = useState<string[]>([]);
  const [importResult, setImportResult] = useState<TActivityImportResultData>();

  const [templates, setTemplates] = useState<Record<string, TImportTemplate>>({});

  const onOpenModal = () => {
    setOpen(true);
  };
  useEffect(() => {
    document.addEventListener(ActivityEvents.OPEN_IMPORT_RESERVATIONS_MODAL, onOpenModal);
    return () => {
      document.removeEventListener(ActivityEvents.OPEN_IMPORT_RESERVATIONS_MODAL, onOpenModal);
    };
  }, []);

  const onReset = () => {
    setTab(TABS[0].key);
    setSettings(initialValue.settings);
    setFile(undefined);
    setImportErrors([]);
    setImportResult(undefined);
  };

  const onFinish = useCallback(async () => {
    const { reservationMode, startDate, endDate, activityCreateTemplateId, name } = settings;
    if (
      reservationMode == null ||
      startDate == null ||
      endDate == null ||
      activityCreateTemplateId == null ||
      name == null
    ) {
      notification.error({ key: configService().NOTIFICATION_KEY, message: 'Failed to initiate reservation export' });
      // eslint-disable-next-line no-console
      console.error('Failed to initiate reservation export due to missing properties, all properties: ', settings);
      return;
    }

    setImporting(true);
    await downloadActivityImportTemplateWithReservations({
      reservationMode: reservationMode.id,
      startDate: startDate.unix(),
      endDate: endDate.unix(),
      activityCreateTemplateId,
      templateName: name,
    });
    setImporting(false);
  }, [settings]);

  useEffect(() => {
    if (!open) {
      onReset();
    } else {
      const doGettingTemplates = async () => {
        const results = await fetchActivityImportTemplate(organizationId);
        setTemplates(keyBy(results, '_id'));
      };
      doGettingTemplates();
    }
  }, [open, organizationId]);

  const contextValue = useMemo(() => {
    return {
      tab,
      setTab,
      settings,
      setSettings,
      importTemplates: templates,
      file,
      setFile,
      onClose: () => setOpen(false),
      onReset,
      importing,
      importErrors,
      importResult,
      setImportResult,
      onFinish,
      setImporting,
    };
  }, [tab, setTab, file, importing, importErrors, settings, templates, importResult, onFinish]);

  const modalMainContent = useMemo(() => {
    switch (tab) {
      case TABS[0].key:
        return <ImportReservationsModalMain />;
      default:
        return null;
    }
  }, [tab]);

  return (
    <ImportReservationsModalContext.Provider value={contextValue}>
      <Modal
        open={open}
        title={language['reservations.export.title'] as string}
        footer={<div />}
        onCancel={() => setOpen(false)}
        width={700}
      >
        <ImportReservationsModalNav />
        <div data-testid="IMPORT_RESERVATIONS_MODAL">{modalMainContent}</div>
      </Modal>
    </ImportReservationsModalContext.Provider>
  );
}
