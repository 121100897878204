import { regionPlaceHolderInUrl, regionifyUrl } from '@timeedit/preferences-and-dm-commons/lib/src';
import { configsCloudRun2024 } from '../configs';
import { getAccessTokenDecoded } from './token.service';

export const configService = () => {
  const env = process.env.REACT_APP_TE_APP_ENV as keyof typeof configsCloudRun2024;
  const region = getAccessTokenDecoded()?.region;
  const configsRaw = configsCloudRun2024[env];
  for (const [configKey, configValue] of Object.entries(configsRaw)) {
    if (configValue.includes(regionPlaceHolderInUrl)) {
      configsRaw[configKey as keyof typeof configsRaw] = regionifyUrl(configValue, region!);
    }
  }
  return configsRaw;
};
