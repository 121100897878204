const LANG_CODE = {
  Danish: 'da',
  Dutch: 'nl',
  English: 'en',
  Finnish: 'fi',
  German: 'de',
  Norwegian: 'nb',
  Swedish: 'sv',
};

export const DEFAULT_LANG = LANG_CODE.English;
