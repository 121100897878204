import { useEffect, useMemo, useState } from 'react';
import { TActivity2Be } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/Activity2.type';
import { getActivityById } from 'activities/services/activities.service';
import { useSelector } from 'react-redux';
import { IState } from 'types/state.interface';
import { compact, isEmpty, uniq, groupBy } from 'lodash';
import * as activityService from 'activities/services/activities.service';
import {
  TActivityResultsInResponseGroupByActivitySeries,
  TActivityRawValue,
} from 'activities/pages/types/activity.type';
import { toActivityWeekRange } from 'activities/utils';
import { SUBMISSION_VALUE_TYPE } from '@timeedit/activity-manager-shared-lib/lib/internal/types/schedulingEnum.type';
import { TWeekSelectorValue } from '../WeekSelector/WeekSelector';

const activityCategoryToObjectFilterValues = (categories: { id: string; values: string[] }[]) => {
  return categories?.map(({ id, values }) => ({
    fieldId: id,
    values,
  }));
};

export const toActivityInitialValues = ({
  activityType,
  duration,
  startDate,
  endDate,
  values,
  tags,
  weeks,
}: {
  activityType: string;
  duration?: number;
  startDate?: string;
  endDate?: string;
  values: TActivity2Be['values'];
  tags?: TActivity2Be['tags'];
  weeks?: (number | number[])[];
}): Record<string, TActivityRawValue> => {
  const activityValue: Record<string, TActivityRawValue> = {
    activityType: compact([activityType]),
    ...values.reduce(
      (results: Record<string, TActivity2Be['values'][number]['value']>, val: TActivity2Be['values'][number] | any) => {
        const { submissionValueType } = val;
        if (submissionValueType === SUBMISSION_VALUE_TYPE.FILTER) {
          return {
            ...results,
            [val.extId]: JSON.stringify({
              value: (val.value as any).categories ? [] : val.value,
              filters: activityCategoryToObjectFilterValues(
                (val.value as any).categories || (val.submissionValue as any).categories,
              ),
              valueType: SUBMISSION_VALUE_TYPE.FILTER,
            }),
          };
        }
        return {
          ...results,
          [val.extId]: val.value,
        };
      },
      {},
    ),
  };
  if (duration !== undefined) {
    activityValue.duration = duration;
  }
  if (weeks !== undefined) {
    activityValue.weeks = weeks;
  }
  if (startDate && endDate) {
    activityValue.weeks = toActivityWeekRange(startDate, endDate);
  }
  if (tags) {
    activityValue.tags = tags.map((tag) => ({
      value: tag._id.toString(),
      label: tag.name,
    }));
  }
  return activityValue;
};

export const toUniqActivityValues = (series: TActivityResultsInResponseGroupByActivitySeries) => {
  const activityValues = series?.allValues?.map(({ activityValue }) => activityValue) ?? [];
  const extIdsInValues: string[] = [];
  const hasMultipleValues: string[] = [];
  const hasValues: string[] = [];
  activityValues.forEach((item, itemIndex: number) => {
    if (extIdsInValues.includes(item.extId)) {
      hasMultipleValues.push(item.extId);
    } else {
      extIdsInValues.push(item.extId);
    }
    if (hasValues.includes(item.extId)) {
      delete activityValues[itemIndex];
    }
    if (!isEmpty(item.value)) {
      hasValues.push(item.extId);
    }
  });
  if (uniq(series.durations).length > 1) {
    hasMultipleValues.push('duration');
  }
  return { activityValues, hasMultipleValues };
};

export const useActivityGetter = ({ activityId }: { activityId: string }) => {
  const organizationId = useSelector((state: IState) => state.auth.user?.organizationId);
  const [loading, setLoading] = useState<boolean>(false);
  const [activity, setActivity] = useState<undefined | TActivity2Be>();
  const [initialValues, setInitialValues] = useState<undefined | Record<string, TActivityRawValue>>();
  const [activitiesInSameTracks, setActivitiesInSameTracks] = useState<TActivity2Be[]>([]);

  useEffect(() => {
    if (activityId) {
      const doGetActivity = async () => {
        setLoading(true);
        if (!organizationId || !activityId) {
          setActivity(undefined);
          setLoading(false);
          return;
        }
        const result = await getActivityById(organizationId, activityId);
        const { activity } = result;
        if (activity && activity.metadata) {
          const ssp = {
            groupBy: 'FLAT',
            matchType: 'ALL',
            page: 1,
            filters: {
              activitySeriesId: {
                values: [activity.activitySeriesGroup.activitySeriesId],
              },
              track: {
                values: [activity.track],
              },
            },
          };
          setLoading(true);
          const activitiesInSameTracks: TActivity2Be[] = (await activityService.getActivities(organizationId, ssp))
            .results;
          if (activitiesInSameTracks) {
            setActivitiesInSameTracks(activitiesInSameTracks.filter(({ _id }) => _id !== activity._id));
          }

          setInitialValues(
            toActivityInitialValues({
              activityType: activity.metadata.activityType,
              duration: activity.metadata.length,
              startDate: activity?.metadata.startDate,
              endDate: activity?.metadata.endDate,
              values: activity.values,
              tags: activity.tags,
            }),
          );
        }

        setActivity(activity);
        setLoading(false);
      };
      doGetActivity();
    } else {
      setActivity(undefined);
    }
  }, [activityId]);

  return {
    activity,
    initialValues,
    activitiesInSameTracks,
    loading,
  };
};

export const useActivitySeriesGetter = ({ activitySeriesId }: { activitySeriesId?: string }) => {
  const organizationId = useSelector((state: IState) => state.auth.user?.organizationId);
  const [loading, setLoading] = useState<boolean>(false);
  const [activitySeries, setActivitySeries] = useState<
    undefined | (TActivityResultsInResponseGroupByActivitySeries & { tags: TActivity2Be['tags'] })
  >();
  const [activityValues, setActivityValues] = useState<undefined | TActivity2Be['values']>();
  const [multipleValuesExtIds, setMultipleValuesExtIds] = useState<string[]>([]);
  const [allDateRanges, setAllDateRanges] = useState<TWeekSelectorValue[][]>([]);

  useEffect(() => {
    if (!organizationId || !activitySeriesId) return;

    const doGetActivitySeries = async () => {
      const ssp = {
        groupBy: 'ACTIVITY_SERIES',
        matchType: 'ALL',
        page: 1,
        limit: 1,
        filters: {
          activitySeriesId: {
            values: [activitySeriesId],
          },
        },
      };

      const activitiesSsp = {
        groupBy: 'FLAT',
        matchType: 'ALL',
        page: 1,
        limit: 1,
        filters: {
          activitySeriesId: {
            values: [activitySeriesId],
          },
        },
      };
      setLoading(true);
      const series = (await activityService.getActivities(organizationId, ssp)).results?.[0];

      const activitiesInSeries: TActivity2Be[] = (
        await activityService.getActivities(organizationId, {
          ...activitiesSsp,
          groupBy: 'FLAT',
          limit: 100,
        })
      ).results;

      const allDateRanges = Object.values(
        groupBy(
          activitiesInSeries.map((activity) => ({
            track: activity.track,
            dateRanges: toActivityWeekRange(activity.metadata.startDate, activity.metadata.endDate),
          })),
          'track',
        ),
      );
      setAllDateRanges(
        allDateRanges.map((item) => {
          return item.map((sub) => {
            return sub.dateRanges[0];
          });
        }),
      );
      setActivitySeries(series);
      const { activityValues, hasMultipleValues } = toUniqActivityValues(series);
      setActivityValues(activityValues as TActivity2Be['values']);
      setMultipleValuesExtIds(hasMultipleValues);
      setLoading(false);
    };
    doGetActivitySeries();
  }, [activitySeriesId]);

  const initialValues = useMemo(() => {
    const durations = uniq(compact(activitySeries?.durations));
    return toActivityInitialValues({
      activityType: activitySeries?.activityType!,
      duration: durations[0],
      tags: activitySeries?.tags,
      weeks: activitySeries?.startEndDates?.flatMap((item) => toActivityWeekRange(item.startDate, item.endDate)) ?? [],
      values: activityValues || [],
    });
  }, [activitySeries, activityValues]);

  return {
    activitySeries,
    activityValues,
    initialValues,
    allDateRanges,
    multipleValuesExtIds,
    loading,
  };
};
