/* eslint-disable import/no-extraneous-dependencies */
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import moment from 'moment';
import XLSX from 'xlsx';
import { TWeekSelectorValue } from './components/WeekSelector/WeekSelector';

dayjs.extend(isoWeek);

export const toDurationDisplay = (minutes: number): string => {
  return moment().startOf('date').add(minutes, 'minute').format('HH:mm');
};
export const toCenterOfWeek = (week: number | string | Dayjs | Date, startOrEndWeek: 'start' | 'end' = 'start') => {
  const date = startOrEndWeek === 'start' ? dayjs(week).add(2, 'day') : dayjs(week).subtract(2, 'day');
  return dayjs((date.startOf('week').valueOf() + date.endOf('week').valueOf()) / 2);
};

export const excelToJSON = (file: File): Record<string, any> => {
  const reader = new FileReader();
  reader.readAsBinaryString(file);
  return new Promise((resolve) => {
    reader.onload = async (e: any) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const parsedSheets = Object.keys(wb.Sheets).reduce((results, sheetKey) => {
        return {
          ...results,
          [sheetKey]: XLSX.utils.sheet_to_json(wb.Sheets[sheetKey], {
            defval: '',
            blankrows: true,
          }),
        };
      }, {});
      resolve(parsedSheets);
    };
  });
};

export const toActivityWeekRange = (startDate: string | Date, endDate: string | Date): TWeekSelectorValue[] => {
  const diff = Math.ceil(toCenterOfWeek(endDate, 'end').diff(toCenterOfWeek(startDate), 'weeks'));
  if (!diff) return [toCenterOfWeek(dayjs(startDate)).valueOf()];
  return [
    new Array(1 + diff).fill(null).map((item, itemIdx) => {
      return toCenterOfWeek(dayjs(startDate).add(itemIdx, 'week')).valueOf();
    }),
  ];
};
