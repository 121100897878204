import sanitizeHtml from 'sanitize-html';
import React from 'react';

export default function DivWithHtml({
  htmlInput,
  allowedHTMLTags,
  allowedAttributes,
}: {
  htmlInput: string;
  allowedHTMLTags?: string[];
  allowedAttributes?: typeof sanitizeHtml.defaults.allowedAttributes;
}) {
  const htmlSanitzed = sanitizeHtml(htmlInput, {
    allowedTags: allowedHTMLTags ?? [],
    allowedAttributes: allowedAttributes ?? false,
  });
  // This is the only place in the app where dangerouslySetInnerHTML is allowed. Do NEVER use it elsewhere!
  return <div dangerouslySetInnerHTML={{ __html: htmlSanitzed }} />;
}
