import { Button } from 'antd';
import intl, { getInlineString } from 'i18n/intl';
import React, { useEffect, useMemo } from 'react';

interface Props {
  isSelectingAllInCurrentPage?: boolean;
  countPerAllPages?: number;
  countItemsInPage: number;
  countItemsInAllPages?: number;
  isActive?: boolean;
  onToggle: (isActive?: boolean) => void;
  labels: {
    selectAll: string;
    allInPageAreSelected: string;
    allInAllPageAreSelected: string;
  };
}

const language = intl.messages;

function TableAllItemsSelection(props: Props) {
  const { isActive, isSelectingAllInCurrentPage, countPerAllPages, countItemsInPage, onToggle, labels } = props;
  const isInvisibile = useMemo(() => {
    return !isSelectingAllInCurrentPage || !countPerAllPages || countItemsInPage >= countPerAllPages;
  }, [isSelectingAllInCurrentPage, countItemsInPage, countPerAllPages]);

  if (isInvisibile) return null;

  if (isActive) {
    return (
      <div>
        {getInlineString(labels.allInAllPageAreSelected, countPerAllPages)}.&nbsp;
        <Button
          type="link"
          onClick={() => {
            onToggle(false);
          }}
        >
          {language.clear_selection as string}
        </Button>
      </div>
    );
  }
  return (
    <div>
      {getInlineString(labels.allInPageAreSelected, countItemsInPage)}.&nbsp;
      <Button type="link" onClick={() => onToggle(true)}>
        {getInlineString(labels.selectAll, countPerAllPages)}
      </Button>
    </div>
  );
}
export default TableAllItemsSelection;
