import React, { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  cancelStudentCombinationSets,
  updateGeneratingStudentSetsStatus,
} from '../../slices/combinationStudents.slice';
import './Timer.scss';

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

interface IProps {
  selectedRowKeys: string[] | undefined;
}

function Timer({ selectedRowKeys }: IProps) {
  const dispatch = useDispatch();
  const [time, setTime] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (time <= 600000 && isLoading) {
      const interval = setInterval(() => setTime(time + 1000), 1000);

      return () => clearInterval(interval);
    }
    setIsLoading(false);
    return () => null;
  }, [time, isLoading]);

  const onCancel = (selectedRowKeys: string[] | undefined) => {
    if (!isEmpty(selectedRowKeys)) {
      dispatch(cancelStudentCombinationSets(selectedRowKeys || []));
    }
    setIsLoading(false);
    dispatch(updateGeneratingStudentSetsStatus(false));
  };

  return (
    <div className="timer">
      <Spin spinning={isLoading} style={{ paddingRight: 10 }} />
      <span className="timer--title">Generating Student sets</span>

      {Object.entries({
        m: (time / MINUTE) % 60,
        s: (time / SECOND) % 60,
      }).map(([label, value]) => (
        <div key={label} className="col-4">
          <div className="box">
            <span>
              {`${Math.floor(value)}`.padStart(2, '0')} <span className="timer-text">{label}</span>
            </span>
          </div>
        </div>
      ))}

      <Button size="small" onClick={() => onCancel(selectedRowKeys)}>
        Cancel
      </Button>
    </div>
  );
}

export default Timer;
